import React, { ReactNode } from 'react';

type Props = {
  component: ReactNode;
  className?: string;
  center?: boolean;
  style?: any;
  width?: string;
  height?: string;
};

const Image: React.FC<Props> = ({ component, center, width, height, ...rest }: any) => {
  const SvgComponent = component;
  const customStyle = { height: 'auto' };
  if (center) {
    return (
      <div style={{ margin: '0 auto' }}>
        {width && height ?
          <SvgComponent style={customStyle} height={height} width={width} {...rest} />
          :
          <SvgComponent style={customStyle} {...rest} />
        }
      </div>
    );
  } else {
    return <>
      {width && height ?
        <SvgComponent style={customStyle} height={height} width={width} {...rest} />
        :
        <SvgComponent style={customStyle} {...rest} />
      }
    </>;
  }
};

export default Image;
