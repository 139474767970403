// TODO implement this on actions
export enum DOMAIN_PREFIX {
  DATA_COLLECTION = 'DATA_COLLECTION',
  NEGOTIATION = 'NEGOTIATION',
  KYC = 'KYC',
  PERSONAL_AREA = 'PERSONAL_AREA',
  ERROR_DIALOG = 'ERROR_DIALOG',
  LOADING_DIALOG = 'LOADING_DIALOG',

  CRM = 'CRM',
  ORCHESTRATOR = 'ORCHESTRATOR'
}

export const getPrefix = (domain: DOMAIN_PREFIX) => `[${DOMAIN_PREFIX[domain]}] `;
