import React, { lazy } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router';

import { NavbarContext } from '../../context/nav-context';

// Redux
import { useDispatch } from 'react-redux';
import { openSidebarAction } from '../../store/actions/set-sidebar.action';

// Enums
import { AssistanceRoutes } from '../../enums/assistance-routes';

// Components
// @ts-ignore
import Page from '../../components/Page/Page';
// @ts-ignore
import { SidebarTile } from '../../components/Sidebar/Sidebar';
// @ts-ignore
import Navbar from '../../components/Navbar/Navbar';
import NotFound from '../../components/not-found/NotFound';

// Utils
import { delay } from '../../utils/delay';

// Pages
import BookingComplete from './pages/BookingComplete';
import { useLocationChange } from '../../hooks/useLocationChange';
import { PageSections } from '../../enums/data-layer/page-sections';

// Lazy
const BookingCall = lazy(() => delay(import( './pages/BookingCall')));

const Assistance: React.FC = () => {

  const navbarCtx = React.useContext(NavbarContext);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  /**
   * Custom Hooks
   */
  useLocationChange(false, PageSections.ASSISTANCE);

  /**
   * useCallbacks
   */
  const backAction = React.useCallback(() => navigate(-1), [navigate]);
  const showSidebarAction = React.useCallback(() => dispatch(openSidebarAction()), [dispatch]);

  /**
   * useMemos
   */
  const backBtn = React.useMemo(() => {
    return { l1: { icon: 'icon-conte_icon_header_back', action: backAction } };
  }, [backAction]);
  const backBtnDisabled = React.useMemo(() => ({ l1: { icon: '', action: null } }), []);
  const menuBtn = React.useMemo(() => {
    return { r2: { icon: 'icon-conte_icon_header_right_burger_icon', action: showSidebarAction } };
  }, [showSidebarAction]);
  const menuBtnDisabled = React.useMemo(() => ({ r2: { icon: '', action: null } }), []);

  /**
   * useEffects
   */
  // Configure navbar actions
  React.useEffect(() => {

    navbarCtx.showGradientBg(true);

    if (location.pathname.includes(AssistanceRoutes.BOOKING_CALL)) {
      navbarCtx.updateButton({ ...backBtn, ...menuBtn });
    } else {
      navbarCtx.updateButton({ ...menuBtnDisabled, ...backBtnDisabled });
    }

    return () => {
      navbarCtx.showGradientBg(false);
      navbarCtx.updateButton({ ...menuBtnDisabled, ...backBtnDisabled });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuBtn, menuBtnDisabled, location]);

  return (
    <React.Fragment>

      <SidebarTile />

      <Page.Container withSidebar hasGradient={navbarCtx.hasGradientBg}>

        <Navbar />

        <Page.Content className='my-md-4'>

          <Routes>
            <Route path={AssistanceRoutes.BOOKING_CALL} element={<BookingCall />} />
            <Route path={AssistanceRoutes.BOOKING_COMPLETE} element={<BookingComplete />} />
            <Route path='/' element={<Navigate to={AssistanceRoutes.BOOKING_CALL} replace={true} />} />
            <Route path='*' element={<NotFound />} />
          </Routes>

        </Page.Content>

      </Page.Container>

    </React.Fragment>

  );

};

export default Assistance;