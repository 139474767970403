import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { CRMAPI } from '../../api';
import { CreateOtpWriteDTO, VerifyOtpWriteDTO } from '../../types/otp';
import { getTokenExpiration } from '../../utils/dateUtil';
import { ThunkExtra } from '../types';
import { timeout } from '../../utils/delay';

export const setOtpStatus = createAction('setOtpStatus', otp => ({ payload: { otp } }));

type RequestOtpArgs = Pick<CreateOtpWriteDTO, 'email'>;

export const requestOTPAction = createAsyncThunk<void, RequestOtpArgs, ThunkExtra>(
  'requestOTPAction',
  async ({ email }, { rejectWithValue }) => {
    for (let retry = 0; retry < 3; retry++) {

      try {

        await CRMAPI.createOTP({ email });

        // On success => end retry
        retry = 3;

      } catch (err) {

        const error = err as AxiosError;

        // Max 3 retry before error modal shown
        if (retry < 2 && error?.response?.status === 404) {
          // Wait 5 seconds and retry API call
          await timeout(5000);
        } else {
          return rejectWithValue(error?.message);
        }
      }
    }
  }
);

type VerifyOtpReturned = {
  otpVerified: boolean;
} | void;

export const verifyOTPAction = createAsyncThunk<VerifyOtpReturned, VerifyOtpWriteDTO, ThunkExtra>(
  'verifyOTPAction',
  async ({ email, otp_code, dataLayerCallback }, { rejectWithValue }) => {
    try {
      await CRMAPI.verifyOTP({ email, otp_code });
      sessionStorage.removeItem("otp");
      sessionStorage.setItem('auth-expiration', `${getTokenExpiration(new Date())}`);

      if (dataLayerCallback) {
        dataLayerCallback(undefined);
      }

      return { otpVerified: true };
      // redirect to route /continue
    } catch (err) {
      const error = err as AxiosError;
      if (dataLayerCallback) {
        dataLayerCallback(undefined, error.message);
      }
      return rejectWithValue('Codice non valido');
    }
  }
);

// account vuoto
// {
//   "id": "256751",
//   "applicants": [
//       {
//           "firstName": "Marco",
//           "lastName": "Stevanon",
//           "email": "teliwiw111@hbehs.com"
//       }
//   ],
//   "contact_id": "256751",
//   "base_url": "https://quote-int.conteprestiti.com"
// }

// account con stato
// {
//   "id": "256601",
//   "step": "progress_summary_4",
//   "applicants": [
//       {
//           "fiscalCode": "STVMRA59E17G224C",
//           "firstName": "Mario",
//           "lastName": "Stevanon",
//           "gender": "M",
//           "status": "DRAFT",
//           "recordType": "NATURAL_PERSON",
//           "datePrivacy": "2022-05-17T09:50:59.849Z",
//           "phone": "+39 3333333333",
//           "email": "mokagi8156@akapple.com",
//           "addresses": [
//               {
//                   "zipCode": "36064",
//                   "streetName": "Via Veneto",
//                   "streetNumber": "3",
//                   "city": "Mason vicentino",
//                   "province": "VI",
//                   "country": "ITA",
//                   "status": "ACTIVE",
//                   "type": "RESIDENCE"
//               }
//           ],
//           "openBankingCode": "bc224c9ef2df4d5abcbe51627162e587",
//           "openBankingEnabled": "true",
//           "nationality": "ITA",
//           "birthCity": "Padova",
//           "birthProvince": "PD",
//           "birthCountry": "ITA",
//           "birthDate": "1959-05-17",
//           "educationLevel": "HIGH_SCHOOL_DIPLOMA",
//           "maritalStatus": "COHABITING",
//           "numHouseholdMembers": "TWO",
//           "peopleWithIncome": "TWO",
//           "jobData": {
//               "employmentStatus": "STUDENT"
//           },
//           "financialData": {
//               "hasOtherIncome": false,
//               "previousLoans": "NEVER"
//           }
//       }
//   ],
//   "consents": {
//       "consenso_comunicazioni_commerciali": true,
//       "consenso_survey": true,
//       "consenso_cessione_dati": true,
//       "consenso_profilazione": true
//   },
//   "contact_id": "256601",
//   "step_checkpoint": "RESUME_FINANCE",
//   "base_url": "https://quote-dev.conteprestiti.com",
//   "loan_intent": {
//       "amount": 7000,
//       "purpose": "NEW_CAR",
//       "term": 72
//   }
// }
