import styled from 'styled-components';
import { defaultTheme } from '../../components/ThemeWrapper';

/**
 * BASE CONTAINER
 */

export interface ContainerProps {
  align?: string;
  color?: string;
  fontSize?: number;
  fontStyle?: string;
  lineHeight?: number;
  padding?: number;
  paddingTop?: number;
  paddingBottom?: number;
  paddingLeft?: number;
  paddingRight?: number;
  paddingHorizontal?: number;
  paddingVertical?: number;
  margin?: number;
  marginTop?: number;
  marginBottom?: number;
  marginLeft?: number;
  marginRight?: number;
  marginVertical?: number;
  marginHorizontal?: number;
  fullHeight?: boolean;
  fullWidth?: boolean;
  width?: number;
  height?: number;
  widthMinContent?: boolean;
  backgroundColor?: string;
  borderRadius?: number;
  borderWidth?: number;
  borderStyle?: string;
  borderColor?: string;
  hasBoxShadow?: boolean;
  isClickable?: boolean;
  position?: string;
  opacity?: number;
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
}

export const Container = styled.div<ContainerProps>`
  font-size: ${props => props.fontSize || ''}px;
  line-height: ${props => props.lineHeight || ''}px;
  color: ${props => props.color || ''};
  text-align: ${props => props.align || 'center'};
  padding: ${props => props.padding || ''}px;
  padding-top: ${props => props.paddingTop || props.paddingVertical || ''}px;
  padding-bottom: ${props => props.paddingBottom || props.paddingVertical || ''}px;
  padding-left: ${props => props.paddingLeft || props.paddingHorizontal || ''}px;
  padding-right: ${props => props.paddingRight || props.paddingHorizontal || ''}px;
  margin: ${props => props.margin || ''}px;
  margin-top: ${props => props.marginTop || props.marginVertical || ''}px !important;
  margin-bottom: ${props => props.marginBottom || props.marginVertical || ''}px !important;
  margin-left: ${props => props.marginLeft || props.marginHorizontal || ''}px;
  margin-right: ${props => props.marginRight || props.marginHorizontal || ''}px;
  width: ${props =>
    props.widthMinContent ? 'min-content' : props.fullWidth ? '100%' : props.width ? `${props.width}px` : ''};
  height: ${props => (props.fullHeight ? '100vh' : props.height ? `${props.height}px` : '')};
  background-color: ${(props: ContainerProps) => props.backgroundColor || ''};
  border-radius: ${props => props.borderRadius || ''}px;
  border-width: ${props => props.borderWidth}px;
  border-style: ${props => props.borderStyle};
  border-color: ${props => props.borderColor};
  box-shadow: ${props => (props.hasBoxShadow ? '0 2px 20px rgba(0, 0, 0, 0.15)' : '')};
  cursor: ${props => (props.isClickable ? 'pointer' : '')};
  position: ${props => props.position};
  opacity: ${props => props.opacity ?? ''};
  top: ${props => props.top ?? ''}px;
  bottom: ${props => props.bottom ?? ''}px;
  left: ${props => props.left ?? ''}px;
  right: ${props => props.right ?? ''}px;
`;

/**
 * FLEX CONTAINER
 */

export interface FlexContainerProps {
  alignItems?: string;
  justifyContent?: string;
  flexDirection?: string;
  gap?: number;
  childrenFullWidth?: boolean;
}

export const FlexContainer = styled(Container)<FlexContainerProps>`
  display: flex;
  flex-direction: ${props => props.flexDirection ?? 'row'};
  align-items: ${props => props.alignItems ?? 'center'};
  justify-content: ${props => props.justifyContent ?? 'center'};
  gap: ${props => props.gap ?? 0}px;

  & > div {
    width: ${props => (props.childrenFullWidth ? '100%' : '')};
  }
`;

/**
 * INLINE CONTAINER
 */
export const Span = styled.span<ContainerProps>`
  color: ${(props: ContainerProps) => props.color || ''};
  font-size: ${(props: ContainerProps) => props.fontSize || ''}px;
  font-style: ${(props: ContainerProps) => props.fontStyle || ''};
`;

/**
 * CUSTOM
 */
interface CircleProps {
  isFilled: boolean;
}

export const Separator = styled(Span)<ContainerProps>`
  display: block;
  width: 60px;
  height: 4px;
  border-radius: 5px;
  background-color: ${(props: ContainerProps) => props.backgroundColor || ''};
`;

export const ObliqueSeparator = styled.span`
  display: inline-block;
  width: 20px;
  height: 1px;
  background-color: black;
  transform: translateY(-50%) rotate(-60deg);
  margin: 0;
`;

export const FixedButtonsContainer = styled(FlexContainer)`
  width: 100%;
  margin: 0 !important;
  position: fixed;
  bottom: 70px;
  left: 50%;
  transform: translateX(-50%);

  @media (min-width: 768px) {
    width: calc(100% - 110px);
    left: calc(50% + 55px);
  }
`;

export const Dots = styled.span`
  overflow: hidden;

  &::before {
    content: '${() => '.'.repeat(500)}';
    font-size: 13px;
    color: #b6b6b6;
  }
`;

interface ChipProps {
  backgroundColor?: string;
}

export const Chip = styled.div<ChipProps>`
  border-radius: 98px;
  background: ${props => props.backgroundColor || defaultTheme.colors.dark_blue};
  color: ${({ theme }) => theme.colors.white};
  font-size: 8px;
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  padding: 0 10px;
`;
