import React, { Fragment, lazy, useCallback, useEffect, useState } from 'react';

// Theme
import { defaultTheme } from '../ThemeWrapper';

// React Router
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Navigate } from 'react-router';

// Hotjar
import { hotjar } from 'react-hotjar';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { closeCustomerServiceAction } from '../../store/actions/customer-service.action';
import { selectContactId } from '../../store/selectors/select-contactId';

// Components
import { AuthHelper } from '../AuthHelper/AuthHelper';
import ErrorModal from '../ErrorModal/ErrorModal';
import InterruptWindow from '../interrupt-window/InterruptWindow';
import { CustomerServiceModal } from '../Modals/CustomerService';

// UI Components
import BottomSheet from '../../UI/BottomSheet/BottomSheet';
import Toast from '../../UI/Toast/Toast';
import { BoldParagraph, Paragraph } from '../../UI/paragraphs/Paragraphs';

// Pages
import Assistance from '../../pages/assistance/Assistance';
import SiteUnderMaintenance from '../../pages/site-under-maintenance/SiteUnderMaintenance';

// Expired and KO
import OffersExpired from '../../pages/expired/OffersExpired';
import OffersSelectedExpired from '../../pages/expired/OfferSelectedExpired';
import SignatureRequiredExpired from '../../pages/expired/SignatureRequiredExpired';
import DocsRequiredByUwAmlExpired from '../../pages/expired/DocsRequiredByUwAmlExpired';
import KycExpired from '../../pages/expired/KycExpired';
import DeclinedByRules from '../../pages/expired/DeclinedByRules';
import OfferBureauKo from '../../pages/expired/OfferBureauKo';
import Cancelled from '../../pages/expired/Cancelled';
import DeclinedByAmlUw from '../../pages/expired/DeclinedByAmlUw';

// Enums
import { AppRoutes, ProgressRoutes } from '../../enums/app-routes';
import { NotificationType } from '../../enums/notification-type';

// Utils
import { delay } from '../../utils/delay';

// Style
import "../../index.css";


const TestLanding = lazy(() => delay(import('../../features/landing/test-landing')));
const Insurance = lazy(() => import("../../features/insurance/insurance"));

const OTP = lazy(() => delay(import('../../features/otp/otp')));
const DataCollectionPOL = lazy(() =>
  delay(import('../../pages/data-collection-pol/DataCollectionPOL'))
);
const OptimizedDataCollection = lazy(() => delay(import('../../features/sections/data-collection/optimized/OptimizedDataCollection')));
const Progress = lazy(() => delay(import('../../features/progress/progress')));
const Adv = lazy(() => delay(import('../../pages/ADV/ADV')));
const Negotiation = lazy(() => delay(import('../../features/sections/negotiation/Negotiation')));
const KYC = lazy(() => delay(import('../../pages/KYC/KYC')));
const KYCDesktop = lazy(() => delay(import('../../pages/KYC/pages/VideoSelfie/pages/Desktop/Desktop')));
const ConsentsPageMitek = lazy(() =>
  delay(import('../../pages/KYC/pages/VideoSelfie/pages/ConsentsPageMitek/ConsentsPageMitek'))
);
const SPIDCallback = lazy(() =>
  delay(import('../../pages/KYC/pages/SPID/pages/SPIDCallback/SPIDCallback'))
);
const VideoSelfie = React.lazy(() => delay(import('../../pages/KYC/pages/VideoSelfie/VideoSelfie')));
const IncomeDocumentation = React.lazy(() =>
  delay(import('../../features/documentation/income-documentation'))
);
const PersonalArea = lazy(() => delay(import('../../pages/PersonalArea/PersonalArea')));
const Continue = lazy(() => delay(import('../../features/recover/recover')));
const ConsentsPageTink = lazy(() =>
  delay(import('../../pages/OpenBanking/pages/ConsentsPageTink/ConsentsPageTink'))
);
const OpenBanking = lazy(() => delay(import('../../pages/OpenBanking/OpenBanking')));
const NotFound = lazy(() => delay(import('../../pages/NotFound/NotFound')));
const LeadsContainer = lazy(() => delay(import('../../features/leads/Container')));
//#endregion

let broadcastChannel = new BroadcastChannel('conte-interrupt-channel');
const homeUrl = 'https://www.conteprestiti.it/';

export default function CustomRoutes() {

  const dispatch = useDispatch();

  /**
   * useSelector
   */
  const isCustomerServiceOpen = useSelector(state => state?.customerService.isOpen);
  const userId = useSelector(selectContactId);
  const notificationPopup = useSelector(state => state?.notificationPopup);
  const loadingPopup = useSelector(state => state?.loadingPopup);

  /**
   * useState
   */
  const [showInterrupt, setShowInterrupt] = useState(false);

  /**
   * useEffect
   */
  useEffect(() => {
    broadcastChannel = new BroadcastChannel('conte-interrupt-channel');
    addBroadcastChannelListeners();
    broadcastChannel.postMessage('open');
  }, []);

  useEffect(() => {
    addBroadcastChannelListeners();
  }, [showInterrupt]);

  // Hotjar
  useEffect(() => {
    if (process.env.REACT_APP_HJ_ID) {
      const hjOpt = { hjid: process.env.REACT_APP_HJ_ID, hjsv: 6 };
      hotjar.initialize(hjOpt.hjid, hjOpt.hjsv);
      hotjar.identify(userId, {});
    }
  }, [userId]);

  /**
   * handlers and Callbacks
   */
  const handleRedirectHome = () => {
    window.location.href = homeUrl;
  };

  const handleSwitchActivePage = () => {
    setShowInterrupt(false);
    broadcastChannel.postMessage('isOpened');
    window.location.href = '/continue';
  };

  const addBroadcastChannelListeners = useCallback(() => {
    broadcastChannel.onmessage = (event) => {
      if ('open' === event.data && !showInterrupt) {
        broadcastChannel.postMessage('isOpened');
      }
      if ('isOpened' === event.data) {
        setShowInterrupt(true);
      }
    };
  }, [showInterrupt]);

  const handleCloseCustomerService = useCallback(() => {
    dispatch(closeCustomerServiceAction());
  }, [dispatch]);

  return (
    /**
     * ! WARNING - DO NOT CHANGE THE ROUTE PATH ON THE FOLLOWING ROUTES
     * /kyc/eiDAW-redirect  -> used in Orchestrator
     * /continue            -> used in CRM
     * /ob-callback         -> used in Tink Dashboard
     */
    <BrowserRouter>
      {showInterrupt &&
        <InterruptWindow homeUrl={homeUrl} handleRedirectHome={handleRedirectHome}
                         handleSwitchActivePage={handleSwitchActivePage} />
      }

      <ErrorModal />

      <Toast show={notificationPopup.isOpen} type={notificationPopup.type} title={notificationPopup.title}
             message={notificationPopup.message}>
        {NotificationType.EMAIL_VERIFIED === notificationPopup.type &&
          <Fragment>
            <Paragraph color={defaultTheme.colors.dark_blue}>Ben fatto,</Paragraph>
            <Paragraph className='mt-2'>
              La tua email è stata&nbsp;<BoldParagraph>verificata</BoldParagraph>&nbsp;
              correttamente&nbsp;💪
            </Paragraph>
          </Fragment>
        }
      </Toast>

      <Toast show={loadingPopup.isOpen} type={NotificationType.INFO} title={loadingPopup.title}
             message={loadingPopup.message} />

      {/* Customer Service */}
      <BottomSheet isOpen={isCustomerServiceOpen} onClose={handleCloseCustomerService}>
        <CustomerServiceModal />
      </BottomSheet>

      {!showInterrupt &&
        <AuthHelper>
          <Routes>
            {process.env.REACT_APP_UNDER_MAINTENANCE === 'all' ? (
                <>
                  <Route path='*' element={<Navigate replace to={AppRoutes.SITE_UNDER_MAINTENANCE} />} />
                </>
              ) :
              (
                <>
                  {
                    process.env.REACT_APP_ENV !== 'pro' && (
                      <>
                        <Route path='/' element={<TestLanding />} />
                        <Route path='/landing' element={<TestLanding />} />
                      </>
                    )
                  }
                  <Route path={`${AppRoutes.INSURANCE}/*`} element={<Insurance />} />
                  <Route path='otp' element={<OTP />} />
                  <Route path={AppRoutes.PROGRESS} element={<Progress />} />
                  <Route
                    path={`${AppRoutes.PROGRESS}/${ProgressRoutes.DATA_COLLECTION}`}
                    element={<Progress />}
                  />
                  <Route path={`${AppRoutes.PROGRESS}/${ProgressRoutes.NEGOTIATION}`} element={<Progress />} />
                  <Route path={`${AppRoutes.PROGRESS}/${ProgressRoutes.KYC}`} element={<Progress />} />
                  <Route
                    path={`${AppRoutes.OPTIMIZED_DATA_COLLECTION}/*`}
                    element={<OptimizedDataCollection />}
                  />
                  <Route path='data-collection-pol/*'
                         element={process.env.REACT_APP_UNDER_MAINTENANCE === AppRoutes.DATA_COLLECTION_POL ? redirectToUnderMaintenance :
                           <DataCollectionPOL />} />
                  <Route path={`${AppRoutes.LEADS}/*`}
                         element={<LeadsContainer />} />
                  <Route path='adv/*'
                         element={process.env.REACT_APP_UNDER_MAINTENANCE === AppRoutes.ADV ? redirectToUnderMaintenance :
                           <Adv />} />
                  <Route path='negotiation/*'
                         element={process.env.REACT_APP_UNDER_MAINTENANCE === AppRoutes.NEGOTIATION ? redirectToUnderMaintenance :
                           <Negotiation />} />
                  <Route path='kyc/*'
                         element={process.env.REACT_APP_UNDER_MAINTENANCE === AppRoutes.KYC ? redirectToUnderMaintenance :
                           <KYC />} />
                  <Route path='kyc/video-selfie/*'
                         element={process.env.REACT_APP_UNDER_MAINTENANCE === 'kyc/video-selfie' ? redirectToUnderMaintenance :
                           <VideoSelfie />} />
                  <Route path='kyc/video-selfie/privacy'
                         element={process.env.REACT_APP_UNDER_MAINTENANCE === 'kyc/video-selfie/privacy' ? redirectToUnderMaintenance :
                           <ConsentsPageMitek />} />
                  <Route path='kyc/video-selfie/desktop'
                         element={process.env.REACT_APP_UNDER_MAINTENANCE === 'kyc/video-selfie/desktop' ? redirectToUnderMaintenance :
                           <KYCDesktop />} />
                  <Route path='kyc/income-documentation/*'
                         element={process.env.REACT_APP_UNDER_MAINTENANCE === 'kyc/income-documentation' ? redirectToUnderMaintenance :
                           <IncomeDocumentation />} />
                  <Route path='kyc/eiDAW-redirect'
                         element={process.env.REACT_APP_UNDER_MAINTENANCE === 'kyc/eiDAW-redirect' ? redirectToUnderMaintenance :
                           <SPIDCallback />} />
                  <Route path={`${AppRoutes.PERSONAL_AREA}/*`}
                         element={process.env.REACT_APP_UNDER_MAINTENANCE === AppRoutes.PERSONAL_AREA ? redirectToUnderMaintenance :
                           <PersonalArea />} />
                  <Route path='continue' element={<Continue />} />
                  <Route path='openbanking/privacy'
                         element={process.env.REACT_APP_UNDER_MAINTENANCE === 'openbanking/privacy' ? redirectToUnderMaintenance :
                           <ConsentsPageTink />} />
                  <Route path='ob-callback'
                         element={process.env.REACT_APP_UNDER_MAINTENANCE === 'ob-callback' ? redirectToUnderMaintenance :
                           <OpenBanking />} />
                  <Route path='assistance/*'
                         element={process.env.REACT_APP_UNDER_MAINTENANCE === AppRoutes.ASSISTANCE ? redirectToUnderMaintenance :
                           <Assistance />} />

                  {/*Expired and KO Routes*/}
                  <Route path={AppRoutes.CANCELLED} element={<Cancelled />} />
                  <Route path={AppRoutes.DECLINED_BY_RULES} element={<DeclinedByRules />} />
                  <Route path={AppRoutes.DECLINED_BY_AML} element={<DeclinedByAmlUw />} />
                  <Route path={AppRoutes.DECLINED_BY_UW} element={<DeclinedByAmlUw />} />
                  <Route path={AppRoutes.OFFER_BUREAU_KO} element={<OfferBureauKo />} />
                  <Route path={AppRoutes.OFFERS_EXPIRED} element={<OffersExpired />} />
                  <Route path={AppRoutes.OFFER_SELECTED_EXPIRED} element={<OffersSelectedExpired />} />
                  <Route path={AppRoutes.SIGNATURE_REQUIRED_EXPIRED} element={<SignatureRequiredExpired />} />
                  <Route path={AppRoutes.DOCS_REQUIRED_BY_UW_EXPIRED} element={<DocsRequiredByUwAmlExpired />} />
                  <Route path={AppRoutes.DOCS_REQUIRED_BY_AML_EXPIRED} element={<DocsRequiredByUwAmlExpired />} />
                  <Route path={AppRoutes.KYC_EXPIRED} element={<KycExpired />} />

                  {/*Fallback*/}
                  <Route path='*' element={<NotFound />} />
                </>
              )}
            <Route path={AppRoutes.SITE_UNDER_MAINTENANCE} element={<SiteUnderMaintenance />} />
          </Routes>
        </AuthHelper>
      }
    </BrowserRouter>
  );
}

const redirectToUnderMaintenance = <Navigate replace to={`/${AppRoutes.SITE_UNDER_MAINTENANCE}`} />;
