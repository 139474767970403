import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { OrchestratorAPI } from '../../api';
import { DC_SLIDES } from '../../constants/data-collection-slides';
import { StepCheckpoints } from '../../constants/step-checkpoints';
import { SCOPO_RAPPORTO } from '../../pages/ADV/enum/scopo-rapporto';
import * as CRMService from '../../services/crm.service';
import { AdvData } from '../../types/procedure';
import { RootState } from '../index';

type UpdateAdvDataActionReturned = { advData: Partial<AdvData>; stepCheckpoint: StepCheckpoints };
type UpdateAdvDataActionArgs = Partial<AdvData>;

export const updateAdvDataAction = createAsyncThunk<UpdateAdvDataActionReturned, UpdateAdvDataActionArgs>(
  'updateAdvDataAction',
  async (advDataQuestionnaire, thunkAPI) => {
    const { loanIntent, procedureId } = thunkAPI.getState() as RootState;
    const slides = (thunkAPI.getState() as RootState).dataCollection.slides;

    try {
      // generate ADV implicit fields
      const { jobRole } = slides[DC_SLIDES.JOB_ROLE.ID] as any;
      const cod_professione = jobRole;
      const scopo_rapporto =
        SCOPO_RAPPORTO.find(sr => sr.projectValue === loanIntent?.purpose)?.value || undefined;

      const advData: Partial<AdvData> = {
        ...advDataQuestionnaire,
        cod_professione,
        scopo_rapporto,
        tipo_comportamento: 2
      };
      console.log('updateAdvDataAction ~ advData', advData);

      if (!procedureId) {
        throw new Error('updateAdvDataAction ~ procedureId is undefined');
      }

      await OrchestratorAPI.advChecks(procedureId, advData);
      const { stepCheckpoint } = await CRMService.updateQuestionnaire(advData);

      return { advData, stepCheckpoint };
    } catch (err) {
      const error = err as AxiosError;
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);
