import { createAction } from '@reduxjs/toolkit';
import { MainSliceState } from '../main-slice';
import { DOMAIN_PREFIX, getPrefix } from './action-prefix';

const prefix = getPrefix(DOMAIN_PREFIX.LOADING_DIALOG);

export const enableLoadingAction = createAction<Partial<MainSliceState['loading']['text']>>(
  prefix.concat('enable-loading')
);
export const disableLoadingAction = createAction<void>(prefix.concat('disable-loading'));
