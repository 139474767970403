import { DC_SLIDES } from '../../constants/data-collection-slides';
import { RootState } from '../index';

export const selectMaritalStatus = (state: RootState) => {
  return {
    maritalStatus: state.dataCollection.slides[DC_SLIDES.MARITAL_STATUS.ID]
  };
};

export const selectNumHouseHoldMembers = (state: RootState) =>
  state.dataCollection.slides[DC_SLIDES.NUM_HOUSEHOLD_MEMBERS.ID];

export const selectPeopleWithIncome = (state: RootState) =>
  state.dataCollection.slides[DC_SLIDES.PEOPLE_WITH_INCOME.ID];
