import React from 'react';

// React Bootstrap
import BootstrapModal from 'react-bootstrap/Modal';

// Components
import Image from '../Image/Image';

// Styles
import styles from './Modal.module.scss';


const Modal: any = {};

Modal.Container = ({ show, isDismissable = true, className, noPadding, onCloseHandler, children }: any) => (
  <BootstrapModal
    show={show}
    onHide={onCloseHandler}
    className={`${styles.customMargin} ${className}`}
    backdrop={isDismissable ? true : 'static'}
    keyboard={isDismissable}
    centered>
    <BootstrapModal.Header closeButton={isDismissable} />
    <BootstrapModal.Body className={noPadding ? styles.noPadding : ''}>
      <div className={[styles.modalWrap, !isDismissable && 'pt-5'].join(' ')}>{children}</div>
    </BootstrapModal.Body>
  </BootstrapModal>
);

Modal.Image = ({ src, className }: any) => (
  <div className={styles.modalImage}>
    <Image component={src} className={className} />
  </div>
);

Modal.Title = ({ children }: any) => <div className={styles.modalTitle}>{children}</div>;
Modal.Subtitle = ({ children }: any) => <div className={styles.modalText}>{children}</div>;

export default Modal;
