import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { OrchestratorAPI } from '../../api';
import * as CRMService from '../../services/crm.service';
import { CRMAccountDTO, HubspotDeal } from '../../types/exelab-crm/account';
import { MainSliceState } from '../main-slice';
import { ThunkExtra } from '../types';
import { setLoadingBarAction } from './set-loading-bar.action';
import { StepCheckpoints } from '../../constants/step-checkpoints';
import { updateCrmAccountAction } from './set-account-crm.action';
import { isExternalComparatorChannel, isLeadsChannel } from '../../utils/channel/channel.utils';
import { Sources } from '../../enums/sources';
import { setTrackingAction } from './set-tracking.action';
import { initSession } from '../../api/routes/orchestrator/prestiti-online';
import { Offer, Procedure } from '../../types/procedure';
import { setAnalyticsHubSpotAction } from './set-hubspot.action';
import { GeneralStatus } from '../../enums/offer/status';
import { getAccountCRM, getProcedure, getProcedureCRM } from '../../api/routes/orchestrator.routes';

type ApplyStateReturned = {
  account?: Partial<MainSliceState>;
  procedure?: Partial<HubspotDeal>;
  cbsProcedureStatus?: string;
  cbsProcedureOffers?: Array<Offer>;
  secciId?: string;
  hasCpiProduct?: boolean;
};

interface HubspotAndCbsStatusPayload {
  hubspotAccount: CRMAccountDTO;
  hubspotDeal?:HubspotDeal;
  cbsProcedure?: Procedure;
  secciId?: string;
}

export const getHubspotAndCbsStatusAction = createAsyncThunk<
  HubspotAndCbsStatusPayload,
  undefined,
  ThunkExtra
>('recover-action', async (_, { rejectWithValue }) => {
  try {
    const hubspotAccount = await getAccountCRM();
    const { procedureId, step_checkpoint } = hubspotAccount || {};
    let getCrmProcedureResponse, cbsProcedure, hubspotDeal, secciId;
    if (procedureId && ( step_checkpoint !== StepCheckpoints.DECLINED_BY_RULES && step_checkpoint !== StepCheckpoints.OFFER_BUREAU_KO ) ) {
      [cbsProcedure, getCrmProcedureResponse] = await Promise.all([getProcedure(procedureId), getProcedureCRM()]);
      hubspotDeal = getCrmProcedureResponse.crm;
      secciId = getCrmProcedureResponse.secciId
    }
    return { hubspotAccount, hubspotDeal, cbsProcedure, secciId };
  } catch (err) {
    const error = err as AxiosError;
    return rejectWithValue(error?.message);
  }
});

export const applyStateAction = createAsyncThunk<ApplyStateReturned, undefined, ThunkExtra>(
  'applyStateAction',
  async (_, { getState, rejectWithValue, dispatch }) => {
    const { channel } = getState();
    let account;
    try {
      account = await CRMService.getAccount();
    } catch (err) {
      const error = err as AxiosError;
      // account does not exist, return to landing page
      window.location.href = process.env.REACT_APP_LANDING_PAGE_URL || '';

      return rejectWithValue(error?.message);
    }

    const procedureId = account.procedureId;

    if (
      StepCheckpoints.INITIATION_LEADS === account.stepCheckpoint &&
      !isLeadsChannel(channel!) &&
      !isExternalComparatorChannel(channel!)
    ) {
      // Overwrite step checkpoint and channel
      dispatch(updateCrmAccountAction({ data: { channel }, stepCheckpoint: StepCheckpoints.DC_CONSENSUS }));
      account = undefined;
      return { account };
    }

    let cbsProcedureStatus, cbsProcedureOffers, hasCpiProduct;

    // If CRM has procedureId
    try {
      if (procedureId) {
        const cbsProcedure = await OrchestratorAPI.getProcedure(procedureId);
        cbsProcedureStatus = cbsProcedure?.status;
        const okLoanResponses = cbsProcedure.creditManagementOffersResponse.loanResponse
          .filter(loanResponse => loanResponse.generalStatus !== GeneralStatus.KO);
        cbsProcedureOffers = okLoanResponses.flatMap(loanResponse => loanResponse.offers ?? []);
        hasCpiProduct = okLoanResponses.length ? okLoanResponses[0]!.offers!.length > 1 : false;
      }
    } catch (error) {
      // We can skip this error
    }

    let procedure, secciId;
    try {
      const getCrmProcedureResponse = await OrchestratorAPI.getProcedureCRM();
      procedure = getCrmProcedureResponse.crm;
      secciId = getCrmProcedureResponse.secciId;
    } catch (err) {
      return { account, cbsProcedureStatus, cbsProcedureOffers, hasCpiProduct };
    }

    return { account, procedure, cbsProcedureStatus, cbsProcedureOffers, secciId, hasCpiProduct };
  }
);

// get data from Prestiti Online and apply to store
export const applyStatePrestitiOnlineAction = createAsyncThunk<Partial<MainSliceState>, string, ThunkExtra>(
  'applyStatePrestitiOnlineAction',
  async (applicationId, thunkAPI) => {
    try {
      thunkAPI.dispatch(setLoadingBarAction({ percentage: 50 }));

      // Init session with guest Cookie
      await initSession({ applicationId });

      const account: any = await CRMService.getAccount();

      const email = account.dataCollection?.slides?.contact_info?.email;

      const tracking = {
        source: Sources.REFERRALS,
        urlSource: document.referrer
      };

      thunkAPI.dispatch(setTrackingAction(tracking));
      thunkAPI.dispatch(setAnalyticsHubSpotAction({ email, tracking }));

      return account;
    } catch (err) {
      const error = err as AxiosError;
      console.error(error);
      window.location.href = process.env.REACT_APP_LANDING_PAGE_URL || '';

      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const applyKycDocumentsAction = createAsyncThunk('applyKycDocumentsAction', async (_, thunkAPI) => {
  try {
    const account = await CRMService.getAccount();
    const {
      // @ts-ignore
      kyc: { kyc_data }
    } = account;

    return kyc_data;
  } catch (err) {
    const error = err as AxiosError;
    return thunkAPI.rejectWithValue(error?.message);
  }
});
