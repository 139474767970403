import React from 'react';
import { useNavigate } from 'react-router';

import { defaultTheme } from '../../../components/ThemeWrapper';

import Emoji from 'react-emoji-render';

// UI Components
import Image from '../../../UI/Image/Image';
import { PageSubtitle, PageTitle } from '../../../UI/headers/Headers';
import { Container } from '../../../UI/containers/Containers';
import { BoldParagraph, Paragraph } from '../../../UI/paragraphs/Paragraphs';

// Components
import Slide from '../../../components/Slide/Slide';

// Resources
import { ReactComponent as conte_icon_feedback_ok } from '../../../assets/resources/images/conte_icon_feedback_ok.svg';


const BookingComplete: React.FC = () => {

  const navigate = useNavigate();

  const handleSubmit = () => {
    navigate({ pathname: '/continue' });
  };

  return (
    <Slide.Container>

      <Container>
        <Image component={conte_icon_feedback_ok} width={'130px'} height={'130px'} />
      </Container>

      <Container marginHorizontal={40} marginVertical={20}>

        <Container marginBottom={30}>
          <PageTitle fontSize={18} isBold>
            <BoldParagraph>Hai prenotato correttamente la tua chiamata!</BoldParagraph>
          </PageTitle>
        </Container>

        <Container marginBottom={50}>
          <PageSubtitle fontSize={18}>Entro poche ore sarai contattato dal nostro servizio clienti <Emoji
            text='💪' /></PageSubtitle>
        </Container>

        <Container>
          <Paragraph color={defaultTheme.colors.text}>
            Ti ricordiamo che il nostro servizio è attivo dal Lunedì al Venerdì dalle ore 9:00 alle 19:00.
          </Paragraph>
        </Container>

      </Container>

      <Slide.Actions>
        <Slide.FixedButton text='Torna alla tua richiesta' onClick={handleSubmit} />
      </Slide.Actions>

    </Slide.Container>
  );
};

export default BookingComplete;