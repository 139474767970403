export enum PERSONAL_AREA_ROUTES {
  EMPTY = 'no-procedure',
  PROCEDURE_EVALUATION = 'procedure-evaluation',
  DOCUMENTATION_REQUIRED = 'documentation-required',
  DOCUMENTATION_COMPLETE = 'documentation-complete',
  SIGNATURE_REQUIRED = 'signature-required',
  LOAN_LIST = 'loan-list',
  REPAYMENT_PLAN = 'repayment-plan',
  CONTRACT = 'contract',
  COMMUNICATION = 'communication',
  OTHER_ACTIONS = 'other-actions',
  IBAN_CHANGE = 'iban-change',
  EARLY_REPAYMENT = 'early-repayment',
  CONTRACT_WITHDRAWAL = 'contract-withdrawal',

  STARTING_OFFER_DISPLAYED = 'starting-offer',
  IBAN_CHANGE_REQUESTED = 'iban-change',

  // KO Routes
  // Step checkpoint
  DECLINED_BY_RULES = 'declined-by-rules',
  OFFER_BUREAU_KO = 'offer-bureau-ko',

  // Procedure status
  CANCELLED = 'cancelled',
  OFFERS_EXPIRED = 'offers-expired',
  OFFER_SELECTED_EXPIRED = 'offer-selected-expired',
  SIGNATURE_REQUIRED_EXPIRED = 'signature-required-expired',
  DOCS_REQUIRED_BY_UW_EXPIRED = 'uw-required-docs-expired',
  DOCS_REQUIRED_BY_AML_EXPIRED = 'aml-required-docs-expired',
  KYC_EXPIRED = 'kyc-expired',
  DECLINED_BY_AML = 'declined-by-aml',
  DECLINED_BY_UW = 'declined-by-uw'
}
