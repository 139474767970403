export const DC_SLIDES: Record<DataCollectionSlidesKeys, DataCollectionSlidesValues> = {
  CLIENT_TYPE: { ID: 'client_type', PROGRESS: 1, SECTION: 0 },

  //#region SECTION 1
  PROGRESS_SUMMARY_1: { ID: 'progress_summary_1', PROGRESS: 2, SECTION: 1 },
  PERSONAL_INFO_1: { ID: 'personal_info_1', PROGRESS: 3, SECTION: 1 },
  PERSONAL_INFO_2: { ID: 'personal_info_2', PROGRESS: 4, SECTION: 1 },
  FISCAL_ID: { ID: 'fiscal_id', PROGRESS: 5, SECTION: 1 },
  CONTACT_INFO: { ID: 'contact_info', PROGRESS: 6, SECTION: 1 },
  PRIVACY_CONSENTS: { ID: 'privacy_consents', PROGRESS: 7, SECTION: 1 },
  ADDRESS: { ID: 'address', PROGRESS: 8, SECTION: 1 },
  MARITAL_STATUS: { ID: 'marital_status', PROGRESS: 9, SECTION: 1 },
  NUM_HOUSEHOLD_MEMBERS: { ID: 'num_household_members', PROGRESS: 10, SECTION: 1 },
  PEOPLE_WITH_INCOME: { ID: 'people_with_income', PROGRESS: 11, SECTION: 1 },
  EDUCATION_LEVEL: { ID: 'education_level', PROGRESS: 12, SECTION: 1 },
  //#endregion

  //#region SECTION 2
  PROGRESS_SUMMARY_2: { ID: 'progress_summary_2', PROGRESS: 13, SECTION: 2 },
  EMPLOYMENT_STATUS: { ID: 'employment_status', PROGRESS: 14, SECTION: 2 },
  VAT_NUMBER: { ID: 'vat_number', PROGRESS: 15, SECTION: 2 },
  HIRED_ON_YEAR: { ID: 'hired_on_year', PROGRESS: 16, SECTION: 2 },
  HIRED_ON_MONTH: { ID: 'hired_on_month', PROGRESS: 17, SECTION: 2 },
  COMPANY_INDUSTRY: { ID: 'company_industry', PROGRESS: 18, SECTION: 2 },
  COMPANY_SIZE: { ID: 'company_size', PROGRESS: 19, SECTION: 2 },
  JOB_ROLE: { ID: 'job_role', PROGRESS: 20, SECTION: 2 },
  //#endregion

  //#region SECTION 3
  PROGRESS_SUMMARY_3: { ID: 'progress_summary_3', PROGRESS: 21, SECTION: 3 },
  LIVING_SITUATION: { ID: 'living_situation', PROGRESS: 22, SECTION: 3 },
  PREVIOUS_LOANS: { ID: 'previous_loans', PROGRESS: 23, SECTION: 3 },
  LATE_PAYMENTS: { ID: 'late_payments', PROGRESS: 24, SECTION: 3 },
  MONTHLY_INCOME: { ID: 'monthly_income', PROGRESS: 25, SECTION: 3 },
  INCOME_SOURCES: { ID: 'income_sources', PROGRESS: 26, SECTION: 3 },
  COUPON_CODE: { ID: 'coupon_code', PROGRESS: 27, SECTION: 3 },
  //#endregion

  //#region SECTION 4
  PROGRESS_SUMMARY_4: { ID: 'progress_summary_4', PROGRESS: 28, SECTION: 4 },
  //#endregion
};

export type DataCollectionSlidesKeys =
  | 'CLIENT_TYPE'
  | 'PROGRESS_SUMMARY_1'
  | 'PERSONAL_INFO_1'
  | 'PERSONAL_INFO_2'
  | 'FISCAL_ID'
  | 'CONTACT_INFO'
  | 'PRIVACY_CONSENTS'
  | 'ADDRESS'
  | 'MARITAL_STATUS'
  | 'NUM_HOUSEHOLD_MEMBERS'
  | 'PEOPLE_WITH_INCOME'
  | 'EDUCATION_LEVEL'
  | 'VAT_NUMBER'
  | 'PROGRESS_SUMMARY_2'
  | 'EMPLOYMENT_STATUS'
  | 'HIRED_ON_YEAR'
  | 'HIRED_ON_MONTH'
  | 'COMPANY_INDUSTRY'
  | 'COMPANY_SIZE'
  | 'JOB_ROLE'
  | 'PROGRESS_SUMMARY_3'
  | 'LIVING_SITUATION'
  | 'PREVIOUS_LOANS'
  | 'LATE_PAYMENTS'
  | 'MONTHLY_INCOME'
  | 'INCOME_SOURCES'
  | 'COUPON_CODE'
  | 'PROGRESS_SUMMARY_4';

export type DataCollectionSlidesValues = {
  ID: Lowercase<DataCollectionSlidesKeys>;
  PROGRESS: number;
  SECTION: 0 | 1 | 2 | 3 | 4;
};
