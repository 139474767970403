import { useCallback, useEffect, useMemo } from 'react';
import { pushFunnelStep } from '../../../services/data-layer/data-layer.service';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { Action } from '../enums/action';
import { DataLayerFunnelStep } from '../../../types/data-layer/data-layer';
import {
  selectAddressInfo,
  selectEmploymentStatus,
  selectMonthlyIncome,
  selectPersonalInfo,
  selectPersonalInfo2,
  selectStepCheckpoint
} from '../../../store/selectors';
import { selectLoanIntent } from '../../../store/selectors/select-loan-intent';
import { selectCompanyIndustry, selectCompanySize } from '../../../store/selectors/select-company';
import {
  selectMaritalStatus,
  selectNumHouseHoldMembers,
  selectPeopleWithIncome
} from '../../../store/selectors/select-family';
import { selectCouponCode } from '../../../store/selectors/select-coupon-code';
import { selectLivingSituation } from '../../../store/selectors/select-living-situation';
import { selectOfferPreSelected } from '../../../store/selectors/select-offers';
import { PageSections } from '../../../enums/data-layer/page-sections';
import { getStepNumber } from '../services/data-layer.service';

const useDataLayer = (pageSection: PageSections) => {
  const stepCheckpoint = useSelector(selectStepCheckpoint);
  const { pathname } = useLocation();
  const { amount, term, purpose } = useSelector(selectLoanIntent) || {};
  const { gender } = useSelector(selectPersonalInfo);
  const { birthYear, birthMonth, birthDay, nationality } = useSelector(selectPersonalInfo2);
  const employmentStatus = useSelector(selectEmploymentStatus);
  const { companyIndustry } = useSelector(selectCompanyIndustry);
  const { companySize } = useSelector(selectCompanySize);
  const { netMonthlySalaryEur, grossYearlySalaryEur } = useSelector(selectMonthlyIncome);
  const { city } = useSelector(selectAddressInfo);
  const { maritalStatus } = useSelector(selectMaritalStatus).maritalStatus;
  const { numHouseholdMembers } = useSelector(selectNumHouseHoldMembers);
  const { peopleWithIncome } = useSelector(selectPeopleWithIncome);
  const { homeStatus, rentMortgageAmount } = useSelector(selectLivingSituation);
  const { coupon } = useSelector(selectCouponCode);
  const { installmentsAmount, taeg } = useSelector(selectOfferPreSelected) || {};

  const stepNumber = useMemo(
    () => (getStepNumber(pageSection, pathname) ? getStepNumber(pageSection, pathname).toString() : ''),
    [pageSection, pathname]
  );

  const handlePushFunnelStep = useCallback(
    (input: any, errorMessage?: string) => {
      const applicantsData = input?.data?.applicants ? input.data.applicants[0] : {};
      const loanIntentData = input?.data?.loanIntent;

      return pushFunnelStep({
        action: Action.SENT,
        step_fase: pageSection,
        step_checkpoint: input?.step_checkpoint ?? stepCheckpoint ?? '',
        step_number: stepNumber,
        step_name: pathname ?? '',
        selection_letter: '', // Useless -> Skip
        selection_letter_prev: '', // Useless -> Skip
        status: errorMessage ? 'KO' : '',
        error_message: errorMessage ?? '',
        motivation: loanIntentData ? loanIntentData.purpose : purpose ?? '',
        required_amount: loanIntentData ? loanIntentData.amount?.toString() : amount?.toString() ?? '',
        duration: loanIntentData ? loanIntentData.term?.toString() : term?.toString() ?? '',
        gender: applicantsData?.gender ?? gender ?? '',
        birthday: applicantsData?.birthDate
          ? applicantsData?.birthDate.split('-').reverse().join('/')
          : birthDay
          ? [birthDay, birthMonth, birthYear].join('/')
          : '',
        nationality: applicantsData?.nationality ?? nationality ?? '',
        employment_status: applicantsData?.jobData?.employmentStatus ?? employmentStatus ?? '',
        business_sector: applicantsData?.jobData?.companyIndustry ?? companyIndustry ?? '',
        company_size: applicantsData?.jobData?.companySize ?? companySize ?? '',
        income:
          applicantsData?.financialData?.netMonthlySalaryEur ??
          applicantsData?.financialData?.grossYearlySalaryEur ??
          netMonthlySalaryEur ??
          grossYearlySalaryEur ??
          '',
        city: applicantsData?.addresses ? applicantsData?.addresses[0].city : city ?? '',
        marital_status: applicantsData?.maritalStatus ?? maritalStatus ?? '',
        family_members: applicantsData?.numHouseholdMembers ?? numHouseholdMembers ?? '',
        people_with_income: applicantsData?.peopleWithIncome ?? peopleWithIncome ?? '',
        living_situation: applicantsData?.homeStatus ?? homeStatus ?? '',
        monthly_payment: applicantsData?.rentMortgageAmount ?? rentMortgageAmount ?? '',
        coupon: applicantsData?.promoCode ?? coupon ?? '',
        installment_value: installmentsAmount?.toString() ?? '',
        taeg: taeg?.toString() ?? ''
      });
    },
    [
      pathname,
      stepNumber,
      stepCheckpoint,
      purpose,
      amount,
      term,
      gender,
      birthDay,
      birthMonth,
      birthYear,
      nationality,
      employmentStatus,
      companyIndustry,
      companySize,
      netMonthlySalaryEur,
      grossYearlySalaryEur,
      city,
      maritalStatus,
      numHouseholdMembers,
      peopleWithIncome,
      homeStatus,
      rentMortgageAmount,
      coupon,
      installmentsAmount,
      taeg
    ]
  );

  useEffect(() => {
    const dataLayerFunnelStep: DataLayerFunnelStep = {
      action: Action.OPEN,
      step_fase: pageSection ?? '',
      step_checkpoint: stepCheckpoint ?? '',
      step_name: pathname ?? '',
      step_number: stepNumber,
      selection_letter: '',
      selection_letter_prev: '',
      status: '',
      error_message: '',
      motivation: purpose ?? '',
      required_amount: amount?.toString() ?? '',
      duration: term?.toString() ?? '',
      gender: gender ?? '',
      birthday: birthDay ? [birthDay, birthMonth, birthYear].join('/') : '',
      nationality: nationality ?? '',
      employment_status: employmentStatus ?? '',
      business_sector: companyIndustry ?? '',
      company_size: companySize ?? '',
      income: netMonthlySalaryEur ?? grossYearlySalaryEur ?? '',
      city: city ?? '',
      marital_status: maritalStatus ?? '',
      family_members: numHouseholdMembers ?? '',
      people_with_income: peopleWithIncome ?? '',
      living_situation: homeStatus ?? '',
      monthly_payment: rentMortgageAmount ?? '',
      coupon: coupon ?? '',
      installment_value: installmentsAmount?.toString() ?? '',
      taeg: taeg?.toString() ?? ''
    };

    pushFunnelStep(dataLayerFunnelStep);
  }, [pathname, stepNumber]);

  return { handlePushFunnelStep };
};

export default useDataLayer;
