import { createAsyncThunk } from '@reduxjs/toolkit';

// Axios
import { AxiosError } from 'axios';

// Api
import { OrchestratorAPI } from '../../api';

// Types
import { DocsRequiredReadDTO } from '../../types/docs-required';
import { LoanStatusReadDTO } from '../../types/loan-status';
import { ThunkExtra } from '../types';
import { CbsProcedureStatus } from '../../enums/cbs-procedure-status';
import { StepCheckpoints } from '../../constants/step-checkpoints';
import { PERSONAL_AREA_ROUTES } from '../../constants/personal-area-routes';
import { Document } from '../../types/procedure';

// Enums
import { NotificationType } from '../../enums/notification-type';
import { DocumentRoles } from '../../enums/cbs/document-roles';

// Utils
import notificationPopupUtil from '../../utils/notification-popup/notification.popup.util';
import { uniq } from 'lodash';

export interface ApplyPersonalAreaStatusPayload {
  route: PERSONAL_AREA_ROUTES;
  loanData?: LoanStatusReadDTO;
  documentsRequired?: DocsRequiredReadDTO;
  availableDocuments?: Array<DocumentRoles>;
}

interface ApplyPersonalAreaStatusInput {
  navigateTo?: PERSONAL_AREA_ROUTES;
}

export const applyPersonalAreaStatusAction = createAsyncThunk<
  ApplyPersonalAreaStatusPayload,
  ApplyPersonalAreaStatusInput,
  ThunkExtra
>('applyPersonalAreaStatus', async (applyPersonalAreaStatusInput, { getState, rejectWithValue }) => {
  const path = applyPersonalAreaStatusInput?.navigateTo;
  const procedureId = getState().procedureId;
  const stepCheckpoint = getState().stepCheckpoint;

  if (!procedureId) {
    // TODO - handle this case
    throw new Error('No procedure id');
  }

  try {
    if (path) {
      return { route: PERSONAL_AREA_ROUTES.DOCUMENTATION_COMPLETE };
    }

    const procedure = await OrchestratorAPI.getProcedure(procedureId);

    let payload: ApplyPersonalAreaStatusPayload = {
      route: PERSONAL_AREA_ROUTES.EMPTY,
      availableDocuments: uniq(
        procedure.documents.map((document: Document) => document.role as DocumentRoles)
      )
    };

    switch (procedure.status) {
      case CbsProcedureStatus.OFFER_SELECTED:
      case CbsProcedureStatus.KYC_EVALUATION:
      case CbsProcedureStatus.UW_EVALUATION:
      case CbsProcedureStatus.ADV_EVALUATION:
      case CbsProcedureStatus.ADV_REINFORCED_EVALUATION:
        // PROCEDURE EVALUATION page
        payload = { ...payload, route: PERSONAL_AREA_ROUTES.PROCEDURE_EVALUATION };
        break;

      case CbsProcedureStatus.SIGNATURE_REQUIRED:
        // SIGNATURE page
        payload = { ...payload, route: PERSONAL_AREA_ROUTES.SIGNATURE_REQUIRED };
        break;

      case CbsProcedureStatus.DOCS_REQUIRED_BY_UW:
      case CbsProcedureStatus.DOCS_REQUIRED_BY_AML: {
        payload = {
          ...payload,
          route: PERSONAL_AREA_ROUTES.DOCUMENTATION_REQUIRED,
          documentsRequired: procedure.requiredDocumentTypes
        };
        break;
      }

      case CbsProcedureStatus.CONTRACT_SIGNED:
      case CbsProcedureStatus.LOAN_DISBURSED:
      case CbsProcedureStatus.IN_LIFE:
      case CbsProcedureStatus.PRE_EARLY_COLLECTION:
      case CbsProcedureStatus.EARLY_COLLECTION:
      case CbsProcedureStatus.SOFT_COLLECTION:
      case CbsProcedureStatus.STRONG_COLLECTION:
      case CbsProcedureStatus.POST_DBT_1st_ATTEMPT:
      case CbsProcedureStatus.POST_DBT_2nd_ATTEMPT:
      case CbsProcedureStatus.POST_DBT_3rd_ATTEMPT:
      case CbsProcedureStatus.WRITE_OFF_PROPOSAL:
      case CbsProcedureStatus.PAID_OF:
      case CbsProcedureStatus.WITHDRAWAL_REQUESTED:
      case CbsProcedureStatus.WITHDRAWAL_DONE:
      case CbsProcedureStatus.WITHDRAWAL_COMPLETED:
      case CbsProcedureStatus.EARLY_TERM_REQUESTED:
      case CbsProcedureStatus.TOTAL_EARLY_TERM_DONE: {
        // LOAN STATUS page
        const loanStatus = await OrchestratorAPI.loanStatus(procedureId);
        payload = {
          ...payload,
          route: PERSONAL_AREA_ROUTES.LOAN_LIST,
          loanData: loanStatus
        };
        break;
      }

      case CbsProcedureStatus.OFFERS_EXPIRED:
        payload = { ...payload, route: PERSONAL_AREA_ROUTES.OFFERS_EXPIRED };
        break;

      case CbsProcedureStatus.OFFER_SELECTED_EXPIRED:
        payload = { ...payload, route: PERSONAL_AREA_ROUTES.OFFER_SELECTED_EXPIRED };
        break;

      case CbsProcedureStatus.SIGNATURE_REQUIRED_EXPIRED:
        payload = { ...payload, route: PERSONAL_AREA_ROUTES.SIGNATURE_REQUIRED_EXPIRED };
        break;

      case CbsProcedureStatus.DOCS_REQUIRED_BY_UW_EXPIRED:
        payload = { ...payload, route: PERSONAL_AREA_ROUTES.DOCS_REQUIRED_BY_UW_EXPIRED };
        break;

      case CbsProcedureStatus.DOCS_REQUIRED_BY_AML_EXPIRED:
        payload = { ...payload, route: PERSONAL_AREA_ROUTES.DOCS_REQUIRED_BY_AML_EXPIRED };
        break;

      case CbsProcedureStatus.KYC_EXPIRED:
        payload = { ...payload, route: PERSONAL_AREA_ROUTES.KYC_EXPIRED };
        break;

      case CbsProcedureStatus.CANCELLED:
        payload = { ...payload, route: PERSONAL_AREA_ROUTES.CANCELLED };
        break;

      case CbsProcedureStatus.DECLINED_BY_AML:
        payload = { ...payload, route: PERSONAL_AREA_ROUTES.DECLINED_BY_AML };
        break;

      case CbsProcedureStatus.DECLINED_BY_UW:
        payload = { ...payload, route: PERSONAL_AREA_ROUTES.DECLINED_BY_UW };
        break;

      case CbsProcedureStatus.DECLINED_BY_RULES:
      default:
        switch (stepCheckpoint) {
          case StepCheckpoints.STARTING_OFFER_DISPLAYED:
            payload = { ...payload, route: PERSONAL_AREA_ROUTES.STARTING_OFFER_DISPLAYED };
            break;
          case StepCheckpoints.IBAN_CHANGE_REQUESTED:
            payload = { ...payload, route: PERSONAL_AREA_ROUTES.IBAN_CHANGE_REQUESTED };
            break;
          case StepCheckpoints.DECLINED_BY_RULES:
            payload = { ...payload, route: PERSONAL_AREA_ROUTES.DECLINED_BY_RULES };
            break;
          case StepCheckpoints.OFFER_BUREAU_KO:
            payload = { ...payload, route: PERSONAL_AREA_ROUTES.OFFER_BUREAU_KO };
            break;
          default:
            payload = { ...payload, route: PERSONAL_AREA_ROUTES.EMPTY };
            break;
        }
      // BLANK page
    }

    return payload;
  } catch (err) {
    const error = err as AxiosError;
    return rejectWithValue(error?.message);
  }
});

export const startEarlyTermAction = createAsyncThunk<void, undefined, ThunkExtra>(
  'startEarlyTerm',
  async (_, { getState, rejectWithValue, dispatch }) => {

    const procedureId = getState().procedureId;

    if (!procedureId) {
      // TODO - handle this case
      throw new Error('No procedure id');
    }

    try {
      const now = new Date();
      const dateTerm = now.toISOString().substring(0, now.toISOString().indexOf('T'));
      await OrchestratorAPI.startEarlyTerm(procedureId, dateTerm);
      notificationPopupUtil.openAndResetPopup(dispatch, 'Abbiamo inviato una email di riepilogo alla tua casella di posta elettronica', NotificationType.SUCCESS);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error?.message);
    }
  }
);

export const startWithdrawalAction = createAsyncThunk<void, undefined, ThunkExtra>(
  'startWithdrawal',
  async (_, { getState, rejectWithValue, dispatch }) => {
    const procedureId = getState().procedureId;

    if (!procedureId) {
      // TODO - handle this case
      throw new Error('No procedure id');
    }

    try {
      const now = new Date();
      const dateTerm = now.toISOString().substring(0, now.toISOString().indexOf('T'));
      await OrchestratorAPI.startWithdrawal(procedureId, dateTerm);
      notificationPopupUtil.openAndResetPopup(dispatch, 'Abbiamo inviato una email di riepilogo alla tua casella di posta elettronica', NotificationType.SUCCESS);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error?.message);
    }
  }
);
