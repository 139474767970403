import React from 'react';
import styles from './FixedButton.module.scss';

const FixedButton = ({ id, name, label, type, disabled, onClick, children, ...rest }: any) => {
  const classes = [styles.fixedButton];

  if (disabled) {
    classes.push(styles.disabled);
  }

  return (
    <button
      id={id}
      name={name}
      type={type ? type : 'submit'}
      className={classes.join(' ')}
      disabled={disabled}
      onClick={onClick}
      {...rest}>
      {label || children}
    </button>
  );
};

export default FixedButton;
