// Redux
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkExtra } from '../types';

// Services
import * as CRMService from '../../services/crm.service';

// Utils
import { generateTinkLink } from '../../utils/generate-tink-link';

// Axios
import { AxiosError } from 'axios';

// Types
import { CRMAccountDTO } from '../../types/exelab-crm/account';


export const redirectToTinkAction = createAsyncThunk<void, void, ThunkExtra>(
  'redirectToTinkAction',
  async (_, { getState, rejectWithValue }) => {

    const { applicationId } = getState();
    const fiscalCode = getState()?.dataCollection?.slides?.fiscal_id?.code;
    const { email } = getState()?.dataCollection?.slides?.contact_info;

    try {

      const consents: Partial<CRMAccountDTO['consents']> = {
        consenso_open_banking: true,
        consenso_open_banking_psd2: true
      };
      await CRMService.updateConsents(consents);

      let tinkStatus = {
        email,
        fiscalCode,
        applicationId
      };

      const tinkLink = generateTinkLink(tinkStatus, fiscalCode);

      // Redirect to Tink
      window.location.assign(tinkLink);

    } catch (error) {

      rejectWithValue((error as AxiosError)?.message);
    }
  }
);
