import React, { useCallback } from 'react';
// import { useInterval } from '../../hooks/useInterval';
import Button from '../../UI/Button/Button';
import { Font } from '../../UI/Font/Font';
import { useNavigate } from 'react-router';
import { AppRoutes } from '../../enums/app-routes';
import { AssistanceRoutes } from '../../enums/assistance-routes';
import { closeCustomerServiceAction } from '../../store/actions/customer-service.action';
import { useDispatch } from 'react-redux';
import { KNOWLEDGE_FAQ } from '../../constants/links';

// const activityMetadata = {
//   dayStart: 1,
//   dayEnd: 5,
//   timeStart: 9,
//   timeEnd: 19
// };

export function CustomerServiceModal() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleBookingNavigation = useCallback(() => {
    dispatch(closeCustomerServiceAction());
    navigate({ pathname: `/${AppRoutes.ASSISTANCE}/${AssistanceRoutes.BOOKING_CALL}` }, { replace: true });
  }, [navigate]);

  return (
    <div style={{ padding: '10px 30px' }}>
      <Font style={{ color: '#0F4683', fontSize: '20px', marginBottom: '10px' }}>
        <strong>Hai bisogno di aiuto? 😃</strong>
      </Font>
      <Font style={{ color: '#666666', fontSize: '15px', marginBottom: '10px' }}>
        Se stai riscontrando un problema o vuoi avere maggiori informazioni, abbiamo creato per te l’area
        assistenza che è stata suddivisa con le domande e le problematiche di contatto più frequenti.
      </Font>

      <a href={KNOWLEDGE_FAQ} target='_blank' rel='noreferrer'>
        <Button fluid customClasses={['my-4']}>
          Cerca nell'area assistenza
        </Button>
      </a>

      {/* <Divider /> */}

      {/* {isCallCenterActive ? (
        <Font style={{ color: '#666666', fontSize: '15px', margin: '20px 0' }}>
          Se invece preferisci avere un contatto telefonico chiama il nostro servizo clienti. Un nostro
          operatore ti darà supporto nel più breve tempo possibile.
        </Font>
      ) : (
        <Font style={{ color: '#666666', fontSize: '15px', margin: '20px 0' }}>
          Al momento il nostro servizio clienti telefonico non è disponibile. Il servizio è attivo dal{' '}
          <b>Lunedì</b> a <b>Venerdì</b> dalle <b>{activityMetadata.timeStart}</b> alle{' '}
          <b>{activityMetadata.timeEnd}</b>.
        </Font>
      )} */}

      <Button
        fluid
        color='primary'
        variant='outline'
        style={{ color: '#0F4683', borderColor: '#0F4683', padding: 0, height: '40px' }}
        icon='icon-conte_icon_prenota_chiamata'
        iconPosition='left'
        onClick={handleBookingNavigation}>
        <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ fontSize: '12px' }}>Prenota una chiamata</div>
          </div>
        </div>
      </Button>

      {/* <a href={`tel:${csPhone}`}>
        <Button
          fluid
          color="primary"
          variant="outline"
          style={{ color: '#0F4683', borderColor: '#0F4683', padding: 0 }}
          icon="icon-conte_icon_prenota_chiamata"
          iconPosition="left"
          disabled={!isCallCenterActive}>
          <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ color: '#4DABFF' }}>{csPhone}</div>
              <div style={{ fontSize: '12px' }}>Chiama il servizio clienti</div>
            </div>
          </div>
        </Button>
      </a> */}
      {/* 
      {!isCallCenterActive && (
        <React.Fragment>
          <Font style={{ color: '#666666', fontSize: '15px', margin: '20px 0' }}>
            Se preferisci puoi comunque inviarci un’email, ti risponderemo nel più breve tempo possibile.
          </Font>

          <Button
            fluid
            color="primary"
            variant="outline"
            style={{ color: '#0F4683', borderColor: '#0F4683', padding: 0 }}
            icon="icon-conte_icon_messaggi"
            iconPosition="left">
            <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ color: '#4DABFF' }}>assistenza@conteprestiti.it</div>
                <div style={{ fontSize: '12px' }}>Invia una email</div>
              </div>
            </div>
          </Button>
        </React.Fragment>
      )} */}
    </div>
  );
}
