export enum Events {
  INIT = 'init',
  ERROR_MONITOR = 'error_monitor',
  FUNNEL_STEP = "funnel_step",
  E02_FUNNEL_VIEW = 'funnel_view',
  E05_CALCOLA_PRESTITO_VIEW = 'calcola_prestito_view',
  E12_COMMERCIAL_OFFER = 'commercial-offer',
  E13_SELECT_OFFER = 'select-offer',
  E14_OFFER_SUMMARY = 'offer-summary',
  E15_KYC = 'kyc',
  E16_PURCHASE = 'purchase'
}