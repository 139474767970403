import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { OrchestratorAPI } from '../../api';
import { StepCheckpoints } from '../../constants/step-checkpoints';
import * as CRMService from '../../services/crm.service';
import {
  CreateUpdateHubspotDealRequest,
  CreateUpdateHubspotDealResponse,
  CrmOffer
} from '../../types/exelab-crm/account';
import { Offer } from '../../types/procedure';
import { ThunkExtra } from '../types';
import { YesNo } from '../../enums/yes-no';
import { isExternalComparatorChannel } from '../../utils/channel/channel.utils';
import { OfferActionTypeEnum } from '../../enums/offer-action-type';

type PreSelectOfferCRMActionReturned = {
  selected: Partial<CrmOffer>;
  stepCheckpoint: StepCheckpoints;
  secciId?: string;
};
type PreSelectOfferCRMActionArgs = Offer | Partial<CrmOffer>;

export const preSelectOfferCRMAction = createAsyncThunk<
  PreSelectOfferCRMActionReturned,
  PreSelectOfferCRMActionArgs,
  ThunkExtra
>('preSelectOfferCRMAction', async (selected, { getState, rejectWithValue }) => {
  const { channel, procedureId } = getState();

  if (!procedureId) {
    // TODO: Handle error
    throw new Error('No procedure id');
  }

  const payload: CreateUpdateHubspotDealRequest = {
    selected_by: 'customer',
    procedureId,
    offer: {
      offerId: selected.offerId,
      reference: selected.reference,
      amount: selected.amount,
      installmentsCnt: selected.installmentsCnt,
      installmentsAmount: selected.installmentsAmount,
      tan: selected.tan,
      taeg: selected.taeg,
      teg: selected.teg,
      recommended: selected.recommended,
      totalCreditAmount: selected.totalCreditAmount,
      insurance: YesNo.NO
    }
  };
  console.log('preSelectOfferCRMAction ~ payload', payload);

  let createUpdateHubspotDealResponse: CreateUpdateHubspotDealResponse | undefined;
  try {
    if (!isExternalComparatorChannel(channel!)) {
      createUpdateHubspotDealResponse = await OrchestratorAPI.createProcedureCRM(payload);
    }
    // CRM
    const stepCheckpoint = await CRMService.updateSelectedOfferCheckpoint();

    return { selected: payload.offer, stepCheckpoint, secciId: createUpdateHubspotDealResponse?.secciId };
  } catch (err) {
    const error = err as AxiosError;
    return rejectWithValue(error?.message);
  }
});

interface UpdateHubspotDealArgs {
  offer: Partial<CrmOffer>;
  actionType?: OfferActionTypeEnum;
}

interface UpdateHubspotDealPayload {
  offer: Partial<CrmOffer>;
  secciId: string;
  actionType?: OfferActionTypeEnum
}

export const updateHubspotDealAction = createAsyncThunk<
  UpdateHubspotDealPayload,
  UpdateHubspotDealArgs,
  ThunkExtra
>('update-hubspot-deal', async ({ offer, actionType = OfferActionTypeEnum.UPDATE }, { getState, rejectWithValue }) => {
  const { procedureId, offers } = getState();
  const { preSelectedOffer, selectedOffer } = offers || {};
  const offerId = offer?.offerId ?? selectedOffer?.offerId ?? preSelectedOffer?.offerId ?? undefined;
  if (!procedureId) {
    return rejectWithValue('Ops! Qualcosa è andato storto.');
  }

  const payload: Partial<CreateUpdateHubspotDealRequest> = {
    procedureId,
    offer: { ...offer, offerId },
    selected_by: 'customer'
  };

  try {
    const createUpdateHubspotDealResponse: CreateUpdateHubspotDealResponse =
      await OrchestratorAPI.createProcedureCRM(payload);

    return { offer, secciId: createUpdateHubspotDealResponse.secciId, actionType };
  } catch (err) {
    const error = err as AxiosError;

    return rejectWithValue(error?.message);
  }
});
