import React from 'react';

// Redux
import { useSelector } from 'react-redux';
import { selectPersonalInfo } from '../../store/selectors';

// Theme
import { defaultTheme } from '../../components/ThemeWrapper';

// Components
import PageWrapper from '../../components/PageWrapper';
import Slide from '../../components/Slide/Slide';

// UI Components
import { Container, FlexContainer } from '../../UI/containers/Containers';
import Image from '../../UI/Image/Image';
import { BoldParagraph, Paragraph } from '../../UI/paragraphs/Paragraphs';
import { Card } from '../../UI/Card/Card';

// Constants
import { CONTE_HOME } from '../../constants/links';

// Resources
import {
  ReactComponent as conte_icon_illustration_site_under_maintenance
} from '../../assets/resources/illustrations/conte_icon_illustration_site_under_maintenance.svg';
import Button from '../../UI/Button/Button';


const SiteUnderMaintenance: React.FC = () => {

  /**
   * useSelectors
   */
  const { firstName } = useSelector(selectPersonalInfo);

  /**
   * handlers
   */
  const handleRedirectHome = () => {
    window.location.href = process.env.REACT_APP_LANDING_PAGE_URL ?? CONTE_HOME;
  };

  return (
    <PageWrapper hasNavbarGradient>
      <Slide.Container>
        <Slide.Content noMargin>
          <Card id={'personal-area-declined-by-rules'}>
            <Container marginBottom={40}>
              <Image component={conte_icon_illustration_site_under_maintenance} />
            </Container>
            <Container fontSize={18} lineHeight={32} color={defaultTheme.colors.text}>
              <BoldParagraph color={defaultTheme.colors.dark_blue}>{firstName}{firstName ? ',' : ''}</BoldParagraph>
              <Paragraph>
                {firstName ? 'a' : 'A'}bbiamo riscontrato un piccolo inconveniente sul nostro sito, siamo già al lavoro
                per tornare online il
                prima possibile 💪
                <br />
                <br />
                Ti avviseremo con un’email non appena potrai scoprire la rata che ti abbiamo riservato 📬
              </Paragraph>
            </Container>
          <FlexContainer marginTop={30}>
            <Button onClick={handleRedirectHome}>Torna alla Homepage</Button>
          </FlexContainer>
          </Card>
        </Slide.Content>
      </Slide.Container>
    </PageWrapper>
  );
};

export default SiteUnderMaintenance;