import { RootState } from '../index';

export function selectStartingOfferStatus(state: RootState) {
  return state.startingOffer.generalStatus;
}

export function selectStartingOfferList(state: RootState) {
  return state.startingOffer.offerList;
}

export function selectStartingOfferSelected(state: RootState) {
  return state.startingOffer.selectedOffer;
}

export function selectIsStartingOfferSelectedByUser(state: RootState) {
  return state.startingOffer.isSelectedByUser;
}
