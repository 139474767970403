const DATA_COLLECTOR_URL = process.env.REACT_APP_ACCERTIFY_ENDPOINT as string;

export const accertifyDataCollector = async (): Promise<any | { transactionId: string }> => {
  let windowRef: any = window;
  const start = performance.now();

  // Data Collector script
  const s = document.createElement('script');
  s.setAttribute('type', 'text/javascript');
  s.setAttribute('src', DATA_COLLECTOR_URL);
  s.setAttribute('dvct', '500');
  s.setAttribute('sid', 'test-session');
  s.setAttribute('id', 'bcn');
  s.setAttribute('dvc', 'l');
  s.onload = () => {
    if (windowRef.hasOwnProperty('_bcn') && windowRef._bcn.hasOwnProperty('dvc')) {
      windowRef._bcn.dvc.collect();
    }
  };
  document.head.appendChild(s);

  return new Promise(resolve => {
    const interval = setInterval(() => {
      if (
        windowRef.hasOwnProperty('collector') &&
        windowRef.hasOwnProperty('_bcn') &&
        windowRef._bcn.hasOwnProperty('dvc')
      ) {
        const data = windowRef.collector.getPayload(windowRef._bcn.dvc.getTID());
        const click = performance.now();
        console.log('Time elapsed ' + (click - start) / 1000.0 + ' seconds');
        clearInterval(interval);
        resolve(data);
      }
    }, 500);
  });
};
