import { ComponentType } from 'react';

export const delay = async (promise: Promise<{ default: ComponentType<any> }>, delayMS = 300) => {
  const [fulfilledPromise] = await Promise.all([
    promise,
    new Promise(resolve => setTimeout(resolve, delayMS))
  ]);
  return fulfilledPromise;
};

export const timeout = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}
