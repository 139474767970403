import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { OrchestratorAPI } from '../../api';
import { DC_SLIDES } from '../../constants/data-collection-slides';
import * as CRMService from '../../services/crm.service';
import { RootState } from '../index';

export const setIbanAction = createAsyncThunk('setIbanAction', async (iban: string, thunkAPI) => {
  const fiscalCode: string = (thunkAPI.getState() as RootState).dataCollection.slides[DC_SLIDES.FISCAL_ID.ID]
    .code;

  const ibanAttempt: number = (thunkAPI.getState() as RootState).ibanAttempt;

  try {

    const payload = { fiscalCode, iban };
    const ibanValid = await OrchestratorAPI.ibanCheck(payload);

    // iban is sent anyway, even if it is not valid // TODO check
    await CRMService.setIbanCode(iban);
    if (ibanValid) {
      return { iban, ibanValid, ibanAttempt: ibanAttempt + 1 };
    }

    return { iban, ibanValid, ibanAttempt: ibanAttempt + 1 };
  } catch (err) {
    const error = err as AxiosError;
    return thunkAPI.rejectWithValue(error?.message);
  }
});

export const setIbanValidAction = createAction('setIbanValidAction');
