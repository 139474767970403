import React from 'react';
import styles from './Font.module.scss';

export function Font({ fontSize = 12, lineHeight = 20, className, children, ...rest }: any) {
  return (
    <div
      className={[styles.text, className].join(' ')}
      style={{ fontSize: `${fontSize}px`, lineHeight: `${lineHeight}px` }}
      {...rest}>
      {children}
    </div>
  );
}
