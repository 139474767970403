import { RootState } from '../index';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { disableLoadingAction, enableLoadingAction } from './set-loading.action';

// Axios
import { AxiosError } from 'axios';

// Api
import { OrchestratorAPI } from '../../api';

// Types
import { CrmOffer } from '../../types/exelab-crm/account';
import { Document, Offer } from '../../types/procedure';
import { SecciPreviewWriteDTO } from '../../types/secci';

// Enums
import { NotificationType } from '../../enums/notification-type';
import { DocumentRoles, DocumentRolesLabels } from '../../enums/cbs/document-roles';

// Constants
import { DC_SLIDES } from '../../constants/data-collection-slides';

// Utils
import notificationPopupUtil from '../../utils/notification-popup/notification.popup.util';
import { getDocumentByRole } from '../../api/routes/orchestrator.routes';

type DownloadSecciActionPreviewReturned = void;
type DownloadSecciActionPreviewArgs = Offer;

export const downloadSecciPreviewAction = createAsyncThunk<
  DownloadSecciActionPreviewReturned,
  DownloadSecciActionPreviewArgs
>('downloadSecciPreviewAction', async (offer, thunkAPI) => {
  const personalInfo = (thunkAPI.getState() as RootState).dataCollection.slides[DC_SLIDES.PERSONAL_INFO_1.ID];
  const { firstName, lastName } = personalInfo;

  const datetime = new Date().toISOString();
  const date = datetime.substring(0, datetime.indexOf('T'));

  const windowRef = window.open('about:blank', '_blank') as Window;

  try {
    const payload: SecciPreviewWriteDTO = {
      firstName,
      lastName,
      date,

      amount: offer.amount,
      durationInMonth: offer.term,
      numberOfInstallments: offer.installmentsCnt,
      rateAmount: offer.installmentsAmount,
      accountAmountDebt: offer.totalCreditAmount,
      tan: offer.tan,
      taeg: offer.taeg,
      interessi: offer.interestsTotalSum,
      expense: offer.expensesTotalSum,
      expenseRate: offer.expensesRated,
      initialFeeAmount: String(offer.initialFeeAmount),
      periodicFeeAmount: String(offer.periodicFeeAmount)
    };
    const secciLink = await OrchestratorAPI.generateSecciPreview(payload);
    windowRef.location = secciLink;
    window.setTimeout(() => windowRef.close(), 700);
    return;
  } catch (err) {
    const error = err as AxiosError;
    return thunkAPI.rejectWithValue(error?.message);
  }
});

type DownloadSecciActionReturned = void;
type DownloadSecciActionArgs = CrmOffer | { reference: 'default' };

export const downloadSecciAction = createAsyncThunk<DownloadSecciActionReturned, DownloadSecciActionArgs>(
  'downloadSecciAction',
  async (offer, thunkAPI) => {
    const procedureId = (thunkAPI.getState() as RootState).procedureId;

    if (!procedureId) {
      return thunkAPI.rejectWithValue('Procedure ID not found');
    }

    const windowRef = window.open('about:blank', '_blank') as Window;
    try {
      const secciLink = await OrchestratorAPI.generateSecci({
        offerId: offer ? offer.reference : 'default',
        procedureId
      });
      windowRef.location = secciLink;
      window.setTimeout(() => windowRef.close(), 700);
      return;
    } catch (err) {
      const error = err as AxiosError;
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const downloadDocumentByEntityId = createAsyncThunk<void, Partial<Document>>(
  'downloadDocumentByEntityIdAction',
  async (documentInfo, { getState, rejectWithValue, dispatch }) => {
    const procedureId = (getState() as RootState).procedureId;
    const { entityId, fileName } = documentInfo;

    try {
      const documentBlob = await OrchestratorAPI.getDocument(procedureId || `${documentInfo.fileName}`, entityId!);
      const documentUrl = URL.createObjectURL(documentBlob);

      let link = document.createElement('a');
      link.href = documentUrl;
      link.download = fileName?.concat('.pdf')!;
      link.click();
      notificationPopupUtil.openAndResetPopup(
        dispatch,
        'Hai scaricato il documento richiesto',
        NotificationType.SUCCESS
      );

      // For Firefox, it is necessary to delay revoking the ObjectURL.
      setTimeout(() => window.URL.revokeObjectURL(documentUrl), 250);
      return;
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue({
        hasImage: false,
        title: 'Attenzione!',
        subtitle:
          'Al momento non sembra esserci alcun documento disponibile per il download.\nAssicurarsi che il documento sia stato caricato prima di tentare di scaricarlo.'
      });
    }
  }
);

export const downloadDocumentByRole = createAsyncThunk<void, DocumentRoles>(
  'downloadDocumentByRoleAction',
  async (role, thunkAPI) => {
    const procedureId = (thunkAPI.getState() as RootState).procedureId;

    if (!procedureId) {
      return thunkAPI.rejectWithValue('Procedure ID not found');
    }

    try {
      // Get base64 documents
      const base64Documents = await getDocumentByRole(procedureId, role);

      const latestGeneratedBase64Document = base64Documents[0];

      // Convert base64 to binary
      const binaryDocument = window.atob(latestGeneratedBase64Document);

      // Create a Uint8Array from the binary data
      const uint8Array = new Uint8Array(binaryDocument.length);

      for (let i = 0; i < binaryDocument.length; i++) {
        uint8Array[i] = binaryDocument.charCodeAt(i);
      }

      // Create a Blob from the Uint8Array
      const blobDocument = new Blob([uint8Array], { type: 'application/octet-stream' });

      const documentUrl = URL.createObjectURL(blobDocument);

      let link = document.createElement('a');
      link.href = documentUrl;
      link.download = `${DocumentRolesLabels[role]}.pdf`;
      link.click();
      notificationPopupUtil.openAndResetPopup(
        thunkAPI.dispatch,
        'Hai scaricato il documento richiesto',
        NotificationType.SUCCESS
      );

      // For Firefox, it is necessary to delay revoking the ObjectURL.
      setTimeout(() => window.URL.revokeObjectURL(documentUrl), 250);
      return;
    } catch (err) {
      const error = err as AxiosError;
      return thunkAPI.rejectWithValue({
        hasImage: false,
        title: 'Attenzione!',
        subtitle:
          'Al momento non sembra esserci alcun documento disponibile per il download.\nAssicurarsi che il documento sia stato caricato prima di tentare di scaricarlo.'
      });
    }
  }
);

export const downloadDocumentById = createAsyncThunk<void, string>(
  'downloadRepaymentPlanAction',
  async (documentId, thunkAPI) => {
    const procedureId = (thunkAPI.getState() as RootState).procedureId;

    if (!procedureId) {
      return thunkAPI.rejectWithValue('Procedure ID not found');
    }

    try {
      const procedure = await OrchestratorAPI.getProcedure(procedureId);
      const documentBlob = await OrchestratorAPI.getDocument(procedureId, documentId);
      const documentUrl = URL.createObjectURL(documentBlob);
      const documentName = procedure.documents.find(doc => doc.entityId === documentId)?.role || 'document';

      let link = document.createElement('a');
      link.href = documentUrl;
      link.download = documentName.concat('.pdf');
      link.click();

      // For Firefox it is necessary to delay revoking the ObjectURL.
      setTimeout(() => window.URL.revokeObjectURL(documentUrl), 250);
      return;
    } catch (err) {
      const error = err as AxiosError;
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const downloadRepaymentPlanAction = createAsyncThunk<void, void>(
  'downloadRepaymentPlanAction',
  async (_, thunkAPI) => {
    const dispatch = thunkAPI.dispatch;
    const procedureId = (thunkAPI.getState() as RootState).procedureId;

    if (!procedureId) {
      return thunkAPI.rejectWithValue('Procedure ID not found');
    }

    const windowRef = window.open('about:blank', '_blank') as Window;

    try {
      dispatch(enableLoadingAction(''));
      const pdaLink = await OrchestratorAPI.getPDA(procedureId);
      dispatch(disableLoadingAction());
      windowRef.location = pdaLink;
      window.setTimeout(() => {
        notificationPopupUtil.openAndResetPopup(
          dispatch,
          'Hai scaricato il documento richiesto',
          NotificationType.SUCCESS
        );
        windowRef.close();
      }, 700);
    } catch (err) {
      const error = err as AxiosError;
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const downloadContractAction = createAsyncThunk<void, void>(
  'downloadContractAction',
  async (_, thunkAPI) => {
    const procedureId = (thunkAPI.getState() as RootState).procedureId;

    if (!procedureId) {
      return thunkAPI.rejectWithValue('Procedure ID not found');
    }

    try {
      const procedure = await OrchestratorAPI.getProcedure(procedureId);

      const contractDocument = procedure.documents.find(doc => doc.role === 'Contratto Firmato');
      if (!contractDocument) {
        throw new Error('Contract document not found');
      }

      const documentBlob = await OrchestratorAPI.getDocument(procedureId, contractDocument?.entityId);
      const documentUrl = URL.createObjectURL(documentBlob);
      const documentName = contractDocument.role || 'document';

      let link = document.createElement('a');
      link.href = documentUrl;
      link.download = documentName.concat('.pdf');
      link.click();

      // For Firefox it is necessary to delay revoking the ObjectURL.
      setTimeout(() => window.URL.revokeObjectURL(documentUrl), 250);
      return;
    } catch (err) {
      const error = err as AxiosError;
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);
