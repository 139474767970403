import React from 'react';

// React Router
import { useNavigate } from 'react-router';

// Redux
import { useSelector } from 'react-redux';
import { selectPersonalInfo } from '../../store/selectors';

// Custom Hooks
import { useBackListener } from '../data-collection-pol/custom-hooks/useBackListenerDataCollectionPol';

// Theme
import { defaultTheme } from '../../components/ThemeWrapper';

// Component
import Slide from '../../components/Slide/Slide';
import PageWrapper from '../../components/PageWrapper';

// UI Components
import Image from '../../UI/Image/Image';
import { Container } from '../../UI/containers/Containers';
import { BoldParagraph, Paragraph } from '../../UI/paragraphs/Paragraphs';

// Resources
import { ReactComponent as conte_icon_illustration_declined_by_aml_uw } from '../../assets/resources/illustrations/expired-ko/conte_icon_illustration_cancelled_declined_by_aml_uw.svg';

const DeclinedByAmlUw: React.FC = () => {
  const navigate = useNavigate();

  /**
   * useSelectors
   */
  const { firstName } = useSelector(selectPersonalInfo);

  /**
   * Custom Hooks
   */
  // Prevent navigation back
  useBackListener(() => {
    navigate(1);
  });

  return (
    <PageWrapper hasNavbarGradient>
      <Slide.Container>
        <Slide.Content noMargin>
          <Container marginBottom={40}>
            <Image component={conte_icon_illustration_declined_by_aml_uw} />
          </Container>
          <Container paddingHorizontal={20} fontSize={18} lineHeight={32} color={defaultTheme.colors.text}>
            <BoldParagraph color={defaultTheme.colors.dark_blue}>{firstName},</BoldParagraph>
            <Paragraph>
              a seguito dei controlli effettuati purtroppo non possiamo dare seguito alla tua richiesta di
              prestito personale.
            </Paragraph>
            <br />
            <Paragraph>
              Ti abbiamo inviato via email una comunicazione che ha valore di liberatoria per richiesta di
              finanziamento non concesso nei confronti di altre banche o istituti finanziari.
            </Paragraph>
            <br />
            <Paragraph>
              Ti ringraziamo per averci scelto e speriamo in futuro di poter soddisfare le tue esigenze.
            </Paragraph>
          </Container>
        </Slide.Content>
      </Slide.Container>
    </PageWrapper>
  );
};

export default DeclinedByAmlUw;