import { DC_SLIDES } from '../../constants/data-collection-slides';
import { RootState } from '../index';

export const selectCompanyIndustry = (state: RootState) => {
  return state.dataCollection.slides[DC_SLIDES.COMPANY_INDUSTRY.ID];
};

export const selectVatNumber = (state: RootState) => {
  return state.dataCollection.slides[DC_SLIDES.VAT_NUMBER.ID];
};

export const selectCompanySize = (state: RootState) => {
  return state.dataCollection.slides[DC_SLIDES.COMPANY_SIZE.ID];
};

export const selectCompanyHiring = (state: RootState) => {
  const { hiredOnYear, employerName } = state.dataCollection.slides[DC_SLIDES.HIRED_ON_YEAR.ID] || {};
  const { hiredOnMonth } = state.dataCollection.slides[DC_SLIDES.HIRED_ON_MONTH.ID] || {};

  return {
    hiredOnYear,
    hiredOnMonth,
    employerName
  };
};
