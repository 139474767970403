import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { OrchestratorAPI } from '../../api';
import { StepCheckpoints } from '../../constants/step-checkpoints';
import * as CRMService from '../../services/crm.service';
import { CrmOffer } from '../../types/exelab-crm/account';
import { ThunkExtra } from '../types';
import { Offer } from '../../types/procedure';
import { createProcedureCRM } from '../../api/routes/orchestrator.routes';
import { YesNo } from '../../enums/yes-no';
import { isEmpty, omit } from 'lodash';
import { CoherenceQuestionnaire } from '../../types/exelab-crm/cpi';

interface SelectOfferArgs {
  partialDealOffer?: Partial<CrmOffer>;
  stepCheckpoint?: StepCheckpoints;
  dataLayerCallback?: any;
}

type SelectedOfferReturned = {
  preSelectedOffer: Partial<CrmOffer> | Offer;
  stepCheckpoint: StepCheckpoints;
};

export const selectOfferAction = createAsyncThunk<SelectedOfferReturned, SelectOfferArgs, ThunkExtra>(
  'selectOfferAction',
  async (selectOfferArgs, { getState, rejectWithValue }) => {
    const {
      applicationId,
      procedureId,
      offers: { preSelectedOffer }
    } = getState();

    const { partialDealOffer, dataLayerCallback } = selectOfferArgs || {};

    if (!preSelectedOffer) {
      // TODO - handle error
      throw new Error('No preselected offer');
    }

    if (!procedureId || !applicationId) {
      // TODO - handle error
      throw new Error('No procedureId');
    }

    const coherenceQuestionnaire: Partial<CoherenceQuestionnaire> = omit(
      partialDealOffer?.cpi?.coherence_questionnaire,
      ['visualization_date', 'submission_date']
    );

    try {
      const selectedOfferResponse = await OrchestratorAPI.selectOffer(procedureId, {
        coherenceQuestionnaire: !isEmpty(coherenceQuestionnaire)? coherenceQuestionnaire : undefined,
        applicationId,
        offerId: preSelectedOffer?.offerId!,
        reference: preSelectedOffer?.reference!
      });

      await createProcedureCRM({
        procedureId,
        offer: {
          ...preSelectedOffer,
          ...partialDealOffer,
          offerId: selectedOfferResponse.offerId ?? preSelectedOffer.offerId,
          insurance: partialDealOffer ? YesNo.YES : YesNo.NO,
        },
        selected_by: 'customer'
      });

      await CRMService.updateConsents({ contractualDocsAcknowledgementDate: new Date() });

      // CRM
      const stepCheckpoint = selectOfferArgs?.stepCheckpoint
        ? await CRMService.updateCheckpoint(StepCheckpoints.NEGOTIATION_COMPLETED)
        : await CRMService.updateIdentityToBeVerifiedCheckpoint();

      if (dataLayerCallback) {
        dataLayerCallback(undefined);
      }

      return { preSelectedOffer, stepCheckpoint };
    } catch (err) {
      const error = err as AxiosError;
      if (dataLayerCallback) {
        dataLayerCallback(undefined, error?.message);
      }
      return rejectWithValue(error?.message);
    }
  }
);
