import { AdvData, LoanData } from '../../../types/procedure';

type ScopoRapportoTypes = Array<{
  value: AdvData['scopo_rapporto'];
  projectValue: LoanData['purpose'];
  label: string;
}>;
export const SCOPO_RAPPORTO: ScopoRapportoTypes = [
  { value: 1, projectValue: 'DEBT_CONSOLIDATION', label: 'Consolidamento debito' },
  { value: 2, projectValue: 'OTHER', label: 'Altri progetti' },
  { value: 3, projectValue: 'MEDICAL_EXPENSES', label: 'Spese mediche' },
  { value: 4, projectValue: 'STUDYING_TRAINING_COURSES', label: 'Studio/ Corsi di formazione' },
  { value: 5, projectValue: 'WEDDING', label: 'Matrimonio' },
  { value: 6, projectValue: 'TRAVELS', label: 'Viaggi' },
  { value: 7, projectValue: 'HOME_IMPROVEMENT', label: 'Ristrutturazione casa' },
  { value: 8, projectValue: 'FURNITURE', label: 'Arredo' },
  { value: 9, projectValue: 'REAL_ESTATE_BOX_PURCHASE', label: 'Acquisto Immobili/ Box' },
  { value: 10, projectValue: 'NEW_USED_MOTORBIKE', label: 'Moto nuova/ usata' },
  { value: 11, projectValue: 'NEW_CAR', label: 'Auto nuova' },
  { value: 12, projectValue: 'USED_CAR', label: 'Auto usata' },
  { value: 13, projectValue: 'ESTHETICS_WELLBEING', label: 'Estetica e benessere' }
];
