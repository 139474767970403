import React, { useCallback } from 'react';

import { slide as Menu } from 'react-burger-menu';

// Redux
import { useDispatch, useSelector } from 'react-redux';

// Actions
import { closeSidebarAction, openSidebarAction } from '../../store/actions/set-sidebar.action';
import { selectChannel } from '../../store/selectors/select-channel';

// UI Components
import Button from '../../UI/Button/Button';
import Divider from '../../UI/Divider/Divider';
import Image from '../../UI/Image/Image';
import { numberWithCommas } from '../../utils/numberWithCommas';
import { FlexContainer } from '../../UI/containers/Containers';

// Constants
import { LOAN_PROJECTS } from '../../constants/loanProjects';

// Enums
import { AppRoutes, LeadsRoutes } from '../../enums/app-routes';
import { ExternalComparatorsChannels } from '../../enums/channels';

// Utils
import { isExternalComparatorChannel } from '../../utils/channel/channel.utils';

// Resources
import { ReactComponent as conte_icon_logo_color } from '../../assets/resources/logo/conte_icon_logo_color.svg';
import { ReactComponent as conte_icon_logo_white } from '../../assets/resources/logo/conte_icon_logo_white.svg';
import { ReactComponent as prestiti_online_logo } from '../../assets/resources/logo/prestiti_online_logo.svg';
import { ReactComponent as prestiti_online_icon_logo_white } from '../../assets/resources/logo/prestiti_online_logo_white.svg';
import { ReactComponent as CofidisLogo } from '../../assets/resources/logo/cofidis_logo.svg';
import { ReactComponent as CofidisLogoLight } from '../../assets/resources/logo/cofidis_logo_white.svg';
import { ReactComponent as dividerImg } from '../../assets/resources/icons/conte_icon_logo_chain.svg';
import { ReactComponent as MutuiamoLogo } from '../../assets/resources/logo/mutuiamo-logo.svg';

import styles from './Sidebar.module.scss';

const Sidebar = () => {
  const dispatch = useDispatch();

  /**
   * useSelectors
   */
  const isOpen = useSelector(state => state?.sidebar.isOpen);
  const loanIntent = useSelector(state => state?.loanIntent);
  const channel = useSelector(selectChannel);

  /**
   * useCallbacks and handlers
   */
  const onClose = () => dispatch(closeSidebarAction());
  const openLink = useCallback(link => window.open(link, '_blank'), []);

  return (
    <Menu
      disableAutoFocus
      isOpen={isOpen}
      // Small devices (768px and down)
      right={window.matchMedia('(max-width: 767.98px)').matches}
      // Medium devices (768px and up)
      left={window.matchMedia('(min-width: 768px)').matches}
      width="320px"
      customBurgerIcon={false}
      customCrossIcon={
        <div>
          <i className="icon-conte_icon_close_menu" />
        </div>
      }
      onClose={onClose}>
      <div className={styles.sidebarContainer}>
        {/* Logo */}
        <Image component={conte_icon_logo_color} alt="logo" className={styles.logoImage} />
        {channel && isExternalComparatorChannel(channel) && (
          <>
            <Image className={styles.logoDivider} component={dividerImg} />
            <Image component={prestiti_online_logo} alt="Logo di PrestitiOnline.it" />
          </>
        )}
        {channel &&
          (window.location.href.includes(`/${AppRoutes.LEADS}/${LeadsRoutes.LOAN_INTENT}`) ||
            window.location.href.includes(`/${AppRoutes.LEADS}/${LeadsRoutes.PRIVACY}`)) && (
            <>
              <Image className={styles.logoDivider} component={dividerImg} />
              {channel === ExternalComparatorsChannels.COFIDIS && <Image component={CofidisLogo} />}
              {channel === ExternalComparatorsChannels.MUTUIAMO && <MutuiamoLogo />}
            </>
          )}
        {loanIntent && Object.entries(loanIntent).length !== 0 && (
          <React.Fragment>
            {/* Title */}
            <div className={styles.titleContainer}>
              <span className={styles.title}>La tua richiesta</span>
              <span className={styles.lineDivider} />
            </div>

            {/* Request Rows */}
            <div className={styles.requestsContainer}>
              {loanIntent.purpose && (
                <div className={styles.requestRow}>
                  <span>Progetto</span>
                  <span>{LOAN_PROJECTS.find(lp => lp.value === loanIntent.purpose)?.label}</span>
                </div>
              )}

              <div className={styles.requestRow}>
                <span>Importo richiesto</span>
                <span>{numberWithCommas(loanIntent.amount)},00 €</span>
              </div>

              <div className={styles.requestRow}>
                <span>Durata</span>
                <span>{loanIntent.term} mesi</span>
              </div>
            </div>
          </React.Fragment>
        )}

        {/* Title 2 */}
        <div className={styles.titleContainer}>
          <span className={styles.title}>Che documenti mi servono?</span>
          <span className={styles.lineDivider} />
        </div>

        {/* Requested Document Rows */}
        <div className={styles.requestsContainer}>
          <div className={styles.requestsDocRow}>
            <i className="icon-conte_icon_panel_documento_identita" />
            <div className={styles.requestsDocText}>
              <div>Documento di riconoscimento</div>
              <div>Carta d'identità, patente, passaporto o SPID</div>
            </div>
          </div>

          <div className={styles.requestsDocRow}>
            <i className="icon-conte_icon_panel_codice_fiscale" />
            <div className={styles.requestsDocText}>
              <div>Tessera sanitaria</div>
              <div>Codice fiscale/Tessera sanitaria</div>
            </div>
          </div>

          <div className={styles.requestsDocRow}>
            <i className="icon-conte_icon_panel_smartphone" />
            <div className={styles.requestsDocText}>
              <div>Il tuo cellulare</div>
              <div>Ti servirà per firmare il contratto</div>
            </div>
          </div>

          <div className={styles.requestsDocRow}>
            <i className="icon-conte_icon_panel_documento_reddituale" />
            <div className={styles.requestsDocText}>
              <div>Documento reddituale</div>
              <div>
                Ultime 2 buste paga per lavoratori dipendenti, ultimi 2 cedolini della pensione o Modello
                Unico per i lavoratori autonomi &amp; ultimo estratto conto trimestrale o Open Banking
              </div>
            </div>
          </div>

          <div className={styles.requestsDocRow}>
            <i className="icon-conte_icon_panel_documento_iban" />
            <div className={styles.requestsDocText}>
              <div>IBAN del tuo conto corrente</div>
              <div>Per accreditare il tuo prestito</div>
            </div>
          </div>
        </div>

        <div className={styles.footerScrollSpace} />

        <div className={styles.footer}>
          <Divider />
          <Button
            onClick={() => openLink('https://www.conteprestiti.it/knowledge')}
            customClasses={[styles.helpButton]}
            icon="icon-conte_icon_header_help"
            color="primary"
            variant="outline"
            fluid>
            HAI BISOGNO DI AIUTO?
          </Button>
        </div>
      </div>
    </Menu>
  );
};

export const SidebarTile = () => {
  const dispatch = useDispatch();

  /**
   * useSelectors
   */
  const channel = useSelector(state => state?.channel);

  /**
   * useCallbacks
   */
  const showSidebarAction = useCallback(() => dispatch(openSidebarAction()), [dispatch]);

  return (
    <div className={styles.sidebarDesktopBtn}>
      <div style={{ position: 'fixed', top: 0 }}>
        <Image component={conte_icon_logo_white} className={styles.logoImageCollapsed} alt="logo" />
        {channel && isExternalComparatorChannel(channel) && (
          <>
            <br />
            <Image
              component={prestiti_online_icon_logo_white}
              className={styles.logoImageCollapsed}
              alt="Logo di PrestitiOnline.it"
            />
          </>
        )}
        {channel &&
          (window.location.href.includes(`/${AppRoutes.LEADS}/${LeadsRoutes.LOAN_INTENT}`) ||
            window.location.href.includes(`/${AppRoutes.LEADS}/${LeadsRoutes.PRIVACY}`)) && (
            <>
              <br />
              {channel === ExternalComparatorsChannels.COFIDIS && (
                <Image
                  component={CofidisLogoLight}
                  className={styles.logoImageCollapsed}
                  alt="Logo di Cofidis prestiti"
                />
              )}
              {channel === ExternalComparatorsChannels.MUTUIAMO && (
                <FlexContainer marginTop={10}>
                  <MutuiamoLogo width={60} />
                </FlexContainer>
              )}
            </>
          )}
      </div>
      <FlexContainer direction={'columns'} fullHeight>
        <button className={styles.sideButton} onClick={showSidebarAction}>
          <i className="icon-conte_icon_header_left_burger_icon" />
        </button>
      </FlexContainer>
    </div>
  );
};

export default Sidebar;
