// Redux
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkExtra } from '../types';
import { disableLoadingAction, enableLoadingAction } from './set-loading.action';

// Axios
import { AxiosError } from 'axios';

// Api
import { OrchestratorAPI } from '../../api';

// Types
import { CrmLoanIntent } from '../../types/exelab-crm/account';
import { OfferWriteDTO } from '../../types/offer';
import { LoanResponse, Offer } from '../../types/procedure';
import { ObChecksWriteDTO } from '../../types/open-banking';

// Constants
import { StepCheckpoints } from '../../constants/step-checkpoints';

// Enums
import { Status } from '../../enums/offer/status';

// Services
import * as CRMService from '../../services/crm.service';

// Utils
import { accertifyDataCollector } from '../../utils/accertify-data-collector';
import { generateAccount, generateLoanData, generateOfferRequest } from '../../utils/orchestrator-transform';

export type GenerateStartingOfferReturned = {
  applicationId: string;
  loanResponse: LoanResponse;
  stepCheckpoint?: StepCheckpoints;
  openBankingToken?: string;
  openBankingConsent: boolean;
  devicePayload: any;
};

export const generateStartingOfferAction = createAsyncThunk<GenerateStartingOfferReturned, void, ThunkExtra>(
  'generateStartingOfferAction',
  async (_, { getState, rejectWithValue, dispatch }) => {
    const state = getState();
    const { loanIntent, applicationId, kyc_data } = state;
    const { openBankingCode, openBankingEnabled } = state.openBanking;
    const fiscalCode = state.dataCollection.slides?.fiscal_id?.code;
    const slides = state.dataCollection.slides;
    const crmStepCheckpoint = state.stepCheckpoint;

    if (StepCheckpoints.STARTING_OFFER_DISPLAYED === crmStepCheckpoint) {
      dispatch(enableLoadingAction(''));
    }

    try {
      // Open Banking logic
      const openBankingConsent = (!!openBankingCode || openBankingEnabled) as boolean;

      let openBankingApplicationId;

      if (!applicationId && openBankingConsent) {
        const obChecksWriteDTO: ObChecksWriteDTO = {
          applicationId,
          fiscalCode,
          incomeCheckId: openBankingCode ?? ''
        };
        const openBankingData = await OrchestratorAPI.obChecks(obChecksWriteDTO);
        openBankingApplicationId = openBankingData.applicationId;
      }

      // starting offer generation

      let deviceFullPayload = kyc_data.devicePayload;
      console.log('> ~ deviceFullPayload', deviceFullPayload);
      if (!deviceFullPayload) {
        deviceFullPayload = await accertifyDataCollector();
        localStorage.setItem('deviceTransactionId', JSON.stringify(deviceFullPayload));
      }

      const account = generateAccount(slides);
      const loanData = generateLoanData(loanIntent, slides);
      // applicationId can be null (if first time calling starting offer)
      const offerRequest = generateOfferRequest(
        openBankingApplicationId ?? applicationId,
        account,
        deviceFullPayload,
        loanData
      );

      const payload: OfferWriteDTO = {
        ...offerRequest,
        loanData: {
          ...offerRequest.loanData,
          consents: {
            openBankingConsent,
            incomeCheckId: openBankingCode,
            bureausConsent: false // "bureausConsent" always false in first commercial offer request
          }
        }
      };

      let startingOfferPayload;

      /*try {*/
      if (StepCheckpoints.STARTING_OFFER_DISPLAYED === crmStepCheckpoint) {
        dispatch(enableLoadingAction('Stiamo caricando la tua offerta, non chiudere questa pagina 😎'));
      }
      startingOfferPayload = await OrchestratorAPI.generateStartingOffer(payload);

      if (startingOfferPayload.status !== 'COMPLETE') {
        return rejectWithValue(`Unhandled case ${startingOfferPayload.status}`);
      }

      const applicationIdResult = startingOfferPayload.applicationId;
      const loanResponse = startingOfferPayload.loanResponse[0];
      const generalStatus = loanResponse.generalStatus;

      let stepCheckpoint;

      if (generalStatus === 'KO') {
        // CRM
        if (!applicationId) {
          stepCheckpoint = await CRMService.updateOfferDeclinedByRulesCheckpoint();
        }
      } else {
        // CRM
        if (StepCheckpoints.STARTING_OFFER_DISPLAYED !== crmStepCheckpoint) {
          stepCheckpoint = await CRMService.updateCheckpoint(StepCheckpoints.STARTING_OFFER_TO_BE_DISPLAYED);
        }

        if (!applicationId) {
          await CRMService.updateDevicePayload({
            applicationId: applicationIdResult,
            devicePayload: deviceFullPayload
          });
        }
      }

      return {
        applicationId: applicationIdResult,
        loanResponse,
        stepCheckpoint,
        openBankingCode,
        openBankingConsent,
        devicePayload: deviceFullPayload
      };
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error?.message);
    }
  }
);

export const setStartingOfferSelectedAction = createAction<{ selectedOffer: Offer }>(
  'setStartingOfferSelectedAction'
);

// New starting offer on negotiation edit

type CalculateNewStartingOfferArgs = Omit<CrmLoanIntent, 'purpose'>;

interface CalculateNewStartingOfferPayload {
  devicePayload: any;
  loanResponse: LoanResponse;
}

export const calculateNewStartingOfferAction = createAsyncThunk<
  CalculateNewStartingOfferPayload,
  CalculateNewStartingOfferArgs,
  ThunkExtra
>('calculateNewStartingOfferAction', async (newLoanIntent, { getState, rejectWithValue, dispatch }) => {
  const state = getState();

  const { loanIntent, applicationId, kyc_data } = state;
  const slides = state.dataCollection.slides;

  dispatch(enableLoadingAction(''));

  try {
    const openBankingToken =
      localStorage.getItem('openBankingCode') || state.openBanking.openBankingCode || undefined;
    const openBankingEnabled = state.openBanking.openBankingEnabled;
    const openBankingConsent = (!!openBankingToken || openBankingEnabled) as boolean;

    let deviceFullPayload = kyc_data.devicePayload;
    if (!deviceFullPayload) {
      deviceFullPayload = await accertifyDataCollector();
      localStorage.setItem('deviceTransactionId', JSON.stringify(deviceFullPayload));
    }

    const account = generateAccount(slides);
    const loanData = generateLoanData({ ...newLoanIntent, purpose: loanIntent?.purpose }, slides);
    const offerRequest = generateOfferRequest(applicationId, account, deviceFullPayload, loanData);

    const generateStartingOfferRequest: OfferWriteDTO = {
      ...offerRequest,
      loanData: {
        ...offerRequest.loanData,
        consents: {
          openBankingConsent,
          incomeCheckId: openBankingToken,
          bureausConsent: false
        }
      }
    };

    dispatch(enableLoadingAction('Stiamo caricando la tua offerta, non chiudere questa pagina 😎'));

    const startingOfferResponse = await OrchestratorAPI.generateStartingOffer(generateStartingOfferRequest);

    dispatch(disableLoadingAction());

    if (startingOfferResponse.status !== Status.COMPLETE) {
      return rejectWithValue(`Unhandled case ${startingOfferResponse.status}`);
    }

    return {
      loanResponse: startingOfferResponse.loanResponse[0],
      devicePayload: deviceFullPayload
    };
  } catch (err) {
    const error = err as AxiosError;
    return rejectWithValue(error?.message);
  }
});
