import { OrchestratorAPI } from '../../api';

// Redux
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkExtra } from '../types';

// Services
import * as CRMService from '../../services/crm.service';

// Axios
import { AxiosError } from 'axios';


type EnableOpenBankingArgs = { openBankingCode: string; parsedState: any };

export const enableOpenBanking = createAsyncThunk<void, EnableOpenBankingArgs, ThunkExtra>(
  'enableOpenBanking',
  async ({ openBankingCode, parsedState }, { rejectWithValue }) => {

    const { fiscalCode, applicationId } = parsedState;

    try {

      if (applicationId) {

        // After Starting Offer
        await OrchestratorAPI.obChecks({
          applicationId,
          fiscalCode,
          incomeCheckId: openBankingCode
        });

        await CRMService.enableOpenBanking();

      } else {

        // Before Starting Offer

        await CRMService.setOpenBankingCode(openBankingCode);

      }

    } catch (error) {

      return rejectWithValue((error as AxiosError)?.message);
    }
  }
);
