import React from 'react';

// UI Components
import Image from '../../UI/Image/Image';
import Title from '../../UI/Title/Title';
import { Font } from '../../UI/Font/Font';

// Components
import Slide from '../Slide/Slide';

// Resources
import {
  ReactComponent as conte_icon_illustration_not_found
} from '../../assets/resources/illustrations/conte_icon_illustration_not_found.svg';

const NotFound: React.FC = () => {

  /**
   * useCallbacks
   */

  const goToLanding = React.useCallback(() => {
    window.location.href = process.env.REACT_APP_LANDING_PAGE_URL!;
  }, []);

  return (
    <Slide.Container>
      <Image center component={conte_icon_illustration_not_found} width='320px' height='218px' />
      <Slide.Content>
        <Title fontSize={17}>
          <strong>Ooops! Pagina non trovata</strong>
        </Title>
        <Font fontSize={16} lineHeight={24}>
          Ci dispiace molto ma la pagina che stai cercando non esiste.
        </Font>
      </Slide.Content>
      <Slide.Actions>
        <Slide.FixedButton text='Torna alla Home' onClick={goToLanding} />
      </Slide.Actions>
    </Slide.Container>
  );
};

export default NotFound;