export enum GeneralStatus {
  OK = 'OK',
  REFFERED = 'REFERRED',
  KO = 'KO'
}

export enum Status {
  COMPLETE = 'COMPLETE',
  OB_REQUIRED = 'OB_REQUIRED',
  TECHNICAL_ERROR = 'TECHNICAL_ERROR'
}