import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { OrchestratorAPI } from '../../api';
import * as CRMService from '../../services/crm.service';
import { CrmKYC } from '../../types/exelab-crm/account';
import { FileMapping, KycCheckWriteDTO } from '../../types/kyc';
import { generateAccount, generateLoanData, generateOfferRequest } from '../../utils/orchestrator-transform';
import { RootState } from '../index';
import { ThunkExtra } from '../types';
import { applyPersonalAreaStatusAction } from './personal-area.action';
import { PERSONAL_AREA_ROUTES } from '../../constants/personal-area-routes';

export type TempDocuments = {
  fileName: string;
  role: string;
  fileExtension: string;
  contentType: string;
  uploadUrl: string;
  raw: string;
};

const signAndUploadDocuments = async (documents: Array<TempDocuments>): Promise<Array<FileMapping>> => {
  documents = documents
    .filter(doc => doc.raw)
    .map(doc => {
      doc.raw = doc.raw.split(',')[1]; // "base64,data" => [base64.., data] (take 'data');
      return doc;
    });
  const signedUrls = await OrchestratorAPI.generateSignedURLS(documents.length).then(Object.entries);

  const documentsWithUrls = documents.map((doc, idx) => {
    const [filename, uploadUrl] = Object.values(signedUrls[idx]);
    doc.fileName = filename;
    doc.uploadUrl = uploadUrl;
    return doc;
  });

  const docUploadPromises = documentsWithUrls.map(async doc => {
    return new Promise((resolve, reject) => {
      axios
        .put(doc.uploadUrl, doc.raw, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/octet-stream'
          }
        })
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  });

  // upload all documents
  await Promise.all(docUploadPromises);

  return documentsWithUrls.map(doc => {
    return {
      entityId: doc.fileName, // TODO this should be doc.hash - CTLFE-201
      role: doc.role,
      fileName: doc.role, // TODO refactor this filename is the name of the file, not the hash - CTLFE-201
      fileExtension: doc.fileExtension,
      uuid: doc.fileName
    };
  });
};

export const sendKycDocumentsAction = createAsyncThunk(
  'sendKycDocumentsAction',
  async (
    {
      documentsUpdated: documents,
      documentType
    }: { documentsUpdated: Array<TempDocuments>; documentType: CrmKYC['kyc_document_type'] },
    thunkAPI
  ) => {
    const { loanIntent, applicationId, procedureId, iban, kyc_data } = thunkAPI.getState() as RootState;
    const slides = (thunkAPI.getState() as RootState).dataCollection.slides;
    const openBankingConsent = (thunkAPI.getState() as RootState).openBanking.openBankingEnabled;

    let deviceFullPayload = kyc_data.devicePayload;
    if (!deviceFullPayload) {
      const deviceTransactionId = localStorage.getItem('deviceTransactionId');
      deviceFullPayload = deviceTransactionId ? JSON.parse(deviceTransactionId) : '';
    }

    if (!procedureId) {
      // TODO handle error
      throw new Error('Procedure ID is not defined');
    }

    try {
      const uploadedDocuments = await signAndUploadDocuments(documents);

      const account = generateAccount(slides);
      const loanData = generateLoanData(loanIntent, slides);
      const offerRequest = generateOfferRequest(applicationId, account, deviceFullPayload, loanData);

      const payload: KycCheckWriteDTO = {
        ...offerRequest,
        fileMapping: uploadedDocuments,
        iban: iban!,
        loanData: {
          ...offerRequest.loanData,
          consents: {
            openBankingConsent,
            bureausConsent: true // "bureausConsent" always true after starting offer, user already accepted SICs
          }
        }
      };

      await OrchestratorAPI.kycChecks(procedureId, payload);
      await CRMService.updateKycSuccess({
        kyc_mode: 'videoselfie',
        kyc_document_type: documentType
      });

      return { documentType };
    } catch (err) {
      const error = err as AxiosError;
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const setRequireDocumentsAction = createAction('setRequireDocumentsAction', documentationInfo => ({ payload: { documentationInfo } }));

interface SendKycIncomeDocumentationPayload {
  incomeDocumentationType: any;
}

export const sendKycIncomeDocumentation = createAsyncThunk<SendKycIncomeDocumentationPayload, any, ThunkExtra>(
  'sendKycIncomeDocumentation',
  async (incomeDocumentationType, { rejectWithValue }) => {

    try {

      await CRMService.updateKycIncomeDocumentation({
        kyc_income_documentation_type: incomeDocumentationType
      });

      return {
        incomeDocumentationType
      };

    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error?.message);
    }
  }
);

export const failKycVideoSelfieAction = createAsyncThunk('failKycVideoSelfieAction', async (_, thunkAPI) => {
  try {
    return await CRMService.updateKycFailed();
  } catch (err) {
    const error = err as AxiosError;
    return thunkAPI.rejectWithValue(error?.message);
  }
});

interface IncomeDocumentationArgs {
  documents: Array<TempDocuments>,
  dataLayerCallback: any;
}

interface SendPaychecksPayload {
  paychecks:  FileMapping[]
}

export const sendPaychecksAction = createAsyncThunk<SendPaychecksPayload, IncomeDocumentationArgs, ThunkExtra>(
  'sendPaychecksAction',
  async ({ documents, dataLayerCallback }, { getState, rejectWithValue }) => {
    const procedureId = (getState() as RootState).procedureId;

    try {
      const uploadedDocuments = await signAndUploadDocuments(documents);

      // @ts-ignore
      const payload: Array<FileMapping> = uploadedDocuments.map(doc => ({ ...doc, procedureId }));

      await OrchestratorAPI.uploadDocumentMeta(payload);

      await CRMService.paychecksUploadedCheckpoint();

      if (dataLayerCallback) {
        dataLayerCallback(undefined);
      }

      return { paychecks: uploadedDocuments };
    } catch (err) {
      const error = err as AxiosError;
      if (dataLayerCallback) {
        dataLayerCallback(undefined, error?.message);
      }
      return rejectWithValue(error?.message);
    }
  }
);

interface SendBankStatementPayload {
  bankStatement:  FileMapping[]
}

export const sendBankStatementAction = createAsyncThunk<SendBankStatementPayload, IncomeDocumentationArgs, ThunkExtra>(
  'sendBankStatementAction',
  async ({ documents, dataLayerCallback }, { getState, rejectWithValue }) => {
    const procedureId = (getState() as RootState).procedureId;

    try {
      const uploadedDocuments = await signAndUploadDocuments(documents);

      // @ts-ignore
      const payload: Array<FileMapping> = uploadedDocuments.map(doc => ({ ...doc, procedureId }));

      await OrchestratorAPI.uploadDocumentMeta(payload);

      await CRMService.documentsUploadedCheckpoint();

      if (dataLayerCallback) {
        dataLayerCallback(undefined);
      }

      return { bankStatement: uploadedDocuments };
    } catch (err) {
      const error = err as AxiosError;
      if (dataLayerCallback) {
        dataLayerCallback(undefined, error?.message);
      }
      return rejectWithValue(error?.message);
    }
  }
);

interface SendRequiredDocumentsInput {
  documents: Array<TempDocuments>;
  navigateTo?: PERSONAL_AREA_ROUTES;
}

export const sendRequiredDocumentsAction = createAsyncThunk<void, SendRequiredDocumentsInput, ThunkExtra>(
  'sendRequiredDocuments',
  async (sendRequiredDocumentsInput, { getState, rejectWithValue, dispatch }) => {

    const documents = sendRequiredDocumentsInput.documents;
    const path = sendRequiredDocumentsInput.navigateTo;
    const procedureId = getState().procedureId;

    if (!procedureId) {
      // TODO - handle this case
      throw new Error('No procedure id');
    }

    try {
      const uploadedDocuments = await signAndUploadDocuments(documents);

      // @ts-ignore
      const payload: Array<FileMapping> = uploadedDocuments.map(doc => ({ ...doc, procedureId }));

      await OrchestratorAPI.uploadDocumentMeta(payload);
      dispatch(applyPersonalAreaStatusAction({ navigateTo: path }));
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error?.message);
    }
  }
);
