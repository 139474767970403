import React from 'react';

import styles from './Button.module.scss';

const Button = ({
  id,
  name,
  label,
  customClasses = [],
  color,
  variant,
  fluid,
  disabled,
  icon,
  iconPosition,
  onClick,
  children,
  ...rest
}: any) => {
  let classes = [styles.button];

  //#region color
  if (!color || color === 'primary') {
    classes.push(styles.primary);
  }

  if (color === 'secondary') {
    classes.push(styles.secondary);
  }

  if (color === 'success') {
    classes.push(styles.success);
  }

  if (color === 'success-border') {
    classes.push(styles.successBorder);
  }
  //#endregion

  //#region variant
  if (!variant || variant === 'filled') {
    classes.push(styles.filled);
  }

  if (variant === 'outline') {
    classes.push(styles.outline);
  }
  //#endregion

  if (icon) {
    classes.push(styles.icon);
  }

  if (fluid) {
    classes.push(styles.fluid);
  }

  if (disabled) {
    classes.push(styles.disabled);
  }

  return (
    <div className={customClasses.join(' ')}>
      <button
        id={id}
        name={name}
        type="button"
        className={classes.join(' ')}
        disabled={disabled}
        onClick={onClick}
        {...rest}>
        <span className={styles.buttonContent}>
          {icon && (
            <i
              className={`${icon} ${
                iconPosition === 'left' ? styles.iconLeft : iconPosition === 'right' ? styles.iconRight : ''
              }`}
            />
          )}
          {label || children}
        </span>
      </button>
    </div>
  );
};

export default Button;