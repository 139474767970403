import React from 'react';
import styles from './Divider.module.scss';

type DividerProps = {
  height?: number;
  type?: string;
  children?: React.ReactNode;
  className?: string;
};

const Divider = ({ height = 1, type = 'solid', children, className }: DividerProps) => {
  return (
    <span
      style={{ borderTopWidth: `${height}px`, borderStyle: type }}
      className={[styles.divider, className].join(' ')}>
      {children && <div className={styles.text}>{children}</div>}
    </span>
  );
};

export default Divider;

// export function Divider({ height = 1, type = 'solid', children, className }: DividerProps) {
//   return (
//     <span
//       style={{ borderTopWidth: `${height}px`, borderStyle: type }}
//       className={[styles.divider, className].join(' ')}>
//       {children && <div className={styles.text}>{children}</div>}
//     </span>
//   );
// }
