import { RootState } from '../index';
import { CrmOffer } from '../../types/exelab-crm/account';

export function selectOffers(state: RootState) {
  return state.offers;
}

export function selectOffersStatus(state: RootState) {
  return state.offers.generalStatus;
}

export function selectOffersList(state: RootState) {
  return state.offers.offerList;
}

export function selectOfferPreSelected(state: RootState): Partial<CrmOffer> {
  return state.offers.preSelectedOffer!;
}

export function selectOfferSelected(state: RootState) {
  return state.offers.selectedOffer;
}
