import { ExternalComparatorsChannels } from '../../enums/channels';

export const isExternalComparatorChannel = (channel: string) => {
  const acceptedChannels: Array<string> = Object.values(ExternalComparatorsChannels);

  return acceptedChannels.includes(channel) && !isLeadsChannel(channel);
};

export const isLeadsChannel = (channel: string) => {
  const acceptedChannels: Array<any> = [ExternalComparatorsChannels.COFIDIS, ExternalComparatorsChannels.MUTUIAMO];

  return acceptedChannels.includes(channel);
};
