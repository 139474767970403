import { MainSliceState } from '../main-slice';
import { PayloadAction } from '@reduxjs/toolkit';

export const setInsuranceReducer = (state: MainSliceState, action: PayloadAction<any>) => {
  state.insurance = { ...state.insurance, ...action.payload };
};

export const setInsuranceQuestionnaireReducer = (state: MainSliceState, action: PayloadAction<any>) => {
  state.insurance.questionnaire = { ...state.insurance.questionnaire, ...action.payload };
};
