import React from 'react';
import { slide as Menu } from 'react-burger-menu';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as conte_icon_logo_color } from '../../assets/resources/logo/conte_icon_logo_color.svg';
import { ReactComponent as conte_icon_logo_white } from '../../assets/resources/logo/conte_icon_logo_white.svg';
import { closeSidebarPAAction, openSidebarPAAction } from '../../store/actions/set-sidebar.action';
import { Font } from '../../UI/Font/Font';
import Image from '../../UI/Image/Image';
import styles from './SidebarPersonalArea.module.scss';

const SidebarPersonalArea = () => {
  const dispatch = useDispatch();

  const isOpen = useSelector(state => state.sidebarPA.isOpen);
  const onClose = React.useCallback(() => dispatch(closeSidebarPAAction()), [dispatch]);

  const hubspotBaseUrl = React.useMemo(() => process.env.REACT_APP_HUBSPOT_BASE_URL, []);

  return (
    <Menu
      disableAutoFocus
      isOpen={isOpen}
      // Small devices (768px and down)
      right={window.matchMedia('(max-width: 767.98px)').matches}
      // Medium devices (768px and up)
      left={window.matchMedia('(min-width: 768px)').matches}
      width="320px"
      customBurgerIcon={false}
      customCrossIcon={
        <div>
          <i className="icon-conte_icon_close_menu" />
        </div>
      }
      onClose={onClose}>
      <div className={styles.sidebarContainer}>
        {/* Logo */}
        {/* <Image component={conte_icon_logo_white} alt="logo" className={styles.logoImage} /> */}
        <Image component={conte_icon_logo_color} alt="logo" className={styles.logoImage} />

        <div className={styles.linkContainer}>
          <Font fontSize="20">
            <a href="/personal-area">Home</a>
          </Font>
          <Font fontSize="20">
            <a href={hubspotBaseUrl + '/area-personale/stato-prestito'}>Prestiti</a>
          </Font>
          <Font fontSize="20">
            <a href={hubspotBaseUrl + '/area-personale/stato-prestito'}>Stato pratica</a>
          </Font>
          <Font fontSize="20">
            <a href={hubspotBaseUrl + '/area-personale/impostazioni-privacy'}>Impostazioni Privacy</a>
          </Font>
          <Font fontSize="20">
            <a href={hubspotBaseUrl + '/area-personale/knowledge'}>Assistenza</a>
          </Font>
          <Font fontSize="20">
            <a href={hubspotBaseUrl + '/area-personale/contattaci'}>Contatti</a>
          </Font>
          <Font fontSize="20">
            <a href={hubspotBaseUrl + '/area-personale/guide-pratiche'}>Guide pratiche</a>
          </Font>
        </div>
      </div>
    </Menu>
  );
};

export const SidebarPersonalAreaTile = () => {
  const dispatch = useDispatch();
  const showSidebarAction = React.useCallback(() => dispatch(openSidebarPAAction()), [dispatch]);

  return (
    <div className={styles.sidebarDesktopBtn}>
      <Image component={conte_icon_logo_white} className={styles.logoImage} alt="logo" />
      <div className={styles.sideButtonContainer}>
        <button className={styles.sideButton} onClick={showSidebarAction}>
          <i className="icon-conte_icon_header_left_burger_icon" />
        </button>
      </div>
    </div>
  );
};

export default SidebarPersonalArea;
