import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { Provider as ReduxProvider } from 'react-redux';
import LoadingOverlay from './components/LoadingOverlay/LoadingOverlay';
import CustomRoutes from './components/Routes/Routes';
import Sidebar from './components/Sidebar/Sidebar';
import SidebarPersonalArea from './components/SidebarPersonalArea/SidebarPersonalArea';
import { ThemeProvider } from './components/ThemeWrapper';
import { NavbarProvider } from './context/nav-context';
import reportWebVitals from './reportWebVitals';
import './scss/style.scss';
import { store } from './store';
import './assets/resources/fontello/css/conte-icons.css';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';

if (process.env.REACT_APP_GTM_ID) {
  TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID });
}

ReactDOM.render(
  <ThemeProvider> {/*TODO: Remove*/}
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ReduxProvider store={store}>
        <NavbarProvider>
          <LoadingOverlay />
          <Sidebar />
          <SidebarPersonalArea />
          <Suspense fallback={<LoadingOverlay defaultEnabled={true} />}>
            <CustomRoutes />
          </Suspense>
        </NavbarProvider>
      </ReduxProvider>
    </LocalizationProvider>
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// #region api mock service worker
if (process.env.REACT_APP_ENV === 'local') {
  const { worker } = require('./mocks/browser');
  worker.start({ onUnhandledRequest: 'bypass' });
}
//#endregion

if (!process.env.REACT_APP_FRONTEND_ENDPOINT) {
  throw new Error('REACT_APP_FRONTEND_ENDPOINT is not defined');
}

// TODO add other envs check
