import { configureStore } from '@reduxjs/toolkit';
import { mainSlice } from './main-slice';
import { useDispatch } from 'react-redux';

export const store = configureStore({
  reducer: mainSlice.reducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }),
  devTools: process.env.REACT_APP_ENV !== 'pro'
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export type RootState = ReturnType<typeof store.getState>;

export function observeStore(
  select: (rootState: RootState) => unknown,
  onChange: (state: unknown) => void
): { unsubscribe: () => void } {
  // TODO improve this
  let currentState: unknown;

  function handleChange() {
    let nextState = select(store.getState());
    if (nextState !== currentState) {
      currentState = nextState;
      onChange(currentState);
    }
  }

  const unsubscribe = store.subscribe(handleChange);
  return { unsubscribe };
}
