import { OrchestratorInstance } from '../api-client';

// Types
import { AnnualStatementReadDTO } from '../../types/annual-statement';
import {
  CreateUpdateHubspotDealRequest,
  CreateUpdateHubspotDealResponse,
  CRMAccountDTO,
  CrmPostResponseDTO,
  CRMProcedureReadDTO,
  SaveCbsCpiBeneficiaryRequest
} from '../../types/exelab-crm/account';
import { DocumentMetadataReadDTO, DocumentRead, DocumentsUploadUrlReadDTO } from '../../types/documents';
import { IbanCheckReadDTO, IbanCheckWriteDTO } from '../../types/iban-check';
import { FileMapping, KycCheckWriteDTO } from '../../types/kyc';
import { LoanStatusReadDTO } from '../../types/loan-status';
import { OfferReadDTO, OfferSelectionResponse, OfferSelectionWriteDTO, OfferWriteDTO } from '../../types/offer';
import { ObChecksWriteDTO } from '../../types/open-banking';
import { AdvData, OpenBankingData, Procedure } from '../../types/procedure';
import { ProcedureStatusReadDTO } from '../../types/procedure-status';
import { SecciPreviewWriteDTO, SecciWriteDTO } from '../../types/secci';
import { VoucherDataReadDTO, VoucherGenerateReadDTO, VoucherGenerateWriteDTO } from '../../types/vouchers';

//#region CRM

/**
 * POST /cpl/users/me
 * @description Save a user data from CRM Platform
 */
export async function createAccountCRM(payload: Partial<CRMAccountDTO>) {
  const urlEndpoint = '/cpl/users/me';
  return OrchestratorInstance.post<Partial<CRMAccountDTO>, CrmPostResponseDTO>(urlEndpoint, payload);
}

/**
 * GET /cpl/users/me
 * @description Retrieve a user from CRM Platform
 */
export async function getAccountCRM() {
  const urlEndpoint = '/cpl/users/me';
  return OrchestratorInstance.get<void, CRMAccountDTO>(urlEndpoint);
}

/**
 * POST /cpl/procedures/pre-selection
 * @description Update current procedure data in CRM
 */
export async function createProcedureCRM(payload: Partial<CreateUpdateHubspotDealRequest>) {
  const urlEndpoint = '/cpl/users/pre-selection';
  return OrchestratorInstance.post<CreateUpdateHubspotDealRequest, CreateUpdateHubspotDealResponse>(
    urlEndpoint,
    payload
  );
}

/**
 * GET /cpl/procedures/pre-selection
 * @description Get procedure data from CRM
 */
export async function getProcedureCRM() {
  const urlEndpoint = '/cpl/users/pre-selection';
  return OrchestratorInstance.get<void, CRMProcedureReadDTO>(urlEndpoint);
}

//#endregion

//#region Orchestrator

/**
 * GET /cpl/procedures/{procedureId}
 * @description Retrieve a Procedure from CBS
 */
export async function getProcedure(procedureId: string) {
  const urlEndpoint = `/cpl/procedures/${procedureId}`;
  return OrchestratorInstance.get<void, Procedure>(urlEndpoint);
}

/**
 * DELETE /cpl/procedures/{procedureId}
 * @description Cancel Procedure
 */
export async function deleteProcedure(procedureId: string) {
  const urlEndpoint = `/cpl/procedures/${procedureId}`;
  return OrchestratorInstance.delete<void, string>(urlEndpoint);
}

/**
 * POST /cpl/procedures/starting-offer
 * @description Generate a starting-offer for the given user
 */
export async function generateStartingOffer(payload: OfferWriteDTO) {
  const urlEndpoint = '/cpl/procedures/starting-offer';
  return OrchestratorInstance.post<OfferWriteDTO, OfferReadDTO>(urlEndpoint, payload);
}

/**
 * POST /cpl/procedures/offers
 * @description Update Procedure with additional offers for Account
 */
export async function generateOffers(payload: OfferWriteDTO) {
  const urlEndpoint = '/cpl/procedures/offers';
  return OrchestratorInstance.post<OfferWriteDTO, OfferReadDTO>(urlEndpoint, payload);
}

/**
 * POST /cpl/procedures/{procedureId}/selections
 * @description Select an Offer among those available on the Procedure
 */
export async function selectOffer(procedureId: string, payload: OfferSelectionWriteDTO) {
  const urlEndpoint = `/cpl/procedures/${procedureId}/selections`;
  return OrchestratorInstance.post<OfferSelectionWriteDTO, OfferSelectionResponse>(urlEndpoint, payload);
}

/**
 * POST /cpl/bureau/experian/iban-checks
 * @description Check IBAN
 */
export async function ibanCheck(payload: IbanCheckWriteDTO) {
  const urlEndpoint = '/cpl/bureau/experian/iban-checks';
  return OrchestratorInstance.post<IbanCheckWriteDTO, IbanCheckReadDTO>(urlEndpoint, payload);
}

/**
 * POST /cpl/procedures/{procedureId}/ob-checks
 * @description Execute OB-Checks by passing an incomeCheckId
 */
export async function obChecks(payload: ObChecksWriteDTO) {
  const urlEndpoint = '/cpl/procedures/ob-checks';
  return OrchestratorInstance.post<ObChecksWriteDTO, OpenBankingData>(urlEndpoint, payload);
}

/**
 * POST /cpl/procedures/{procedureId}/adv-checks
 * @description Update Account ADV Data
 */
export async function advChecks(procedureId: string, payload: Partial<AdvData>): Promise<void> {
  const urlEndpoint = `/cpl/procedures/${procedureId}/adv-checks`;
  return OrchestratorInstance.post<Partial<AdvData>, void>(urlEndpoint, payload);
}

/**
 * POST /cpl/procedures/{procedureId}/kyc-checks
 * @description Init KYC checks
 */
export async function kycChecks(procedureId: string, payload: KycCheckWriteDTO): Promise<void> {
  const urlEndpoint = `/cpl/procedures/${procedureId}/kyc-checks`;
  return OrchestratorInstance.post<KycCheckWriteDTO, void>(urlEndpoint, payload);
}

/**
 * GET /cpl/procedures/{procedureId}/status
 * @description Retrieve status of the procedure
 */
export async function getProcedureStatus(procedureId: string) {
  const urlEndpoint = `/cpl/procedures/${procedureId}/status`;
  return OrchestratorInstance.get<void, ProcedureStatusReadDTO>(urlEndpoint);
}

/**
 * GET /cpl/documents/upload-urls/{count}
 * @description Request Signed URLs
 */
export async function generateSignedURLS(count: number) {
  const urlEndpoint = `/cpl/documents/upload-urls/${count}`;
  return OrchestratorInstance.get<void, DocumentsUploadUrlReadDTO>(urlEndpoint);
}

/**
 * GET /cpl/documents/{documentId}
 * @description Download a Document
 */
export async function getDocument(procedureId: string, documentId: string) {
  const urlEndpoint = `/cpl/documents/${documentId}`;
  const params = new URLSearchParams();
  params.append('procedureId', procedureId);
  return OrchestratorInstance.get<void, any>(urlEndpoint, {
    params,
    responseType: 'blob',
    headers: {
      accept: 'application/pdf'
    }
  });
}

export const getDocumentByRole = async (procedureId: string, role: string) => {
  const urlEndpoint = `cpl/procedures/${procedureId}/${role}`;
  return OrchestratorInstance.get<void, any>(urlEndpoint);
}


/**
 * POST /cpl/documents/secci
 * @description Generate Secci Document
 */
export async function generateSecci(payload: SecciWriteDTO) {
  const urlEndpoint = '/cpl/documents/secci';
  return OrchestratorInstance.post<SecciWriteDTO, DocumentRead>(urlEndpoint, payload);
}

/**
 * POST /cpl/documents/secci-preview
 * @description Generate Secci Document Preview
 */
export async function generateSecciPreview(payload: SecciPreviewWriteDTO) {
  const urlEndpoint = '/cpl/documents/secci-preview';
  return OrchestratorInstance.post<SecciPreviewWriteDTO, DocumentRead>(urlEndpoint, payload);
}

/**
 * POST /cpl/documents
 * @description Save a multiple Document
 */
export async function uploadDocumentMeta(payload: FileMapping[]) {
  const urlEndpoint = '/cpl/documents';
  return OrchestratorInstance.post<FileMapping[], DocumentMetadataReadDTO>(urlEndpoint, payload);
}

/**
 * POST /cpl/vouchers
 * @description Create a voucher
 */
export async function createVoucher(payload: VoucherGenerateWriteDTO) {
  const urlEndpoint = '/cpl/vouchers';
  return OrchestratorInstance.post<VoucherGenerateWriteDTO, VoucherGenerateReadDTO>(urlEndpoint, payload);
}

/**
 * TODO verify the responde with the correct type
 * GET /cpl/vouchers/{voucherId}
 * @description Get a voucher user data
 */
export async function getVoucher(procedureId: string, voucherId: string) {
  const urlEndpoint = `/cpl/vouchers/${voucherId}`;
  const params = new URLSearchParams();
  params.append('procedureId', procedureId);
  return OrchestratorInstance.get<void, VoucherDataReadDTO>(urlEndpoint, { params });
}

/**
 * GET /cpl/vouchers/{voucherId}/attachments
 * @description Get a voucher attachment
 */
export async function getVoucherAttachments(voucherId: string) {
  const urlEndpoint = `/cpl/vouchers/${voucherId}/attachments`;
  return OrchestratorInstance.get<void, DocumentRead>(urlEndpoint);
}

/**
 * POST /cpl/procedures/{procedureId}/loan-status
 * @description Get loan status
 */
export async function loanStatus(procedureId: string): Promise<LoanStatusReadDTO> {
  const urlEndpoint = `/cpl/procedures/${procedureId}/loan-status`;
  return OrchestratorInstance.post<void, LoanStatusReadDTO>(urlEndpoint);
}

/**
 * GET /cpl/procedures/{procedureId}/pda
 * @description Retrieve "Piano di Ammortamento" for a given procedure
 */
export async function getPDA(procedureId: string) {
  const urlEndpoint = `/cpl/procedures/${procedureId}/pda`;
  return OrchestratorInstance.get<void, DocumentRead>(urlEndpoint);
}

/**
 * GET /cpl/procedures/{procedureId}/annual-statement
 * @description Retrieve "Annual Statement" for a given procedure
 */
export async function getAnnualStatement(procedureId: string) {
  const urlEndpoint = `/cpl/procedures/${procedureId}/annual-statement`;
  return OrchestratorInstance.get<void, AnnualStatementReadDTO>(urlEndpoint);
}

/**
 * POST /cpl/procedures/{procedureId}/early-term
 * @description Start Early term process
 */
export async function startEarlyTerm(procedureId: string, dateTerm: string) {
  const urlEndpoint = `/cpl/procedures/${procedureId}/early-term/${dateTerm}`;
  return OrchestratorInstance.post<void, void>(urlEndpoint);
}

/**
 * POST /cpl/procedures/{procedureId}/withdraw/{dateTerm}
 * @description
 */
export async function startWithdrawal(procedureId: string, dateTerm: string) {
  const urlEndpoint = `/cpl/procedures/${procedureId}/withdraw/${dateTerm}`;
  return OrchestratorInstance.post<void, void>(urlEndpoint);
}

export async function saveCpiBeneficiary(procedureId: string, payload: SaveCbsCpiBeneficiaryRequest) {
  const urlEndpoint = `/cpl/procedures/${procedureId}/beneficiaries`;
  return OrchestratorInstance.post<SaveCbsCpiBeneficiaryRequest, void>(urlEndpoint, payload);
}

//#endregion