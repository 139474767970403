import React from 'react';

// React Router
import { useNavigate } from 'react-router';
// Redux
import { useSelector } from 'react-redux';
import { selectPersonalInfo } from '../../store/selectors';

// Custom Hooks
import { useBackListener } from '../data-collection-pol/custom-hooks/useBackListenerDataCollectionPol';

// Theme
import { defaultTheme } from '../../components/ThemeWrapper';

// Component
import Slide from '../../components/Slide/Slide';
import PageWrapper from '../../components/PageWrapper';

// UI Components
import Image from '../../UI/Image/Image';
import { Container } from '../../UI/containers/Containers';
import { BoldParagraph, Paragraph } from '../../UI/paragraphs/Paragraphs';

// Resources
import {
  ReactComponent as conte_icon_illustration_signature_required_expired
} from '../../assets/resources/illustrations/expired-ko/conte_icon_illustration_signature_required_expired.svg';

const SignatureRequiredExpired = () => {

  const navigate = useNavigate();

  /**
   * useSelectors
   */
  const { firstName } = useSelector(selectPersonalInfo);

  /**
   * Custom Hooks
   */
  // Prevent navigation back
  useBackListener(() => {
    navigate(1);
  });

  return (
    <PageWrapper hasNavbarGradient>
      <Slide.Container>
        <Slide.Content noMargin>
          <Container marginBottom={40}>
            <Image component={conte_icon_illustration_signature_required_expired} />
          </Container>
          <Container fontSize={18} lineHeight={32} color={defaultTheme.colors.text}>
            <BoldParagraph color={defaultTheme.colors.dark_blue}>{firstName},</BoldParagraph>
            <Paragraph>
              il periodo a disposizione per la firma della documentazione contrattuale richiesta a seguito
              dell’approvazione della tua pratica (10 giorni) è scaduto
              e <BoldParagraph>la tua richiesta di finanziamento è stata annullata.</BoldParagraph>
            </Paragraph>
          </Container>
        </Slide.Content>
      </Slide.Container>
    </PageWrapper>
  );
};

export default SignatureRequiredExpired;






