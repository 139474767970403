export const LOAN_PROJECTS = [
  { value: 'NEW_CAR', label: 'Auto nuova', icon: 'conte_icon_wizard_new_car' },
  { value: 'USED_CAR', label: 'Auto usata', icon: 'conte_icon_wizard_old_car' },
  // { value: 'LIQUIDITY', label: 'Liquidità' },
  { value: 'DEBT_CONSOLIDATION', label: 'Consolidamento debiti', icon: 'conte_icon_wizard_add_money' },
  { value: 'MEDICAL_EXPENSES', label: 'Spese mediche', icon: 'conte_icon_wizard_healthcare' },
  { value: 'ESTHETICS_WELLBEING', label: 'Estetica e Benessere', icon: 'conte_icon_wizard_beauty' },
  { value: 'STUDYING_TRAINING_COURSES', label: 'Studio', icon: 'conte_icon_wizard_studio' },
  { value: 'WEDDING', label: 'Matrimonio', icon: 'conte_icon_wizard_wedding' },
  { value: 'TRAVELS', label: 'Viaggi', icon: 'conte_icon_wizard_trip' },
  { value: 'HOME_IMPROVEMENT', label: 'Ristrutturazione casa', icon: 'conte_icon_wizard_house_improv' },
  { value: 'REAL_ESTATE_BOX_PURCHASE', label: 'Acquisto Box', icon: 'conte_icon_wizard_box' },
  { value: 'NEW_USED_MOTORBIKE', label: 'Moto nuova o usata', icon: 'conte_icon_wizard_bike' },
  { value: 'FURNITURE', label: 'Arredamento', icon: 'conte_icon_wizard_furnitures' },
  { value: 'OTHER', label: 'Altre spese', icon: 'conte_icon_wizard_other' }
];
