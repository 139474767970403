import styled from 'styled-components/macro';

interface HeadersProps {
  color?: any;
  fontSize?: number;
  lineHeight?: number;
  isBold?: boolean;
}

export const PageTitle = styled.h1<HeadersProps>`
  margin: 0;
  padding: 0;
  font-size: ${(props: HeadersProps) => props.fontSize || '18'}px;
  line-height: ${(props: HeadersProps) => props.fontSize || '24'}px;
  font-weight: ${(props: HeadersProps) => (props.isBold ? 'bold' : '')};
`;

export const PageSubtitle = styled.h2<HeadersProps>`
  margin: 0;
  padding: 0;
  font-size: ${(props: HeadersProps) => props.fontSize || '18'}px;
`;

export const CardTitle = styled.h4<HeadersProps>`
  margin: 0;
  padding: 0;
  font-size: ${(props: HeadersProps) => props.fontSize || 17}px;
  line-height: ${(props: HeadersProps) => props.lineHeight || ''}px;
`;

export const CardSubTitle = styled.h5<HeadersProps>`
  margin: 0;
  padding: 0;
  font-size: ${(props: HeadersProps) => props.fontSize || ''}px;
  color: ${(props: HeadersProps) => props.color || ''};
`;

const Headers = { PageTitle, PageSubtitle, CardTitle, CardSubTitle };

export default Headers;
