import styled from 'styled-components/macro';

interface ParagraphsProps {
  color?: any;
  fontSize?: number;
  fontStyle?: string;
  whiteSpace?: string;
  lineHeight?: number;
}

export const Paragraph = styled.p<ParagraphsProps>`
  margin: 0;
  padding: 0;
  color: ${(props: ParagraphsProps) => props.color || ''};
  font-size: ${(props: ParagraphsProps) => props.fontSize || ''}px;
  font-style: ${(props: ParagraphsProps) => props.fontStyle || ''};
  white-space: ${(props: ParagraphsProps) => props.whiteSpace || 'pre-line'};
  line-height: ${(props: ParagraphsProps) => props.lineHeight || ''}px;;
`;

export const BoldParagraph = styled.strong<ParagraphsProps>`
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  color: ${(props: ParagraphsProps) => props.color || ''};
  font-size: ${(props: ParagraphsProps) => props.fontSize || ''}px;
  font-style: ${(props: ParagraphsProps) => props.fontStyle || ''};
`;

export const ItalicParagraph = styled.i<ParagraphsProps>`
  color: ${(props: ParagraphsProps) => props.color || ''};
  font-size: ${(props: ParagraphsProps) => props.fontSize || ''}px;
`

// Custom
export const ParagraphWithLine = styled(Paragraph)`
  border-bottom: 2px solid #00000021;
  line-height: 0.1em;

  & > span {
    background: #fff;
    padding: 0 10px;
  }
`;