/**
 * All functions implemented in this file refers to the creation and update of CRM Account
 */

import { OrchestratorAPI } from '../api';
import { StepCheckpoints } from '../constants/step-checkpoints';
import { CRMAccountDTO, CrmKYC } from '../types/exelab-crm/account';
import { AdvData } from '../types/procedure';
import { parseAccount } from '../utils/orchestrator-transform';
import { removeEmptyValuesDeep } from '../utils/remove-empty-values';

// Update an Account with the current slideId and payload state delta
export const updateAccount = async (deltaPayload: Partial<CRMAccountDTO>) => {
  const sanitizedPayload = removeEmptyValuesDeep(deltaPayload);
  await OrchestratorAPI.createAccountCRM(sanitizedPayload);
};

export const updateDevicePayload = async ({
  applicationId,
  devicePayload
}: {
  applicationId: CRMAccountDTO['applicationId'];
  devicePayload: any;
}): Promise<void> => {
  const payload: Partial<CRMAccountDTO> = { applicationId, kyc: { kyc_data: { devicePayload } } };
  await OrchestratorAPI.createAccountCRM(payload);
};

export const updateConsents = async (consents: Partial<CRMAccountDTO['consents']>): Promise<void> => {
  const account = await OrchestratorAPI.getAccountCRM();
  const payload: Partial<CRMAccountDTO> = { consents: { ...account.consents, ...consents } };
  await OrchestratorAPI.createAccountCRM(payload);
};

export const enableOpenBanking = async (): Promise<void> => {
  const payload: Partial<CRMAccountDTO> = {
    applicants: [{ openBankingEnabled: true }]
  };
  await OrchestratorAPI.createAccountCRM(payload);
};

export const setOpenBankingCode = async (openBankingCode: string): Promise<void> => {
  console.log('setOpenBankingCode ~ openBankingCode', openBankingCode);
  const payload: Partial<CRMAccountDTO> = {
    applicants: [{ openBankingCode, openBankingEnabled: true }]
  };
  await OrchestratorAPI.createAccountCRM(payload);
};

export const setIbanCode = async (iban: string): Promise<void> => {
  const payload: Partial<CRMAccountDTO> = { kyc: { iban } };
  await OrchestratorAPI.createAccountCRM(payload);
};

export const updateKycCount = async (kycMethod: string): Promise<void> => {
  const account = await OrchestratorAPI.getAccountCRM();
  const kyc = account.kyc ? account.kyc : {};
  const payload: Partial<CRMAccountDTO> | null = 'spid' === kycMethod ?
    {
      kyc: {
        kyc_data: {
          ...kyc.kyc_data,
          spidInterruptionCount: kyc.kyc_data?.spidInterruptionCount !== undefined? kyc.kyc_data?.spidInterruptionCount + 1 : 1
        }
      }
    } : 'cied' === kycMethod ? {
      kyc: {
        kyc_data: {
          ...kyc.kyc_data,
          cieInterruptionCount: kyc.kyc_data?.cieInterruptionCount !== undefined? kyc.kyc_data?.cieInterruptionCount + 1 : 1
        }
      }
    } : null;

  if (payload) {
    await OrchestratorAPI.createAccountCRM(payload);
  } else {
    return Promise.reject('Internal server error');
  }
};

type UpdateKycSuccessTypes = { kyc_mode: CrmKYC['kyc_mode']; kyc_document_type: CrmKYC['kyc_document_type'] };
export const updateKycSuccess = async ({
  kyc_mode,
  kyc_document_type
}: UpdateKycSuccessTypes): Promise<void> => {
  const account = await OrchestratorAPI.getAccountCRM();
  const kyc = account.kyc ? account.kyc : {};
  const payload: Partial<CRMAccountDTO> = {
    step_checkpoint: StepCheckpoints.IDENTITY_VERIFIED,
    kyc: {
      kyc_mode,
      kyc_completed_at: new Date().toISOString(),
      kyc_documentation_uploaded: true,
      kyc_retry_count: kyc.kyc_retry_count ? +kyc.kyc_retry_count + 1 : 1,
      kyc_document_type,
      kyc_data: {
        ...kyc.kyc_data,
        kycCompleted: true
      }
    }
  };
  await OrchestratorAPI.createAccountCRM(payload);
};

type UpdateKycIncomeDocumentationTypes = {
  kyc_income_documentation_type: CrmKYC['kyc_income_documentation_type'];
};
export const updateKycIncomeDocumentation = async ({
  kyc_income_documentation_type
}: UpdateKycIncomeDocumentationTypes): Promise<void> => {
  const payload = {
    kyc: { kyc_income_documentation_type }
  };
  await OrchestratorAPI.createAccountCRM(payload);
};

export const updateSpidFailed = async (): Promise<void> => {
  const account = await OrchestratorAPI.getAccountCRM();
  const kyc = account.kyc ? account.kyc : {};
  const payload = {
    kyc: {
      kyc_data: {
        ...kyc.kyc_data,
        kycCompleted: false,
        spidFailed: true
      }
    }
  };
  await OrchestratorAPI.createAccountCRM(payload);
};

export const updateKycFailed = async (): Promise<void> => {
  const account = await OrchestratorAPI.getAccountCRM();
  const kyc = account.kyc ? account.kyc : {};
  const payload = {
    kyc: {
      kyc_data: {
        ...kyc.kyc_data,
        kycCompleted: false,
        videoSelfieFailed: true
      }
    }
  };
  await OrchestratorAPI.createAccountCRM(payload);
};

export const updateQuestionnaire = async (
  questionnaire: Partial<AdvData>
): Promise<{ stepCheckpoint: StepCheckpoints }> => {
  const payload = {
    step_checkpoint: StepCheckpoints.QUESTIONNAIRE_COMPLETED,
    questionnaire
  };
  const sanitizedPayload = removeEmptyValuesDeep(payload);
  await OrchestratorAPI.createAccountCRM(sanitizedPayload);
  return { stepCheckpoint: payload.step_checkpoint };
};

//#region checkpoint update

export const updateStartingOfferDisplayedCheckpoint = async (): Promise<StepCheckpoints> => {
  return await updateCheckpoint(StepCheckpoints.STARTING_OFFER_DISPLAYED);
};

export const updateOfferKOCheckpoint = async (): Promise<StepCheckpoints> => {
  return await updateCheckpoint(StepCheckpoints.OFFER_BUREAU_KO);
};

export const updateOfferDeclinedByRulesCheckpoint = async (): Promise<StepCheckpoints> => {
  return await updateCheckpoint(StepCheckpoints.DECLINED_BY_RULES);
};

export const updateSelectedOfferCheckpoint = async (): Promise<StepCheckpoints> => {
  return await updateCheckpoint(StepCheckpoints.SELECTED_OFFER);
};

export const updateIdentityToBeVerifiedCheckpoint = async (): Promise<StepCheckpoints> => {
  return await updateCheckpoint(StepCheckpoints.IDENTITY_TO_BE_VERIFIED);
};

export const ibanChangeRequestedCheckpoint = async (): Promise<StepCheckpoints> => {
  return await updateCheckpoint(StepCheckpoints.IBAN_CHANGE_REQUESTED);
};

export const paychecksUploadedCheckpoint = async (): Promise<StepCheckpoints> => {
  return await updateCheckpoint(StepCheckpoints.PAYCHECKS_UPLOADED);
}

export const documentsUploadedCheckpoint = async (): Promise<StepCheckpoints> => {
  return await updateCheckpoint(StepCheckpoints.DOCS_UPLOADED);
};

export const updateCheckpoint = async (checkpoint: StepCheckpoints): Promise<StepCheckpoints> => {
  await OrchestratorAPI.createAccountCRM({ step_checkpoint: checkpoint });
  return checkpoint;
};

//#endregion

export const getAccount = async () => await OrchestratorAPI.getAccountCRM().then(parseAccount);
