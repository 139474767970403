import React, { Fragment } from 'react';

// React Router
import { useLocation } from 'react-router';

// Redux
import { RootState } from '../../store';
import { useSelector } from 'react-redux';

// Theme
import { defaultTheme } from '../../components/ThemeWrapper';

// UI Components
import { BoldParagraph, Paragraph } from '../paragraphs/Paragraphs';
import { Container, FlexContainer } from '../containers/Containers';
import Image from '../Image/Image';

// Enums
import { NotificationType } from '../../enums/notification-type';
import { AppRoutes } from '../../enums/app-routes';

// Resources
import {
  ReactComponent as conte_icon_feedback_ok
} from '../../assets/resources/images/conte_icon_feedback_ok.svg';
import {
  ReactComponent as conte_icon_message_box_half_flow
} from '../../assets/resources/images/conte_icon_message_box_half_flow.svg';
import {
  ReactComponent as conte_icon_action_panel_alert
} from '../../assets/resources/images/conte_icon_action_panel_alert.svg';


// Styles
import styles from './Toast.module.scss';


interface ToastProps {
  show: boolean,
  image?: any,
  title?: string,
  message?: string;
  type?: NotificationType
  children?: any;
}

const Toast: React.FC<ToastProps> = ({ show, image, title, message, type, children }) => {
  const styleShow = show ? styles.showToast : styles.hideToast;
  const isLoadingToast = useSelector((state: RootState) => state?.loadingPopup?.isOpen);
  const {pathname} = useLocation();

  return (
    <div className={[styles.toastContainer, styleShow, !pathname.includes(AppRoutes.CONTINUE) && styles.margin].join(' ')}>
      <FlexContainer className={styles.toast} align={'left'}>

        {show && NotificationType.SUCCESS === type &&
          <Image className={styles.imageNotification} component={conte_icon_feedback_ok} width={'75px'}
                 height={'75px'} />
        }

        {show && NotificationType.INFO === type &&
          <Image className={styles.imageNotification} component={conte_icon_action_panel_alert} width={'75px'}
                 height={'75px'} />
        }

        {NotificationType.SUCCESS !== type && NotificationType.INFO !== type && <Image component={conte_icon_message_box_half_flow} className={styles.image} />}

        <Container align="left">

          {(type || title) &&
            <Container align="left" marginTop={20} marginBottom={10}>
              {NotificationType.SUCCESS === type &&
                <Fragment>
                  <BoldParagraph color={defaultTheme.colors.c_dark_green}>Operazione completata con
                    successo!</BoldParagraph>
                  <br />
                </Fragment>
              }
              <BoldParagraph color={defaultTheme.colors.dark_blue} fontSize={isLoadingToast ? 20 : 14}>{title}</BoldParagraph>
            </Container>
          }

          <Paragraph color={defaultTheme.colors.text} fontSize={isLoadingToast ? 16 : 14}>{message}</Paragraph>
          <Container align={'left'} color={defaultTheme.colors.text}>{children}</Container>
        </Container>

      </FlexContainer>
    </div>
  );
};

export default Toast;