import { PayloadAction } from '@reduxjs/toolkit';
import { MainSliceState } from '../main-slice';

export const setPrivacyPolicyReducer = (state: MainSliceState, action: PayloadAction<any>) => {
  state.dataCollection.slides.personal_info_1.datePrivacy = action.payload.personal_info_1.datePrivacy;
};

export const setPrivacyConsentsReducer = (state: MainSliceState, action: PayloadAction<any>) => {
  state.dataCollection.slides.privacy_consents = action.payload.privacy_consents || { ...action.payload };
};
