import React from 'react';

// React Router
import { useNavigate } from 'react-router';

// Redux
import { useSelector } from 'react-redux';
import { selectPersonalInfo } from '../../store/selectors';

// Custom Hooks
import { useBackListener } from '../data-collection-pol/custom-hooks/useBackListenerDataCollectionPol';
import useDataLayer from '../../features/data-layer/hooks/use-data-layer';

// Theme
import { defaultTheme } from '../../components/ThemeWrapper';

// Component
import Slide from '../../components/Slide/Slide';
import PageWrapper from '../../components/PageWrapper';

// UI Components
import Image from '../../UI/Image/Image';
import { Container } from '../../UI/containers/Containers';
import { BoldParagraph, Paragraph } from '../../UI/paragraphs/Paragraphs';

// Enums
import { PageSections } from '../../enums/data-layer/page-sections';

// Constants
import { CONTE_HOME, SIC_FAQ } from '../../constants/links';

// Resources
import {
  ReactComponent as conte_icon_illustration_declined_by_rules_bureau_ko
} from '../../assets/resources/illustrations/personal-area/conte_icon_illustration_declined_by_rules_bureau_ko.svg';

const DeclinedByRules: React.FC = () => {

  const navigate = useNavigate();

  /**
   * useSelectors
   */
  const { firstName } = useSelector(selectPersonalInfo);

  /**
   * Custom Hooks
   */
  // Prevent navigation back
  useBackListener(() => {
    navigate(1);
  });
  const { handlePushFunnelStep } = useDataLayer(PageSections.NEGOTIATION);

  /**
   * handlers
   */
  const handleRedirectHome = () => {
    handlePushFunnelStep(undefined);
    window.location.href = process.env.REACT_APP_LANDING_PAGE_URL ?? CONTE_HOME;
  };

  return (
    <PageWrapper hasNavbarGradient>
      <Slide.Container>
        <Slide.Content noMargin>
          <Container marginBottom={40}>
            <Image component={conte_icon_illustration_declined_by_rules_bureau_ko} />
          </Container>
          <Container fontSize={16} lineHeight={26} color={defaultTheme.colors.text} padding={10}>
            <BoldParagraph color={defaultTheme.colors.dark_blue}>{firstName},</BoldParagraph>
            <Paragraph>
              sulla base delle informazioni che ci hai fornito, non siamo in grado di fornire una proposta di prestito
              personale in quanto la tua situazione complessiva non è in linea con le nostre policy.
              <br /><br />
              Non essendo stati consultati i Sistemi di Informazione Creditizia (per maggiori informazioni leggi la nostra&nbsp;
              <a className='link' href={SIC_FAQ} tabIndex={0} target='_blank'>FAQ</a>), non
              hai bisogno di alcuna comunicazione che abbia valore di liberatoria per richiesta di finanziamento non
              concesso.
            </Paragraph>
          </Container>
        </Slide.Content>
        <Slide.Actions>
          <Slide.FixedButton text="Torna alla Homepage" onClick={handleRedirectHome} />
        </Slide.Actions>
      </Slide.Container>
    </PageWrapper>
  );
};

export default DeclinedByRules;
