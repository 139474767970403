import { PayloadAction } from '@reduxjs/toolkit';
import { MainSliceState } from '../main-slice';

export const setMonthlyIncomeReducer = (state: MainSliceState, action: PayloadAction<any>) => {
  state.dataCollection.slides.monthly_income = action.payload.monthly_income;
};

export const setIncomeSourcesReducer = (state: MainSliceState, action: PayloadAction<any>) => {
  state.dataCollection.slides.income_sources = action.payload.income_sources;
};
