export enum AppRoutes {
  // Test
  LANDING = 'landing',

  // Section Routes
  DATA_COLLECTION_POL = 'data-collection-pol',
  LEADS = 'data-collection-leads',
  DATA_COLLECTION = 'data-collection',
  REDUCED_DATA_COLLECTION = 'data-collection-short',
  OPTIMIZED_DATA_COLLECTION = 'optimized-data-collection',
  NEGOTIATION = 'negotiation',
  KYC = 'kyc',
  ADV = 'adv',
  PERSONAL_AREA = 'personal-area',
  OTP = 'otp',
  PROGRESS = 'progress',
  INSURANCE = 'insurance',

  // Other Routes
  CONTINUE = 'continue',
  ASSISTANCE = 'assistance',
  SITE_UNDER_MAINTENANCE = 'site-under-maintenance',
  OPEN_BANKING_CALLBACK = 'ob-callback',

  // Funnel KO
  CANCELLED = 'cancelled',
  DECLINED_BY_RULES = 'declined-by-rules',
  DECLINED_BY_AML = 'declined-by-aml',
  DECLINED_BY_UW = 'declined-by-uw',
  OFFER_BUREAU_KO = 'offer-bureau-ko',
  // Procedure KO
  OFFERS_EXPIRED = 'offers-expired',
  OFFER_SELECTED_EXPIRED = 'offer-selected-expired',
  SIGNATURE_REQUIRED_EXPIRED = 'signature-required-expired',
  DOCS_REQUIRED_BY_UW_EXPIRED = 'docs-required-by-uw-expired',
  DOCS_REQUIRED_BY_AML_EXPIRED = 'docs-required-by-aml-expired',
  KYC_EXPIRED = 'kyc-expired'
}

export enum ProgressRoutes {
  DATA_COLLECTION = 'data-collection',
  NEGOTIATION = 'negotiation',
  KYC = 'kyc'
}

export enum ReducedDataCollectionRoutes {
  PERSONAL_INFO = 'personal-info',
  EMPLOYMENT = 'employment',
  INCOME_AND_EXPENSES = 'income-and-expenses',
  COMPLETE = 'complete',
  PRIVACY_CONSENTS = 'privacy-consents'
}

export enum DataCollectionRoutes {
  CONTACT_INFO = 'contact-info',
  PRIVACY_CONSENTS = 'privacy-consents',
  PERSONAL_INFO = 'personal-info',
  BIRTH = 'birth',
  FISCAL_CODE = 'fiscal-code',
  EMPLOYMENT = 'employment',
  VAT_NUMBER = 'vat-number',
  HIRING = 'hiring',
  JOB = 'job',
  COMPANY_SIZE = 'company-size',
  COMPANY_INDUSTRY = 'company-industry',
  GROSS_INCOME = 'gross-income',
  MONTHLY_INCOME = 'monthly-income',
  OTHER_INCOME_SOURCES = 'other-income-sources',
  ADDRESS = 'address',
  MARITAL_STATUS = 'marital-status',
  FAMILY = 'family',
  LIVING_SITUATION = 'living-situation',
  COUPON_CODE = 'coupon-code',
  LANDING = 'landing'
}

export enum LeadsRoutes {
  LOAN_INTENT = 'loan-intent',
  PRIVACY = 'privacy-consents'
}

export enum NegotiationRoutes {
  OFFER = 'offer',
  EDIT_OFFER = 'edit-offer',
  OFFER_SUMMARY = 'offer-summary',
  IBAN = 'iban-input'
}

export enum KycRoutes {
  EIDAW_REDIRECT = 'eiDAW-redirect',
  VIDEO_SELFIE = 'video-selfie',
  INCOME_DOCUMENTATION = 'income-documentation'
}

export enum VideoSelfieRoutes {
  PRIVACY = 'privacy',
  DESKTOP = 'desktop',
  DOCUMENT_CHOISE = 'document-choise',
  FRONT_INSTRUCTION = 'front-instruction',
  FRONT_CAPTURE = 'front-capture',
  BACK_CAPTURE = 'back-capture',
  BACK_INSTRUCTION = 'back-instruction',
  SELFIE_INSTRUCTION = 'selfie-instruction',
  SELFIE_CAPTURE = 'selfie-capture',
  TS_FRONT_INSTRUCTION = 'ts-front-instruction',
  TS_FRONT_CAPTURE = 'ts-front-capture',
  TS_BACK_INSTRUCTION = 'ts-back-instruction',
  TS_BACK_CAPTURE = 'ts-back-capture',
  PERMIT_FRONT_INSTRUCTION = 'permit-front-instruction',
  PERMIT_FRONT_CAPTURE = 'permit-front-capture',
  PERMIT_BACK_INSTRUCTION = 'permit-back-instruction',
  PERMIT_BACK_CAPTURE = 'permit-back-capture',
  VERIFICATION_ONGOING = 'verification-ongoing',
  VERIFICATION_COMPLETE = 'verification-complete',
  VERIFICATION_FAILED = 'verification-failed'
}

export enum IncomeDocumentationRoutes {
  ACCOUNT_STATEMENT = 'account-statement',
  PAYROLL = 'payroll'
}

export enum InsuranceRoutes {
  ADVANTAGES = 'advantages',
  EXCLUSIONS = 'exclusions',
  INFO = 'info',
  QUESTIONNAIRE = 'questionnaire',
  BENEFICIARY = 'beneficiary',
}

export enum BeneficiaryRoutes {
  SELECT = "select"
}
