import { DC_SLIDES } from '../constants/data-collection-slides';
import { MainSliceState } from '../store/main-slice';
import { CRMAccountDTO, HubspotAccountDocument } from '../types/exelab-crm/account';
import { OfferWriteDTO } from '../types/offer';
import { Address } from '../types/procedure';
import { explodeDate } from './dateUtil';
import { YesNo } from '../enums/yes-no';
import { TrueFalse } from '../enums/true-false';
import { DocumentRoles } from '../enums/cbs/document-roles';
import { orderBy } from 'lodash';

export const generateAccount = (slides: any): Partial<OfferWriteDTO> => {
  const { zipCode, streetName, streetNumber, city, province, country, differentHomeAddress } =
    slides[DC_SLIDES.ADDRESS.ID];

  const addresses: Address[] = [];

  // TODO validare address, not only the streetName
  if (zipCode && streetName && streetNumber && city && province && country) {
    const address1: Address = {
      /* String  */ zipCode,
      /* String  */ streetName,
      /* String  */ streetNumber,
      /* String  */ city,
      /* String  */ province, // [RM, MI, NA, ...]
      /* String  */ country, // [ITA, GER]
      /* String  */ status: 'ACTIVE',
      /* String  */ type: 'RESIDENCE'
    };
    addresses.push(address1);
  }

  if (differentHomeAddress) {
    const { homeZipCode, homeStreetName, homeStreetNumber, homeCity, homeProvince, homeCountry } =
      slides[DC_SLIDES.ADDRESS.ID];
    const address2: Address = {
      /* String  */ zipCode: homeZipCode,
      /* String  */ streetName: homeStreetName,
      /* String  */ streetNumber: homeStreetNumber,
      /* String  */ city: homeCity,
      /* String  */ province: homeProvince, // [RM, MI, NA, ...]
      /* String  */ country: homeCountry, // [ITA, GER]
      /* String  */ status: 'ACTIVE',
      /* String  */ type: 'HOME'
    };
    addresses.push(address2);
  }

  const birthYear = slides[DC_SLIDES.PERSONAL_INFO_2.ID].birthYear;
  const birthMonth = slides[DC_SLIDES.PERSONAL_INFO_2.ID].birthMonth;
  const birthDay = slides[DC_SLIDES.PERSONAL_INFO_2.ID].birthDay;
  const birthDate = birthYear && birthMonth && birthDay ? [birthYear, birthMonth, birthDay].join('-') : '';

  const payload: Partial<OfferWriteDTO> = {
    applicants: [
      {
        /* String  */ fiscalCode: slides[DC_SLIDES.FISCAL_ID.ID].code,
        /* String  */ firstName: slides[DC_SLIDES.PERSONAL_INFO_1.ID].firstName,
        /* String  */ lastName: slides[DC_SLIDES.PERSONAL_INFO_1.ID].lastName,
        /* String  */ gender: slides[DC_SLIDES.PERSONAL_INFO_1.ID].gender,
        /* String  */ status: 'DRAFT',
        /* String  */ recordType: 'NATURAL_PERSON',
        /* String  */ datePrivacy: slides[DC_SLIDES.PERSONAL_INFO_1.ID].datePrivacy,
        /* String  */ phone: slides[DC_SLIDES.CONTACT_INFO.ID].phoneComplete,
        /* String  */ email: slides[DC_SLIDES.CONTACT_INFO.ID].email,
        /* List    */ addresses,
        /* int   ? */ yearsInItaly: slides[DC_SLIDES.PERSONAL_INFO_2.ID].inItalyFrom,
        /* String  */ nationality: slides[DC_SLIDES.PERSONAL_INFO_2.ID].nationality,
        /* String  */ birthCity: slides[DC_SLIDES.PERSONAL_INFO_2.ID].birthCity,
        /* String  */ birthProvince: slides[DC_SLIDES.PERSONAL_INFO_2.ID].birthProvince, // [RM, MI, NA]
        /* String  */ birthCountry: slides[DC_SLIDES.PERSONAL_INFO_2.ID].birthCountry, // [ITA, GER]
        /* String  */ birthDate,
        homeStatus: slides[DC_SLIDES.LIVING_SITUATION.ID].homeStatus,
        rentMortgageAmount: slides[DC_SLIDES.LIVING_SITUATION.ID].rentMortgageAmount,
        vatNumber: slides[DC_SLIDES.VAT_NUMBER.ID].vatNumber
      }
    ]
  };

  return payload;
};

export const generateLoanData = (loanIntent: any, slides: any) => {
  const payload = {
    ...loanIntent,
    promoCode: slides[DC_SLIDES.COUPON_CODE.ID].coupon,

    /* String  */ maritalStatus: slides[DC_SLIDES.MARITAL_STATUS.ID].maritalStatus,
    /* String? */ numHouseholdMembers: slides[DC_SLIDES.NUM_HOUSEHOLD_MEMBERS.ID].numHouseholdMembers,
    /* String? */ peopleWithIncome: slides[DC_SLIDES.PEOPLE_WITH_INCOME.ID].peopleWithIncome,
    /* String  */ educationLevel: slides[DC_SLIDES.EDUCATION_LEVEL.ID].educationLevel,
    jobData: {
      /* String  */ employmentStatus: slides[DC_SLIDES.EMPLOYMENT_STATUS.ID].employmentStatus,
      /* int     */ jobRole: slides[DC_SLIDES.JOB_ROLE.ID].jobRole,
      /* String? */ hiredOnYear: slides[DC_SLIDES.HIRED_ON_YEAR.ID].hiredOnYear,
      employerName: slides[DC_SLIDES.HIRED_ON_YEAR.ID].employerName,
      /* String? */ hiredOnMonth: slides[DC_SLIDES.HIRED_ON_MONTH.ID].hiredOnMonth,
      /* String? */ companyIndustry: slides[DC_SLIDES.COMPANY_INDUSTRY.ID].companyIndustry,
      /* String? */ companySize: slides[DC_SLIDES.COMPANY_SIZE.ID].companySize
    },
    financialData: {
      // TODO required for CRM, not for OR
      /* String? */ previousLoans: slides[DC_SLIDES.PREVIOUS_LOANS.ID].previousLoans, // [ON_GOING, NEVER, IN_THE_PAST]
      /* String? */ latePayments: slides[DC_SLIDES.LATE_PAYMENTS.ID].latePayments,
      /* Float   */ netMonthlySalaryEur: slides[DC_SLIDES.MONTHLY_INCOME.ID].netMonthlySalaryEur || 0,
      /* Float   */ salaryMonthsCnt: slides[DC_SLIDES.MONTHLY_INCOME.ID].salaryMonthsCnt || 0,
      /* Float   */ grossYearlySalaryEur:
        slides[DC_SLIDES.MONTHLY_INCOME.ID].grossYearlySalaryEur || undefined,
      /* Bool  ? */ hasOtherIncome:
        slides[DC_SLIDES.INCOME_SOURCES.ID].hasOtherIncome === undefined
          ? undefined
          : slides[DC_SLIDES.INCOME_SOURCES.ID].hasOtherIncome === 'Y',
      descriptionSourcesIncome: slides[DC_SLIDES.INCOME_SOURCES.ID].descriptionSourcesIncome,
      /* Float ? */ otherNetMonthlyIncomeEur: slides[DC_SLIDES.INCOME_SOURCES.ID].otherNetMonthlyIncomeEur || undefined,
      /* Float ? */ totalMonthlyInstallmentsAmountEur:
        slides[DC_SLIDES.LIVING_SITUATION.ID].totalMonthlyInstallmentsAmountEur
    },
    employerName: slides[DC_SLIDES.HIRED_ON_YEAR.ID].employerName,
    rentMortgageAmount: slides[DC_SLIDES.LIVING_SITUATION.ID].rentMortgageAmount,
    vatNumber: slides[DC_SLIDES.VAT_NUMBER.ID].vatNumber,
    homeStatus: slides[DC_SLIDES.LIVING_SITUATION.ID].homeStatus,
    insurance: YesNo.NO
  };

  return payload;
};

export const generateOfferRequest = (
  applicationId: any,
  account: any,
  deviceTransactionId: any,
  loanData: any
) => {
  const applicants = account.applicants;
  return { applicationId, applicants, deviceTransactionId, loanData };
};

// reverse of generateAccount
export const parseAccount = (payload: CRMAccountDTO): Partial<MainSliceState> => {

  const reducedDocuments = payload.documents?.reduce((acc: Array<any>, curr) => {
    const year = new Date(curr.date).getFullYear();
    const existingEntry = acc.find(entry => entry.year === year);
    if ([DocumentRoles.LETTERA_TRASPARENZA, DocumentRoles.RENDICONTO].includes(curr.role)) {
      if (!existingEntry) {
        acc.push({
          year,
          [curr.role]: curr.entityid
        });
      } else {
        existingEntry[curr.role] = curr.entityid;
      }
    }
    return acc;
  }, []);

  const documentsGroupedByYear = orderBy(reducedDocuments, "year", "desc");

  const hasCurrentYearReport = payload.documents?.filter(
    (document: HubspotAccountDocument) =>
      new Date(document.date).getFullYear() === new Date().getFullYear() &&
      [DocumentRoles.RENDICONTO, DocumentRoles.LETTERA_TRASPARENZA].includes(document.role)
  ).length === 2;


  const account = payload.applicants?.length ? payload.applicants[0] : undefined;

  const { year: birthYear, month: birthMonth, day: birthDay } = explodeDate(account?.birthDate);

  const parsed: Partial<MainSliceState> = {
    ...payload,

    // #region imported by default
    // id: payload.id,
    // procedureId: payload.procedureId,
    // applicationId: payload.applicationId,
    // step_checkpoint: payload.step_checkpoint,
    // base_url: payload.base_url,
    channel: payload.channel_original,
    latestChannel: payload.channel,
    // consents: payload.consents,
    //#endregion

    // TODO those fields are hardcoded
    // status: account.status,
    // recordType: account.recordType,
    // channel: 'PORTAL',

    contactId: payload.contact_id,
    loanIntent: payload.loan_intent,
    dataCollection: {
      step: Object.values(DC_SLIDES).find(s => s.ID === payload.step)!,
      progressPercentage: 0,
      slides: {
        // INFORMAZIONI PERSONALI
        [DC_SLIDES.CLIENT_TYPE.ID]: { clientType: 'not-client' }, // TODO questo dato non viene salvato, viene applicato hardcoded - CTLFE-201
        [DC_SLIDES.PERSONAL_INFO_1.ID]: {
          firstName: account?.firstName,
          lastName: account?.lastName,
          gender: account?.gender,
          datePrivacy: account?.datePrivacy
        },
        [DC_SLIDES.PERSONAL_INFO_2.ID]: {
          birthDay,
          birthMonth,
          birthYear,
          birthCountry: account?.birthCountry,
          birthProvince: account?.birthProvince,
          birthCity: account?.birthCity,
          nationality: account?.nationality,
          inItalyFrom: account?.yearsInItaly
        },
        [DC_SLIDES.FISCAL_ID.ID]: {
          code: account?.fiscalCode
        },
        [DC_SLIDES.CONTACT_INFO.ID]: {
          email: account?.email,
          ...(account?.phone
            ? {
                phonePrefix: +account.phone.split(' ')[0].replace('+', ''),
                phone: account.phone.split(' ')[1],
                phoneComplete: account.phone
              }
            : {})
        },
        [DC_SLIDES.PRIVACY_CONSENTS.ID]:  payload.consents? {
          ...Object.keys(payload.consents).reduce(
            (accumulator, currentKey) => ({
              ...accumulator,
              [currentKey]:
                [undefined, ''].includes((payload.consents as any)[currentKey]) ||
                (payload.consents as any)[currentKey] === true
                  ? TrueFalse.T
                  : (payload.consents as any)[currentKey] === false
                  ? TrueFalse.F
                  : new Date((payload.consents as any)[currentKey]).toJSON()
            }),
            {}
          )
        } : {},
        [DC_SLIDES.ADDRESS.ID]: {
          ...(!!account?.addresses && account.addresses[0]
            ? {
                streetName: account.addresses[0].streetName,
                streetNumber: account.addresses[0]?.streetNumber,
                city: account.addresses[0]?.city,
                zipCode: account.addresses[0]?.zipCode,
                province: account.addresses[0]?.province,
                country: account.addresses[0]?.country,
                differentHomeAddress: account.addresses[1]?.type !== "RESIDENCE"
              }
            : {}),
          ...(!!account?.addresses && account.addresses[1]?.type !== "RESIDENCE"
            ? {
                homeStreetName: account.addresses[1].streetName,
                homeStreetNumber: account.addresses[1].streetNumber,
                homeCity: account.addresses[1].city,
                homeZipCode: account.addresses[1].zipCode,
                homeProvince: account.addresses[1].province,
                homeCountry: account.addresses[1].country
              }
            : {})
        },
        [DC_SLIDES.MARITAL_STATUS.ID]: {
          maritalStatus: account?.maritalStatus
        },
        [DC_SLIDES.NUM_HOUSEHOLD_MEMBERS.ID]: {
          numHouseholdMembers: account?.numHouseholdMembers
        },
        [DC_SLIDES.PEOPLE_WITH_INCOME.ID]: {
          peopleWithIncome: account?.peopleWithIncome
        },
        [DC_SLIDES.EDUCATION_LEVEL.ID]: {
          educationLevel: account?.educationLevel
        },

        // LA TUA OCCUPAZIONE
        ...(!!account?.jobData
          ? {
              [DC_SLIDES.EMPLOYMENT_STATUS.ID]: {
                employmentStatus: account.jobData.employmentStatus || undefined
              },
              [DC_SLIDES.VAT_NUMBER.ID]: {
                vatNumber: account.vatNumber || undefined
              },
              [DC_SLIDES.HIRED_ON_YEAR.ID]: {
                hiredOnYear: account.jobData.hiredOnYear || undefined,
                employerName: account.jobData.employerName || undefined
              },
              [DC_SLIDES.HIRED_ON_MONTH.ID]: {
                hiredOnMonth: account.jobData.hiredOnMonth || undefined
              },
              [DC_SLIDES.COMPANY_INDUSTRY.ID]: {
                companyIndustry: account.jobData.companyIndustry || undefined
              },
              [DC_SLIDES.COMPANY_SIZE.ID]: {
                companySize: account.jobData.companySize || undefined
              },
              [DC_SLIDES.JOB_ROLE.ID]: {
                jobRole: account.jobData.jobRole || undefined
              }
            }
          : {}),

        // LE TUE ENTRATE ED USCITE
        ...(!!account?.financialData
          ? {
            [DC_SLIDES.LIVING_SITUATION.ID]: {
              // @ts-ignore
              homeStatus: account.homeStatus || undefined,
              rentMortgageAmount: account.rentMortgageAmount || undefined,
              totalMonthlyInstallmentsAmountEur: account.financialData.totalMonthlyInstallmentsAmountEur || undefined
            },
              [DC_SLIDES.PREVIOUS_LOANS.ID]: {
                // @ts-ignore
                previousLoans: account.financialData.previousLoans || undefined
              },
              [DC_SLIDES.LATE_PAYMENTS.ID]: {
                latePayments: account.financialData.latePayments || undefined
              },
              [DC_SLIDES.MONTHLY_INCOME.ID]: {
                netMonthlySalaryEur: account.financialData.netMonthlySalaryEur || undefined,
                salaryMonthsCnt: account.financialData.salaryMonthsCnt || undefined,
                grossYearlySalaryEur: account.financialData.grossYearlySalaryEur || undefined
              },
              [DC_SLIDES.INCOME_SOURCES.ID]: {
                hasOtherIncome:
                  account.financialData.hasOtherIncome === undefined
                    ? undefined
                    : account.financialData.hasOtherIncome
                    ? 'Y'
                    : 'N',
                otherNetMonthlyIncomeEur: account.financialData.otherNetMonthlyIncomeEur || undefined,
                descriptionSourcesIncome: account.financialData.descriptionSourcesIncome || undefined
              }
            }
          : {}),

        [DC_SLIDES.COUPON_CODE.ID]: {
          coupon: account?.promoCode
        }
      }
    },
    stepCheckpoint: payload.step_checkpoint,
    otpVerified: !!payload.otp_verified,
    kyc: {
      ...payload.kyc,
      documentType: payload.kyc ? payload.kyc.kyc_document_type : undefined,
      incomeDocumentationType: payload.kyc ? payload.kyc.kyc_income_documentation_type : undefined,
      bankStatement: undefined,
      paychecks: undefined,
      voucherData: undefined
    },
    kycMode: payload.kyc ? payload.kyc.kyc_mode : undefined,
    kyc_data: payload.kyc && payload.kyc.kyc_data ? payload.kyc.kyc_data : {},
    iban: payload.kyc ? payload.kyc.iban : undefined,
    isIbanChecked: payload.kyc? payload.kyc.iban_check : false,
    openBanking: {
      openBankingEnabled: !!account?.openBankingEnabled,
      openBankingCode: account?.openBankingCode
    },
    advData: payload.questionnaire,
    documents: {
      annualReport: {
        documentsGroupedByYear,
        hasCurrentYearReport
      }
    }
  };

  return parsed;
};