export enum CbsProcedureStatus {
  STARTING_OFFER_GENERATED = 'STARTING_OFFER_GENERATED',
  OFFERS_GENERATED = 'OFFERS_GENERATED',
  OFFER_SELECTED = 'OFFER_SELECTED',
  KYC_EVALUATION = 'KYC_EVALUATION',
  UW_EVALUATION = 'UW_EVALUATION',
  DOCS_REQUIRED_BY_UW = 'DOCS_REQUIRED_BY_UW',
  ADV_EVALUATION = 'ADV_EVALUATION',
  ADV_REINFORCED_EVALUATION = 'ADV_REINFORCED_EVALUATION',
  DOCS_REQUIRED_BY_AML = 'DOCS_REQUIRED_BY_AML',
  SIGNATURE_REQUIRED = 'SIGNATURE_REQUIRED',
  DECLINED_BY_RULES = 'DECLINED_BY_RULES',
  DECLINED_BY_UW = 'DECLINED_BY_UW',
  DECLINED_BY_AML = 'DECLINED_BY_AML',
  CONTRACT_SIGNED = 'CONTRACT_SIGNED',
  CANCELLED = 'CANCELLED',
  LOAN_DISBURSED = 'LOAN_DISBURSED',
  IN_LIFE = 'IN_LIFE',
  PRE_EARLY_COLLECTION = 'PRE_EARLY_COLLECTION',
  EARLY_COLLECTION = 'EARLY_COLLECTION',
  SOFT_COLLECTION = 'SOFT_COLLECTION',
  STRONG_COLLECTION = 'STRONG_COLLECTION',
  POST_DBT_1st_ATTEMPT = 'POST_DBT_1st_ATTEMPT',
  POST_DBT_2nd_ATTEMPT = 'POST_DBT_2nd_ATTEMPT',
  POST_DBT_3rd_ATTEMPT = 'POST_DBT_3rd_ATTEMPT',
  WRITE_OFF_PROPOSAL = 'WRITE-OFF_PROPOSAL',
  PAID_OF = 'PAID-OFF',

  // Withdrawal
  WITHDRAWAL_REQUESTED = "WITHDRAWAL_REQUESTED",
  WITHDRAWAL_DONE = "WITHDRAWAL_DONE",
  WITHDRAWAL_COMPLETED = "WITHDRAWAL_COMPLETED",

  // Early term
  EARLY_TERM_REQUESTED = "EARLY_TERM_REQUESTED",
  TOTAL_EARLY_TERM_DONE = "TOTAL_EARLY_TERM_DONE",

  // KO Status
  OFFERS_EXPIRED = 'OFFERS_EXPIRED',
  OFFER_SELECTED_EXPIRED = 'OFFER_SELECTED_EXPIRED',
  SIGNATURE_REQUIRED_EXPIRED = 'SIGNATURE_REQUIRED_EXPIRED',
  DOCS_REQUIRED_BY_UW_EXPIRED = 'DOCS_REQUIRED_BY_UW_EXPIRED',
  DOCS_REQUIRED_BY_AML_EXPIRED = 'DOCS_REQUIRED_BY_AML_EXPIRED',
  KYC_EXPIRED = 'KYC_EXPIRED'
}
