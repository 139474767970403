export enum PageSections {
  PROGRESS = 'progress',
  DATA_COLLECTION = 'data_collection',
  REDUCED_DATA_COLLECTION = 'data_collection_short',
  NEGOTIATION = 'negotiation',
  KYC = 'kyc',
  ADV = 'adv',
  PERSONAL_AREA = 'personal_area',
  PRESTITI = 'prestiti',
  OPEN_BANKING = 'open_banking',
  ASSISTANCE = 'assistance',
  OTP = 'otp'
}
