import { Location, Params } from 'react-router';

// Enums
import { PageSections } from '../../enums/data-layer/page-sections';

// Constants
import { StepNumbers } from '../../constants/step-numbers';

const getPagePath = (location: Location): string => {
  // slice() method will remove '/' character
  return `${location?.pathname}${location?.hash}`.slice(1);
};

const getPageName = (hasHash: boolean, location: Location, params: Params): string => {
  if (hasHash) {
    // slice() method will remove '#' character
    return location.hash.slice(1) || 'landing';
  } else {
    return params['*'] ? params['*'].replace('-', '_') : 'landing';
  }
};

const getPreviousPagePath = (previousLocation?: Location): string => {
  return previousLocation ? getPagePath(previousLocation) : 'entry';
};

const getStepNumber = (pageSection: PageSections, stepNumberDataCollection: number, pagePath: string): number => {
  switch (pageSection) {
    case PageSections.DATA_COLLECTION:
      return stepNumberDataCollection;
    case PageSections.NEGOTIATION:
    case PageSections.KYC:
    case PageSections.ADV:
    case PageSections.PERSONAL_AREA: {
      const key = pagePath as keyof typeof StepNumbers;
      return StepNumbers[key];
    }
    default:
      return 0;
  }
};

// TODO: Revise -> insert custom name for second part after ':' character
const getStepName = (pageSection: PageSections, hasHash: boolean, location: Location, params: Params): string => {
  return `${pageSection}:${getPageName(hasHash, location, params)}`;
};

const dataLayerUtil = {
  getPagePath,
  getPageName,
  getPreviousPagePath,
  getStepNumber,
  getStepName
};

export default dataLayerUtil;