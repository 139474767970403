import { createAction } from '@reduxjs/toolkit';
import { NotificationType } from '../../enums/notification-type';

interface InputAction {
  isOpen: boolean;
  type?: NotificationType;
  title?: string;
  message?: string;
}

export const notificationPopupAction = createAction('notificationPopup', (notificationPopup: InputAction) => {

      return {
        payload: {
          ...notificationPopup
        }
      };
    }
  )
;
