import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { ReactComponent as conte_icon_popup_generic_error } from '../../assets/resources/images/conte_icon_popup_generic_error.svg';
import { closeErrorModalAction } from '../../store/actions/set-error-dialog.action';
import { selectErrorModal } from '../../store/selectors';
import Button from '../../UI/Button/Button';
import Modal from '../../UI/Modal/Modal';

function ErrorModal() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    title,
    subtitle,
    isOpen,
    isDismissable,
    confirmButton,
    onConfirmNavigateTo,
    handleConfirm,
    cancelButton,
    hasImage
  } = useSelector(selectErrorModal);

  const onClose = React.useCallback(() => dispatch(closeErrorModalAction()), [dispatch]);
  const onConfirmHandler = React.useCallback(() => {
    navigate({ pathname: onConfirmNavigateTo });
    onClose();
  }, [onClose, onConfirmNavigateTo, navigate]);

  return (
    <Modal.Container show={isOpen} onCloseHandler={onClose} isDismissable={isDismissable && !handleConfirm}>
      <span data-test="error-modal" data-test-value="visible" />
      {hasImage && <Modal.Image src={conte_icon_popup_generic_error} />}
      <Modal.Title>{title}</Modal.Title>
      <Modal.Subtitle>{subtitle}</Modal.Subtitle>
      <div className="mt-4">
        {confirmButton && handleConfirm && (
          <Button
            color="primary"
            fluid
            onClick={() => {
              dispatch(closeErrorModalAction());
              dispatch(handleConfirm());
            }}>
            <b>{confirmButton}</b>
          </Button>
        )}
        {confirmButton && onConfirmNavigateTo && (
          <Button color="primary" fluid onClick={onConfirmHandler}>
            <b>{confirmButton}</b>
          </Button>
        )}
        {cancelButton && (
          <Button color="secondary" fluid onClick={onClose}>
            <b>{cancelButton}</b>
          </Button>
        )}
      </div>
    </Modal.Container>
  );
}

export default ErrorModal;
