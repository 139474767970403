import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { assign, omit } from 'lodash';
import { DataCollectionSlidesValues, DC_SLIDES } from '../constants/data-collection-slides';
import { PERSONAL_AREA_ROUTES } from '../constants/personal-area-routes';
import { StepCheckpoints } from '../constants/step-checkpoints';
import { CrmKYC, CrmLoanIntent, CrmOffer } from '../types/exelab-crm/account';
import { DocsRequiredReadDTO } from '../types/docs-required';
import { LoanStatusReadDTO } from '../types/loan-status';
import { LoanResponse, Offer } from '../types/procedure';
import {
  applyKycDocumentsAction,
  applyStatePrestitiOnlineAction,
  getHubspotAndCbsStatusAction
} from './actions/apply-state.action';
import {
  downloadContractAction,
  downloadDocumentByEntityId,
  downloadDocumentById,
  downloadDocumentByRole,
  downloadSecciAction,
  downloadSecciPreviewAction
} from './actions/download.action';
import { enableOpenBanking } from './actions/open-banking.action';
import { applyPersonalAreaStatusAction } from './actions/personal-area.action';
import { redirectToTinkAction } from './actions/redirect-to-tink.action';
import { updateCrmAccountAction, updateCrmStepCheckpointAction } from './actions/set-account-crm.action';
import { updateAdvDataAction } from './actions/set-adv-check.action';
import { closeErrorModalAction, openErrorModalAction } from './actions/set-error-dialog.action';
import { requestHubSpotAction, submitBookingCallAction } from './actions/set-hubspot.action';
import { setIbanAction, setIbanValidAction } from './actions/set-iban.action';
import {
  failKycVideoSelfieAction,
  sendBankStatementAction,
  sendKycDocumentsAction,
  sendKycIncomeDocumentation,
  sendPaychecksAction,
  sendRequiredDocumentsAction,
  setRequireDocumentsAction
} from './actions/set-kyc-checks.action';
import { disableLoadingAction, enableLoadingAction } from './actions/set-loading.action';
import { setLoanIntentAction } from './actions/set-loan-intent.action';
import { logoutAction } from './actions/set-logout.action';
import { generateNonce, verifyNonce } from './actions/set-nonce.action';
import {
  calculateOffersAction,
  generateOffersAction,
  saveHubspotDealAction
} from './actions/set-offers.action';
import { requestOTPAction, setOtpStatus, verifyOTPAction } from './actions/set-otp.action';
import { preSelectOfferCRMAction, updateHubspotDealAction } from './actions/set-preselect-offer-crm.action';
import { saveCPIBeneficiaryAction } from './actions/save-cpi-beneficiary.actions';
import { selectOfferAction } from './actions/set-select-offer.action';
import {
  closeSidebarAction,
  closeSidebarPAAction,
  openSidebarAction,
  openSidebarPAAction
} from './actions/set-sidebar.action';
import {
  calculateNewStartingOfferAction,
  generateStartingOfferAction,
  setStartingOfferSelectedAction
} from './actions/set-starting-offer.action';
import { generateVoucherAction, getVoucherAction } from './actions/set-voucher.action';
import { privacyConsentsPrestitiOnlineAction } from './actions/submit.action';
import { disableLoadingReducer, enableLoadingReducer } from './reducers/loading-reducer';
import { DirectChannels, ExternalComparatorsChannels } from '../enums/channels';
import { closeCustomerServiceAction, openCustomerServiceAction } from './actions/customer-service.action';
import { PageSections } from '../enums/data-layer/page-sections';
import { savePreviousLocationAction } from './actions/save-previous-location.action';
import { Location } from 'react-router';
import { GeneralStatus } from '../enums/offer/status';
import { resetApiErrorAction } from './actions/reset-state.action';
import { emailAction } from './actions/email.action';
import { NotificationType } from '../enums/notification-type';
import { notificationPopupAction } from './actions/notification-popup.action';
import { Sources } from '../enums/sources';
import { setTrackingAction } from './actions/set-tracking.action';
import { setChannelAction } from './actions/set-channel.action';
import { setLoadingBarAction } from './actions/set-loading-bar.action';
import { calculateLeadsOffer } from './actions/leads-offer.action';
import { setIncomeSourcesReducer, setMonthlyIncomeReducer } from './reducers/incomes.reducer';
import { setAddressReducer } from './reducers/address.reducer';
import { setFamilyReducer, setMaritalStatusReducer } from './reducers/famlity.reducer';
import { setLivingSituationReducer } from './reducers/living-situation.reducer';
import { setCouponReducer } from './reducers/coupon.reducer';
import { setContactInfoReducer } from './reducers/contact-info.reducer';
import { setFiscalCodeReducer } from './reducers/fiscal-code.reducer';
import { setPersonalInfoReducer } from './reducers/personal-info.reducer';
import { setEmploymentStatusReducer } from './reducers/employment.reducer';
import {
  setCompanyIndustryReducer,
  setCompanySizeReducer,
  setVatNumberReducer
} from './reducers/company.reducer';
import { setPrivacyConsentsReducer, setPrivacyPolicyReducer } from './reducers/privacy.reducer';
import { setLoanIntentReducer } from './reducers/loan-intent.reducer';
import { parseAccount } from '../utils/orchestrator-transform';
import { DocumentRoles } from '../enums/cbs/document-roles';
import { setInsuranceQuestionnaireReducer, setInsuranceReducer } from './reducers/insurance.reducer';
import { YesNo } from '../enums/yes-no';
import { CpiBeneficiary } from '../types/exelab-crm/cpi';
import { OfferActionTypeEnum } from '../enums/offer-action-type';

// const defaultStep = DC_SLIDES.CLIENT_TYPE; // TODO disabilitata temporaneamente - CTLFE-201
const defaultStep: DataCollectionSlidesValues = DC_SLIDES.PROGRESS_SUMMARY_1;

export type MainSliceState = {
  init: boolean;
  isExternalComparatorInitialized?: boolean;
  isLeadsJourney?: boolean;
  isForeignCustomer?: boolean;
  apiError?: boolean;
  contactId?: string;
  applicationId?: string;
  procedureId?: string;
  cbsProcedureStatus?: string;
  channel?: DirectChannels | ExternalComparatorsChannels | string;
  latestChannel?: DirectChannels | ExternalComparatorsChannels | string;

  loanIntent?: CrmLoanIntent;

  stepCheckpoint?: StepCheckpoints;

  otpRequestCount: number;
  otpVerified: boolean;

  dataCollection: {
    reduced?: {
      activeStep: number;
    };
    prevStep?: DataCollectionSlidesValues;
    step: DataCollectionSlidesValues;
    progressPercentage: number;
    slides: Partial<Record<DataCollectionSlidesValues['ID'], any>>;
  };

  startingOffer: {
    count?: number;
    lastCalculatedGeneralStatus?: GeneralStatus;
    generalStatus?: LoanResponse['generalStatus'];
    offerList: Offer[];
    selectedOffer?: Offer;
    isSelectedByUser: boolean;
  };
  offers: {
    generalStatus?: LoanResponse['generalStatus'];
    offerList?: Array<Offer>;
    preSelectedOffer?: Partial<CrmOffer>;
    selectedOffer?: Partial<CrmOffer>;
  };

  iban?: string;
  ibanValid?: boolean;
  ibanAttempt: number;
  isIbanChecked: boolean;

  kycMode?: CrmKYC['kyc_mode'];
  kyc_data: CrmKYC['kyc_data'];
  kyc: {
    //#region temporary (not persisted)
    voucherCode?: string;
    voucherData: any;

    documentType: any;
    incomeDocumentationType: any;

    paychecks: any;
    bankStatement: any;
    //#endregion
  };

  openBanking: {
    openBankingEnabled: boolean;
    openBankingCode?: string;
  };

  incomeDocumentation: {
    uploadPageDescription?: string;
    documentsRequired: Array<{ role: string; label: string }>;
  };

  advData: any;

  personalArea: {
    route?: PERSONAL_AREA_ROUTES;
    loanData?: LoanStatusReadDTO;
    documentsRequired?: DocsRequiredReadDTO;
  };

  loading: {
    isLoading: boolean;
    text: string;
  };

  loadingBar: {
    percentage: number;
  };

  customerService: {
    isOpen: boolean;
  };

  sidebar: {
    isOpen: boolean;
  };

  sidebarPA: {
    isOpen: boolean;
  };

  errorModal: {
    isOpen: boolean;
    isDismissable: boolean;
    title: string;
    subtitle: string;
    confirmButton?: string;
    handleConfirm?: any;
    onConfirmNavigateTo?: string;
    cancelButton?: string;
    hasImage?: boolean;
  };

  notificationPopup: {
    isOpen: boolean;
    title?: string;
    message?: string;
    type?: NotificationType;
  };

  loadingPopup: {
    isOpen: boolean;
    title?: string;
    message?: string;
    type?: NotificationType;
  };

  nonce: string;

  dataLayer: {
    previousPageSection?: PageSections;
    previousLocation?: Location;
  };

  tracking?: {
    source: Sources;
    urlSource?: string;
    utm?: any;
    channelForm?: any;
  };

  otp: {
    isBack: boolean;
  };

  documents: {
    secciId?: string;
    availableDocuments?: Array<DocumentRoles>;
    annualReport: {
      documentsGroupedByYear: Array<Record<string, string | number>>;
      hasCurrentYearReport: boolean;
    };
  };

  insurance: {
    hasAvailableProduct: boolean;
    isEnabled: boolean;
    preContractualDocuments?: {
      viewDate: string;
    };
    questionnaire?: {
      viewDate: string;
      submitDate?: string;
      question1?: boolean;
      question2?: boolean;
      question3?: boolean;
      question4?: boolean;
      question5?: boolean;
    };
    beneficiary?: CpiBeneficiary
  };
};

export const initialState: MainSliceState = {
  init: false,

  contactId: undefined,
  applicationId: undefined,
  procedureId: undefined,
  cbsProcedureStatus: undefined,
  channel: DirectChannels.CONTE,

  stepCheckpoint: undefined,

  otpRequestCount: 0,
  otpVerified: false,

  loanIntent: undefined,

  dataCollection: {
    reduced: {
      activeStep: 0
    },
    prevStep: undefined,
    step: defaultStep,
    progressPercentage: 0,
    slides: {
      // INFORMAZIONI PERSONALI
      [DC_SLIDES.CLIENT_TYPE.ID]: {},

      [DC_SLIDES.PROGRESS_SUMMARY_1.ID]: undefined,
      [DC_SLIDES.PERSONAL_INFO_1.ID]: {},
      [DC_SLIDES.PERSONAL_INFO_2.ID]: {},
      [DC_SLIDES.FISCAL_ID.ID]: {},
      [DC_SLIDES.CONTACT_INFO.ID]: {},
      [DC_SLIDES.PRIVACY_CONSENTS.ID]: {},
      [DC_SLIDES.ADDRESS.ID]: {},
      [DC_SLIDES.MARITAL_STATUS.ID]: {},
      [DC_SLIDES.NUM_HOUSEHOLD_MEMBERS.ID]: {},
      [DC_SLIDES.PEOPLE_WITH_INCOME.ID]: {},
      [DC_SLIDES.EDUCATION_LEVEL.ID]: {},

      // LA TUA OCCUPAZIONE
      [DC_SLIDES.PROGRESS_SUMMARY_2.ID]: undefined,
      [DC_SLIDES.EMPLOYMENT_STATUS.ID]: {},
      [DC_SLIDES.VAT_NUMBER.ID]: {},
      [DC_SLIDES.HIRED_ON_YEAR.ID]: {},
      [DC_SLIDES.HIRED_ON_MONTH.ID]: {},
      [DC_SLIDES.COMPANY_INDUSTRY.ID]: {},
      [DC_SLIDES.COMPANY_SIZE.ID]: {},
      [DC_SLIDES.JOB_ROLE.ID]: {},

      // LE TUE ENTRATE ED USCITE
      [DC_SLIDES.PROGRESS_SUMMARY_3.ID]: undefined,
      [DC_SLIDES.LIVING_SITUATION.ID]: {},
      [DC_SLIDES.PREVIOUS_LOANS.ID]: {},
      [DC_SLIDES.LATE_PAYMENTS.ID]: {},
      [DC_SLIDES.MONTHLY_INCOME.ID]: {},
      [DC_SLIDES.INCOME_SOURCES.ID]: {},
      [DC_SLIDES.COUPON_CODE.ID]: {},

      // EXTRA
      [DC_SLIDES.PROGRESS_SUMMARY_4.ID]: undefined
    }
  },

  startingOffer: {
    offerList: [],
    generalStatus: undefined,
    selectedOffer: undefined,
    isSelectedByUser: false
  },
  offers: {
    offerList: [],
    generalStatus: undefined,
    preSelectedOffer: undefined,
    selectedOffer: undefined
  },

  iban: undefined,
  ibanValid: undefined,
  ibanAttempt: 0,
  isIbanChecked: false,

  kycMode: undefined,
  kyc_data: {
    kycCompleted: false,
    videoSelfieFailed: false,
    devicePayload: undefined
  },
  kyc: {
    //#region temporary (not persisted)
    voucherCode: undefined,
    voucherData: undefined,

    documentType: undefined,
    incomeDocumentationType: undefined,

    paychecks: undefined,
    bankStatement: undefined
    //#endregion
  },

  openBanking: {
    openBankingEnabled: false,
    openBankingCode: undefined
  },

  incomeDocumentation: {
    uploadPageDescription: undefined,
    documentsRequired: []
  },

  advData: undefined,

  personalArea: {
    route: undefined,
    loanData: undefined,
    documentsRequired: undefined
  },

  loading: {
    isLoading: false,
    text: 'Caricamento in corso...'
  },

  loadingBar: {
    percentage: 0
  },

  customerService: {
    isOpen: false
  },

  sidebar: {
    isOpen: false
  },

  sidebarPA: {
    isOpen: false
  },

  errorModal: {
    isOpen: false,
    isDismissable: true,
    title: 'Ops! Qualcosa è andato storto.',
    subtitle: 'Si è verificato un errore. Riprova.',
    confirmButton: '',
    handleConfirm: null,
    onConfirmNavigateTo: '',
    cancelButton: '',
    hasImage: true
  },

  notificationPopup: {
    isOpen: false
  },

  loadingPopup: {
    isOpen: false
  },

  nonce: '',

  dataLayer: {
    previousPageSection: undefined,
    previousLocation: undefined
  },

  otp: {
    isBack: false
  },

  documents: {
    availableDocuments: [],
    annualReport: {
      documentsGroupedByYear: [],
      hasCurrentYearReport: false
    }
  },

  insurance: {
    hasAvailableProduct: false,
    isEnabled: false
  }
};

const errorMessageToReplace = ['Network Error'];
const errorReducer = (state: MainSliceState, action: PayloadAction<any>) => {
  const { title, subtitle, hasImage } = action.payload || {};
  console.error('StoreError ~', action);
  if ((action as any).error && (action as any).error.stack) {
    console.error('StoreError ~', (action as any).error.stack);
  }

  state.errorModal.isOpen = true;
  state.errorModal.title = title ?? "Ops! Qualcosa è andato storto.";
  state.errorModal.subtitle = errorMessageToReplace.includes(action.payload)
    ? `Ti chiediamo di riprovare! (${action.payload})`
    : subtitle ?? action.payload;
  state.errorModal.hasImage = hasImage ?? true;

  if (StepCheckpoints.STARTING_OFFER_TO_BE_DISPLAYED === state.stepCheckpoint) {
    state.apiError = true;
  }

  disableLoadingReducer(state);
};

const handleLeadsError = (state: MainSliceState, action: PayloadAction<any>) => {
  state.errorModal.isOpen = true;
  state.errorModal.subtitle = errorMessageToReplace.includes(action.payload)
    ? `Ti chiediamo di riprovare! (${action.payload})`
    : action.payload;

  state.errorModal.confirmButton = 'Ricalcola offerta';
  state.errorModal.handleConfirm = calculateLeadsOffer;

  state.loadingPopup = {
    isOpen: false
  };
};

export const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    setContactInfo: setContactInfoReducer,
    setPrivacyPolicy: setPrivacyPolicyReducer,
    setContactInfoAndPrivacyPolicy: (state: MainSliceState, action: PayloadAction<any>) => {
      setContactInfoReducer(state, action);
      setPrivacyPolicyReducer(state, action);
    },
    setLoanIntentAndPrivacyPolicy: (state: MainSliceState, action: PayloadAction<any>) => {
      setLoanIntentReducer(state, action);
      setPrivacyPolicyReducer(state, action);
    },
    setPersonalDetails: setPersonalInfoReducer,
    setBirthInfo: (state: MainSliceState, action: PayloadAction<any>) => {
      state.dataCollection.slides.personal_info_2 = { ...action.payload.personal_info_2 };
    },
    setEmploymentStatus: setEmploymentStatusReducer,
    setVatNumber: setVatNumberReducer,
    setCompanyIndustry: setCompanyIndustryReducer,
    setCompanySize: setCompanySizeReducer,
    setCompanyHiring: (state: MainSliceState, action: PayloadAction<any>) => {
      state.dataCollection.slides.hired_on_year = { ...action.payload.hired_on_year };
      state.dataCollection.slides.hired_on_month = { ...action.payload.hired_on_month };
    },
    setMonthlyIncome: setMonthlyIncomeReducer,
    setIncomeSources: setIncomeSourcesReducer,
    setAddress: setAddressReducer,
    setMaritalStatus: setMaritalStatusReducer,
    setFamily: setFamilyReducer,
    setLivingSituation: setLivingSituationReducer,
    setCouponCode: setCouponReducer,
    resetOffersGeneralStatus: (state: MainSliceState) => {
      state.offers.generalStatus = undefined;
    },
    setLoanIntent: (state: MainSliceState, action: PayloadAction<any>) => {
      state.init = true;
      state.loanIntent = action.payload;
    },
    setLeadsJourney: (state: MainSliceState) => {
      state.isLeadsJourney = true;
      state.ibanValid = false;
    },
    setReducedDataCollection: (state: MainSliceState, action: PayloadAction<any>) => {
      state.dataCollection.reduced = action.payload.reduced;
    },
    setLeadsClient: (state: MainSliceState, action: PayloadAction<any>) => {
      state.channel = action.payload.channel;
      state.isForeignCustomer = action.payload.isForeignCustomer;
      state.dataCollection.slides.personal_info_1.firstName = action.payload.firstName;
      state.dataCollection.slides.personal_info_1.lastName = action.payload.lastName;
      state.dataCollection.slides.fiscal_id.code = action.payload.code;
      state.dataCollection.slides.contact_info.email = action.payload.email;
      state.loanIntent = {
        term: action.payload.term,
        amount: action.payload.amount
      };
    },
    setPersonalInfo: (state: MainSliceState, action: PayloadAction<any>) => {
      state.dataCollection.slides.personal_info_1 = { ...action.payload.personal_info_1 };
      state.dataCollection.slides.personal_info_2 = { ...action.payload.personal_info_2 };
      state.dataCollection.slides.fiscal_id = { ...action.payload.fiscal_id };
      state.dataCollection.slides.contact_info = { ...action.payload.contact_info };
      state.dataCollection.slides.address = { ...action.payload.address };
      state.dataCollection.slides.education_level = { ...action.payload.education_level };
    },
    setFiscalCode: setFiscalCodeReducer,
    setEmployment: (state: MainSliceState, action: PayloadAction<any>) => {
      state.dataCollection.slides.employment_status = { ...action.payload.employment_status };
      state.dataCollection.slides.vat_number = { ...action.payload.vat_number };
      state.dataCollection.slides.company_industry = { ...action.payload.company_industry };
      state.dataCollection.slides.company_size = { ...action.payload.company_size };
      state.dataCollection.slides.job_role = { ...action.payload.job_role };
      state.dataCollection.slides.hired_on_year = { ...action.payload.hired_on_year };
      state.dataCollection.slides.hired_on_month = { ...action.payload.hired_on_month };
      state.dataCollection.slides.monthly_income = { ...action.payload.monthly_income };
    },
    setIncomeAndExpenses: (state: MainSliceState, action: PayloadAction<any>) => {
      state.dataCollection.slides.marital_status = { ...action.payload.marital_status };
      state.dataCollection.slides.num_household_members = { ...action.payload.num_household_members };
      state.dataCollection.slides.people_with_income = { ...action.payload.people_with_income };
      state.dataCollection.slides.late_payments = { ...action.payload.late_payments };
      state.dataCollection.slides.living_situation = { ...action.payload.living_situation };
      state.dataCollection.slides.income_sources = { ...action.payload.income_sources };
      state.dataCollection.slides.coupon_code = { ...action.payload.coupon_code };
      state.dataCollection.slides.previous_loans = { ...action.payload.previous_loans };
    },
    setPrivacyConsents: setPrivacyConsentsReducer,
    setCoupon: (state: MainSliceState, action: PayloadAction<any>) => {
      state.dataCollection.slides.coupon_code = { ...action.payload.coupon_code };
    },
    setLoadingPopup: (state: MainSliceState, action: PayloadAction<any>) => {
      state.loadingPopup = action.payload;
    },
    setForeignBirthPlace: (state: MainSliceState, action: PayloadAction<any>) => {
      state.dataCollection.slides.personal_info_2.birthCountry = action.payload.birthCountry;
      state.dataCollection.slides.personal_info_2.birthCity = action.payload.birthCity;
    },
    setPreselectedOffer: (state: MainSliceState, action: PayloadAction<any>) => {
      state.offers.preSelectedOffer = action.payload;
    },
    setInsurance: setInsuranceReducer,
    setInsuranceQuestionnaire: setInsuranceQuestionnaireReducer,
  },
  extraReducers: builder => {
    //#region init
    builder.addCase(setLoanIntentAction, (state, { payload }) => {
      state.init = true;
      state.loanIntent = payload;
    });

    builder.addCase(setTrackingAction, (state, { payload }) => {
      state.tracking = payload.tracking;
    });

    builder.addCase(setLoadingBarAction, (state, { payload }) => {
      state.loadingBar = payload.loadingBar;
    });

    builder.addCase(setChannelAction, (state, { payload }) => {
      state.channel = payload.channel;
    });

    builder.addCase(setOtpStatus, (state, { payload }) => {
      state.otp = payload.otp;
    });
    //#endregion init

    //#region Loading
    builder.addCase(enableLoadingAction, (state, { payload }) => {
      state.loading.isLoading = true;
      state.loading.text = payload || initialState.loading.text;
    });
    builder.addCase(disableLoadingAction, state => {
      state.loading.isLoading = false;
      state.loading.text = initialState.loading.text;
    });
    //#endregion


    // Reset state
    builder.addCase(resetApiErrorAction, (state: MainSliceState) => {
      state.apiError = undefined;
    });

    builder.addCase(updateCrmStepCheckpointAction.fulfilled, (state, { payload }) => {
      state.stepCheckpoint = payload;
    });

    builder.addCase(updateCrmAccountAction.pending, enableLoadingReducer);
    builder.addCase(updateCrmAccountAction.fulfilled, (state, { payload }) => {
      state.loading.isLoading = false;
      state.stepCheckpoint = payload.stepCheckpoint ?? state.stepCheckpoint;
    });
    builder.addCase(updateCrmAccountAction.rejected, errorReducer);

    //#region Error Modal
    builder.addCase(openErrorModalAction, (state, { payload }) => {
      assign(state.errorModal, initialState.errorModal, payload);
      state.errorModal.isOpen = true;
    });
    builder.addCase(closeErrorModalAction, state => {
      assign(state.errorModal, initialState.errorModal);
      state.errorModal.isOpen = false;
    });
    //#endregion

    //#region Sidebar
    builder.addCase(openSidebarAction, state => {
      state.sidebar.isOpen = true;
    });
    builder.addCase(closeSidebarAction, state => {
      state.sidebar.isOpen = false;
    });

    builder.addCase(openSidebarPAAction, state => {
      state.sidebarPA.isOpen = true;
    });
    builder.addCase(closeSidebarPAAction, state => {
      state.sidebarPA.isOpen = false;
    });
    //#endregion

    //#region CustomerService
    builder.addCase(openCustomerServiceAction, state => {
      state.customerService.isOpen = true;
    });
    builder.addCase(closeCustomerServiceAction, state => {
      state.customerService.isOpen = false;
    });
    //#endregion

    // Notification Popup
    builder.addCase(notificationPopupAction, (state, { payload }) => {
      state.notificationPopup.isOpen = payload.isOpen;
      state.notificationPopup.title = payload.title;
      state.notificationPopup.message = payload.message;
      state.notificationPopup.type = payload.type;
    });

    //#region Starting Offer
    builder.addCase(generateStartingOfferAction.rejected, errorReducer);
    builder.addCase(generateStartingOfferAction.fulfilled, (state, { payload }) => {
      state.applicationId = payload.applicationId;
      state.stepCheckpoint = payload.stepCheckpoint;
      state.openBanking.openBankingCode = payload.openBankingToken;
      state.openBanking.openBankingEnabled = payload.openBankingConsent;
      state.kyc_data.devicePayload = payload.devicePayload;

      state.startingOffer.generalStatus = payload.loanResponse.generalStatus;
      state.startingOffer.offerList = payload.loanResponse.offers!;
      if (payload.loanResponse.offers) {
        state.startingOffer.selectedOffer = payload.loanResponse.offers[0];
      }

      disableLoadingReducer(state);
    });

    builder.addCase(calculateNewStartingOfferAction.pending, enableLoadingReducer);
    builder.addCase(calculateNewStartingOfferAction.rejected, errorReducer);
    builder.addCase(calculateNewStartingOfferAction.fulfilled, (state, { payload }) => {
      state.kyc_data.devicePayload = payload.devicePayload;
      state.startingOffer.lastCalculatedGeneralStatus = payload.loanResponse.generalStatus;
      state.startingOffer.count = state.startingOffer.count ? state.startingOffer.count + 1 : 1;
      if (payload.loanResponse.generalStatus !== GeneralStatus.KO) {
        state.startingOffer.offerList = payload.loanResponse.offers!;
        state.startingOffer.selectedOffer = payload.loanResponse.offers![0];
      }
    });

    builder.addCase(setStartingOfferSelectedAction, (state, { payload }) => {
      state.startingOffer.selectedOffer = payload.selectedOffer;
      state.startingOffer.isSelectedByUser = true;
      state.loanIntent!.amount = payload.selectedOffer.amount;
      state.loanIntent!.term = payload.selectedOffer.term;
    });
    //#endregion

    //#region Offers
    builder.addCase(generateOffersAction.pending, enableLoadingReducer);
    builder.addCase(generateOffersAction.rejected, errorReducer);
    builder.addCase(generateOffersAction.fulfilled, (state, { payload }) => {
      state.applicationId = payload.applicationId;
      state.procedureId = payload.procedureId;
      state.stepCheckpoint = payload.stepCheckpoint;
      state.offers.generalStatus = payload.loanResponse.generalStatus;

      if (payload.loanResponse.generalStatus !== GeneralStatus.KO) {
        state.offers.offerList = payload.loanResponse.offers!.sort(o => (o.recommended ? -1 : 1));
        state.offers.preSelectedOffer = payload.selected;
      }

      disableLoadingReducer(state);
    });

    builder.addCase(calculateOffersAction.pending, enableLoadingReducer);
    builder.addCase(calculateOffersAction.rejected, errorReducer);
    builder.addCase(calculateOffersAction.fulfilled, (state, { payload }) => {
      state.procedureId = payload.procedureId;
      state.applicationId = payload.applicationId ?? state.applicationId;
      state.kyc_data.devicePayload = payload.devicePayload ?? state.kyc_data.devicePayload;
      state.stepCheckpoint = payload.stepCheckpoint ?? state.stepCheckpoint;
      state.offers.generalStatus = payload.generalStatus;
      state.offers.offerList = payload.loanResponse
        .filter(loanResponse => loanResponse.generalStatus !== GeneralStatus.KO)
        .flatMap(loanResponse => loanResponse.offers ?? []);
      state.offers.preSelectedOffer = payload.selected ?? state.offers.preSelectedOffer;
      disableLoadingReducer(state);
      state.insurance.isEnabled = !!payload.hasInsurance;
      state.insurance.hasAvailableProduct = !!payload.hasCpiProduct;
      state.documents.secciId = payload.secciId ?? state.documents.secciId;
    });

    //#endregion

    //#region Pre-Select Offer
    builder.addCase(preSelectOfferCRMAction.pending, enableLoadingReducer);
    builder.addCase(preSelectOfferCRMAction.rejected, errorReducer);
    builder.addCase(preSelectOfferCRMAction.fulfilled, (state, { payload }) => {
      state.offers.preSelectedOffer = payload.selected;
      state.stepCheckpoint = payload.stepCheckpoint;
      state.documents.secciId = payload.secciId;
      disableLoadingReducer(state);
    });

    builder.addCase(saveHubspotDealAction.pending, enableLoadingReducer);
    builder.addCase(saveHubspotDealAction.rejected, errorReducer);
    builder.addCase(saveHubspotDealAction.fulfilled, (state, { payload }) => {
      state.loanIntent = payload.loanIntent;
      state.offers.preSelectedOffer = payload.selectedOffer;
      state.documents.secciId = payload.secciId;
      disableLoadingReducer(state);
    });

    builder.addCase(updateHubspotDealAction.pending, enableLoadingReducer);
    builder.addCase(updateHubspotDealAction.rejected, errorReducer);
    builder.addCase(updateHubspotDealAction.fulfilled, (state, { payload }) => {
      if(payload.actionType === OfferActionTypeEnum.UPDATE) {
        state.offers.preSelectedOffer = {...state.offers.preSelectedOffer, ...payload.offer};
      } else {
        state.offers.preSelectedOffer = payload.offer
      }
      state.documents.secciId = payload.secciId;

      if(payload.offer?.insurance) {
        state.insurance.isEnabled = payload.offer?.insurance === YesNo.YES
      }

      if(payload.offer.cpi?.beneficiaries) {
        state.insurance.beneficiary = payload.offer.cpi?.beneficiaries;
      }
      disableLoadingReducer(state);
    });
    //#endregion

    //region save cpi beneficiary
    builder.addCase(saveCPIBeneficiaryAction.pending, enableLoadingReducer);
    builder.addCase(saveCPIBeneficiaryAction.rejected, errorReducer)
    builder.addCase(saveCPIBeneficiaryAction.fulfilled, disableLoadingReducer);

    //#region Select Offer
    builder.addCase(selectOfferAction.pending, enableLoadingReducer);
    builder.addCase(selectOfferAction.rejected, errorReducer);
    builder.addCase(selectOfferAction.fulfilled, (state, { payload }) => {
      state.offers.selectedOffer = payload.preSelectedOffer as CrmOffer;
      // TODO: add set procedureStatus
      state.stepCheckpoint = payload.stepCheckpoint;
      disableLoadingReducer(state);
    });
    //#endregion

    //#region Cofidis Offer
    builder.addCase(calculateLeadsOffer.pending, enableLoadingReducer);
    builder.addCase(calculateLeadsOffer.rejected, handleLeadsError);
    builder.addCase(calculateLeadsOffer.fulfilled, (state, { payload }) => {
      // General
      state.init = true;
      state.stepCheckpoint = payload?.stepCheckpoint;
      state.contactId = payload?.account ? payload?.account?.contact_id : initialState.contactId;
      state.channel = payload?.account
        ? payload?.account?.channel_original ?? undefined
        : initialState.channel;

      // Iban
      state.iban = payload?.account?.kyc?.iban;
      state.isIbanChecked = !!payload?.account?.kyc?.iban_check;

      // Data collection
      assign(state.dataCollection.slides, payload?.slides);

      // Offers
      state.procedureId = payload?.procedureId;
      state.applicationId = payload?.applicationId;
      state.offers.generalStatus = payload?.offerLoanResponse?.generalStatus;

      if (payload?.offerLoanResponse?.generalStatus !== GeneralStatus.KO) {
        state.offers.offerList = payload?.offerLoanResponse?.offers!.sort((o: any) => (o.recommended ? -1 : 1));
        state.offers.preSelectedOffer = payload?.selected;
      }
      state.offers.preSelectedOffer = payload?.selected;

      // Loading
      state.loading.isLoading = false;
      state.loading.text = initialState.loading.text;

      // Notification popup
      state.loadingPopup = {
        isOpen: false
      };
    });
    //#endregion

    //#region Iban
    builder.addCase(setIbanAction.pending, enableLoadingReducer);
    builder.addCase(setIbanAction.rejected, errorReducer);
    builder.addCase(setIbanAction.fulfilled, (state, { payload }) => {
      state.iban = payload.iban;
      state.ibanValid = payload.ibanValid;
      state.isIbanChecked = payload.ibanValid;
      state.ibanAttempt = payload.ibanAttempt;
      disableLoadingReducer(state);
    });

    builder.addCase(setIbanValidAction, state => {
      state.ibanValid = true;
      state.isIbanChecked = true;
    });
    //#endregion

    //#region KYC Checks
    builder.addCase(sendKycDocumentsAction.pending, enableLoadingReducer);
    builder.addCase(sendKycDocumentsAction.rejected, errorReducer);
    builder.addCase(sendKycDocumentsAction.fulfilled, (state, { payload }) => {
      state.kyc.documentType = payload.documentType;
      state.kyc_data.kycCompleted = true;
      disableLoadingReducer(state);
    });

    builder.addCase(setRequireDocumentsAction, (state, { payload }: any) => {
      state.incomeDocumentation.uploadPageDescription = payload.documentationInfo?.uploadPageDescription;
      state.incomeDocumentation.documentsRequired = payload.documentationInfo?.documentsRequired;
    });

    builder.addCase(sendKycIncomeDocumentation.pending, enableLoadingReducer);
    builder.addCase(sendKycIncomeDocumentation.rejected, errorReducer);
    builder.addCase(sendKycIncomeDocumentation.fulfilled, (state, { payload }) => {
      state.kyc.incomeDocumentationType = payload.incomeDocumentationType;
      disableLoadingReducer(state);
    });

    builder.addCase(failKycVideoSelfieAction.pending, enableLoadingReducer);
    builder.addCase(failKycVideoSelfieAction.rejected, errorReducer);
    builder.addCase(failKycVideoSelfieAction.fulfilled, state => {
      state.kyc_data.videoSelfieFailed = true;
      disableLoadingReducer(state);
    });
    //#endregion

    //#region Paychecks
    builder.addCase(sendPaychecksAction.pending, enableLoadingReducer);
    builder.addCase(sendPaychecksAction.rejected, errorReducer);
    builder.addCase(sendPaychecksAction.fulfilled, (state, { payload }) => {
      state.kyc.paychecks = payload.paychecks;
      disableLoadingReducer(state);
    });
    //#endregion

    //#region BankStatement
    builder.addCase(sendBankStatementAction.pending, enableLoadingReducer);
    builder.addCase(sendBankStatementAction.rejected, errorReducer);
    builder.addCase(sendBankStatementAction.fulfilled, (state, { payload }) => {
      state.kyc.bankStatement = payload.bankStatement;
      disableLoadingReducer(state);
    });
    //#endregion

    //#region ADV Data
    builder.addCase(updateAdvDataAction.pending, enableLoadingReducer);
    builder.addCase(updateAdvDataAction.rejected, errorReducer);
    builder.addCase(updateAdvDataAction.fulfilled, (state, { payload }) => {
      state.advData = payload.advData;
      state.stepCheckpoint = payload.stepCheckpoint;
      disableLoadingReducer(state);
    });
    //#endregion

    builder.addCase(getHubspotAndCbsStatusAction.pending, enableLoadingReducer);
    builder.addCase(getHubspotAndCbsStatusAction.rejected, errorReducer);
    builder.addCase(getHubspotAndCbsStatusAction.fulfilled, (state, { payload }) => {
      const { hubspotAccount, hubspotDeal, cbsProcedure, secciId } = payload;

      const parsedHubspotAccount = parseAccount(hubspotAccount);

      state.loading.isLoading = false;

      state.init = true;

      // Hubspot account
      state.contactId = parsedHubspotAccount.contactId;
      state.applicationId = parsedHubspotAccount.applicationId;
      state.procedureId = parsedHubspotAccount.procedureId;
      state.otpVerified = !!parsedHubspotAccount.otpVerified;
      state.channel = parsedHubspotAccount.channel;
      state.latestChannel = parsedHubspotAccount?.latestChannel;
      state.stepCheckpoint = parsedHubspotAccount.stepCheckpoint;
      state.loanIntent = parsedHubspotAccount.loanIntent;
      assign(state.dataCollection.slides, state.dataCollection.slides, parsedHubspotAccount.dataCollection!.slides);
      state.kyc_data = parsedHubspotAccount.kyc_data ? parsedHubspotAccount.kyc_data : initialState.kyc_data;
      state.kyc.documentType = parsedHubspotAccount.kyc ? parsedHubspotAccount.kyc.documentType : initialState.kyc.documentType;
      state.kyc.incomeDocumentationType = parsedHubspotAccount.kyc
        ? parsedHubspotAccount.kyc.incomeDocumentationType
        : initialState.kyc.incomeDocumentationType;
      state.kycMode = parsedHubspotAccount.kyc ? parsedHubspotAccount.kycMode : initialState.kycMode;
      state.iban = parsedHubspotAccount.kyc ? parsedHubspotAccount.iban : initialState.iban;
      state.ibanValid = parsedHubspotAccount.kyc && parsedHubspotAccount.iban ? !!parsedHubspotAccount.iban : initialState.ibanValid;
      state.ibanAttempt = 0; // TODO review this, this not exists - CTLFE-201
      state.isIbanChecked = parsedHubspotAccount.kyc && parsedHubspotAccount.isIbanChecked ? parsedHubspotAccount.isIbanChecked : false;
      state.openBanking.openBankingCode =
        parsedHubspotAccount && parsedHubspotAccount.openBanking
          ? parsedHubspotAccount.openBanking.openBankingCode
          : initialState.openBanking.openBankingCode;
      state.openBanking.openBankingEnabled =
        parsedHubspotAccount && parsedHubspotAccount.openBanking
          ? parsedHubspotAccount.openBanking.openBankingEnabled
          : initialState.openBanking.openBankingEnabled;

      state.documents = parsedHubspotAccount.documents as any;

      // Hubspot Deal
      if(hubspotDeal) {
      // TODO: create parser
        state.offers.preSelectedOffer = hubspotDeal.offer; // TODO: assign only if deal has not been confirmed yet
        state.offers.selectedOffer = hubspotDeal.offer; // TODO: assign only if deal has been confirmed
        state.insurance.isEnabled = hubspotDeal.offer.insurance === YesNo.YES;

        const {pre_contractual_documents, coherence_questionnaire, beneficiaries} = hubspotDeal.offer?.cpi || {};

        state.insurance.preContractualDocuments = pre_contractual_documents ? { viewDate: pre_contractual_documents.visualization_date} : undefined;
        state.insurance.questionnaire = coherence_questionnaire ? {
          ...omit(coherence_questionnaire, ["visualization_date", "submission_date"]),
          viewDate: coherence_questionnaire.visualization_date!,
          submitDate: coherence_questionnaire.submission_date ?? undefined,
        } : undefined;

        state.insurance.beneficiary = beneficiaries;
        state.documents.secciId = secciId;
      }

      // CBS procedure
      if(cbsProcedure) {
        state.cbsProcedureStatus = cbsProcedure.status;
        const preSelectedOfferId = hubspotDeal?.offer?.offerId;
        state.offers.generalStatus = cbsProcedure.creditManagementOffersResponse.loanResponse?.find(({offers}) => offers?.some(item => item.offerId === preSelectedOfferId))?.generalStatus ?? GeneralStatus.KO;
        const okLoanResponses = cbsProcedure.creditManagementOffersResponse.loanResponse
          .filter(loanResponse => loanResponse.generalStatus !== GeneralStatus.KO);
        state.offers.offerList = okLoanResponses.flatMap(loanResponse => loanResponse.offers ?? []);
        state.insurance.hasAvailableProduct = okLoanResponses.length ? okLoanResponses[0]!.offers!.length > 1 : false;
      }

    });


    builder.addCase(emailAction, (state, { payload }) => {
      state.dataCollection.slides.contact_info.email = payload;
    });
    //#endregion

    //#region Apply State
    builder.addCase(applyStatePrestitiOnlineAction.rejected, errorReducer);
    builder.addCase(applyStatePrestitiOnlineAction.fulfilled, (state, { payload }) => {
      state.init = true;
      state.isExternalComparatorInitialized = true;
      state.applicationId = payload.applicationId;
      state.stepCheckpoint = payload.stepCheckpoint;
      state.channel = payload.channel;
      state.loanIntent = payload.loanIntent;
      assign(state.dataCollection.slides, state.dataCollection.slides, payload.dataCollection!.slides);
    });
    //#endregion

    //#region Apply KYC state
    builder.addCase(applyKycDocumentsAction.rejected, errorReducer);
    builder.addCase(applyKycDocumentsAction.fulfilled, (state, { payload }) => {
      state.kyc_data = payload;
      disableLoadingReducer(state);
    });
    //#endregion

    //#region SPID voucher
    builder.addCase(generateVoucherAction.pending, enableLoadingReducer);
    builder.addCase(generateVoucherAction.rejected, errorReducer);
    // fullfilled redirect to the namirial page, no need to set a reducer

    builder.addCase(getVoucherAction.pending, enableLoadingReducer);
    builder.addCase(getVoucherAction.rejected, errorReducer);
    builder.addCase(getVoucherAction.fulfilled, (state, { payload }) => {
      state.kyc.voucherCode = payload.voucherCode;
      state.kyc.voucherData = payload.voucherData;
      state.kyc_data.spidFailed = 'BASIC' === payload?.voucherData?.typeIdentification;
      disableLoadingReducer(state);
    });
    //#endregion

    //#region HubSpot
    builder.addCase(requestHubSpotAction.pending, enableLoadingReducer);
    builder.addCase(requestHubSpotAction.rejected, errorReducer);
    builder.addCase(requestHubSpotAction.fulfilled, disableLoadingReducer);

    builder.addCase(submitBookingCallAction.pending, enableLoadingReducer);
    builder.addCase(submitBookingCallAction.rejected, errorReducer);
    builder.addCase(submitBookingCallAction.fulfilled, disableLoadingReducer);
    //#endregion

    //#region OTP
    builder.addCase(requestOTPAction.pending, enableLoadingReducer);
    builder.addCase(requestOTPAction.rejected, errorReducer);
    builder.addCase(requestOTPAction.fulfilled, state => {
      state.otpRequestCount = state.otpRequestCount + 1;
      disableLoadingReducer(state);
    });

    builder.addCase(verifyOTPAction.pending, enableLoadingReducer);
    builder.addCase(verifyOTPAction.rejected, errorReducer);
    builder.addCase(verifyOTPAction.fulfilled, (state, { payload }) => {
      disableLoadingReducer(state);

      if (!payload) {
        return;
      }
      state.otpVerified = payload.otpVerified;
    });
    //#endregion

    //#region Nonce
    builder.addCase(generateNonce.pending, enableLoadingReducer);
    builder.addCase(generateNonce.rejected, errorReducer);
    builder.addCase(generateNonce.fulfilled, (state, { payload }) => {
      state.nonce = payload.nonce;
      disableLoadingReducer(state);
    });

    builder.addCase(verifyNonce.pending, enableLoadingReducer);
    builder.addCase(verifyNonce.rejected, errorReducer);
    //#endregion

    //#region Open Banking
    builder.addCase(redirectToTinkAction.pending, enableLoadingReducer);
    builder.addCase(redirectToTinkAction.rejected, errorReducer);
    // fullfilled redirect to the tink page, no need to set a reducer

    builder.addCase(enableOpenBanking.pending, enableLoadingReducer);
    builder.addCase(enableOpenBanking.rejected, errorReducer);
    builder.addCase(enableOpenBanking.fulfilled, disableLoadingReducer);
    //#endregion

    //#region Personal Area Status
    builder.addCase(applyPersonalAreaStatusAction.pending, enableLoadingReducer);
    builder.addCase(applyPersonalAreaStatusAction.rejected, errorReducer);
    builder.addCase(
      applyPersonalAreaStatusAction.fulfilled,
      (state, { payload: { availableDocuments, route, documentsRequired, loanData } }) => {
        state.personalArea.route = route;
        state.documents.availableDocuments = availableDocuments ?? [];
        state.personalArea.loanData = loanData;
        state.personalArea.documentsRequired = documentsRequired;
        disableLoadingReducer(state);
      }
    );

    builder.addCase(sendRequiredDocumentsAction.pending, enableLoadingReducer);
    builder.addCase(sendRequiredDocumentsAction.rejected, errorReducer);
    builder.addCase(sendRequiredDocumentsAction.fulfilled, disableLoadingReducer);
    //#endregion

    //#region Download Files
    builder.addCase(downloadDocumentById.pending, enableLoadingReducer);
    builder.addCase(downloadDocumentById.rejected, errorReducer);
    builder.addCase(downloadDocumentById.fulfilled, disableLoadingReducer);

    builder.addCase(downloadDocumentByRole.pending, enableLoadingReducer);
    builder.addCase(downloadDocumentByRole.rejected, errorReducer);
    builder.addCase(downloadDocumentByRole.fulfilled, disableLoadingReducer);

    builder.addCase(downloadDocumentByEntityId.pending, enableLoadingReducer);
    builder.addCase(downloadDocumentByEntityId.rejected, errorReducer);
    builder.addCase(downloadDocumentByEntityId.fulfilled, disableLoadingReducer);

    builder.addCase(downloadSecciPreviewAction.pending, enableLoadingReducer);
    builder.addCase(downloadSecciPreviewAction.rejected, errorReducer);
    builder.addCase(downloadSecciPreviewAction.fulfilled, disableLoadingReducer);

    builder.addCase(downloadSecciAction.pending, enableLoadingReducer);
    builder.addCase(downloadSecciAction.rejected, errorReducer);
    builder.addCase(downloadSecciAction.fulfilled, disableLoadingReducer);

    builder.addCase(downloadContractAction.pending, enableLoadingReducer);
    builder.addCase(downloadContractAction.rejected, errorReducer);
    builder.addCase(downloadContractAction.fulfilled, disableLoadingReducer);
    //#endregion

    //#region Logout
    builder.addCase(logoutAction.pending, enableLoadingReducer);
    builder.addCase(logoutAction.rejected, errorReducer);
    builder.addCase(logoutAction.fulfilled, state => {
      state = initialState;
      disableLoadingReducer(state);
    });
    //#endregion

    /**
     * Data Layer
     */
    builder.addCase(savePreviousLocationAction, (state, { payload }) => {
      state.dataLayer.previousPageSection = payload.previousPageSection;
      state.dataLayer.previousLocation = payload.previousLocation;
    });

    /**
     * Prestiti Online
     */
    builder.addCase(privacyConsentsPrestitiOnlineAction, (state, { payload }) => {
      state.dataCollection.slides = {
        ...state.dataCollection.slides,
        ...payload.slides
      };
    });
  }
});

export const {
  setLoanIntent,
  setForeignBirthPlace,
  setLeadsJourney,
  setReducedDataCollection,
  setLeadsClient,
  setPersonalInfo,
  setFiscalCode,
  setEmployment,
  setVatNumber,
  setIncomeAndExpenses,
  setPrivacyConsents,
  setCoupon,
  setLoadingPopup,
  setContactInfo,
  setPersonalDetails,
  setBirthInfo,
  setEmploymentStatus,
  setCompanyIndustry,
  setCompanySize,
  setCompanyHiring,
  setMonthlyIncome,
  setIncomeSources,
  setAddress,
  setMaritalStatus,
  setFamily,
  setLivingSituation,
  setCouponCode,
  setContactInfoAndPrivacyPolicy,
  setLoanIntentAndPrivacyPolicy,
  resetOffersGeneralStatus,
  setPrivacyPolicy,
  setInsurance,
  setInsuranceQuestionnaire
} = mainSlice.actions;
