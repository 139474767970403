import React from 'react';

// React Router
import { useNavigate } from 'react-router';

// Redux
import { useSelector } from 'react-redux';
import { selectPersonalInfo } from '../../store/selectors';

// Custom Hooks
import { useBackListener } from '../data-collection-pol/custom-hooks/useBackListenerDataCollectionPol';

// Theme
import { defaultTheme } from '../../components/ThemeWrapper';

// Component
import Slide from '../../components/Slide/Slide';
import PageWrapper from '../../components/PageWrapper';

// UI Components
import Image from '../../UI/Image/Image';
import { Container } from '../../UI/containers/Containers';
import { BoldParagraph, Paragraph } from '../../UI/paragraphs/Paragraphs';

// Constants
import { CONTE_HOME } from '../../constants/links';

// Resources
import {
  ReactComponent as conte_icon_illustration_cancelled
} from '../../assets/resources/illustrations/expired-ko/conte_icon_illustration_cancelled_declined_by_aml_uw.svg';

const Cancelled: React.FC = () => {

  const navigate = useNavigate();

  /**
   * useSelectors
   */
  const { firstName } = useSelector(selectPersonalInfo);

  /**
   * Custom Hooks
   */
  // Prevent navigation back
  useBackListener(() => {
    navigate(1);
  });

  /**
   * handlers
   */
  const handleRedirectHome = () => {
    window.location.href = process.env.REACT_APP_LANDING_PAGE_URL ?? CONTE_HOME;
  };

  return (
    <PageWrapper hasNavbarGradient>
      <Slide.Container>
        <Slide.Content noMargin>
          <Container marginBottom={40}>
            <Image component={conte_icon_illustration_cancelled} />
          </Container>
          <Container fontSize={16} lineHeight={26} color={defaultTheme.colors.text} padding={10}>
            <BoldParagraph color={defaultTheme.colors.dark_blue}>{firstName},</BoldParagraph>
            <Paragraph>
              abbiamo dato seguito alla tua richiesta di rinuncia per la pratica associata al tuo prestito personale e
              la tua richiesta di finanziamento è stata annullata.
              <br />
              <br />
              Ci dispiace di non averti potuto supportare nella realizzazione del tuo progetto e speriamo in futuro di
              poterti essere di aiuto 😊
            </Paragraph>
          </Container>
        </Slide.Content>
        <Slide.Actions>
          <Slide.FixedButton text='Torna alla Homepage' onClick={handleRedirectHome} />
        </Slide.Actions>
      </Slide.Container>
    </PageWrapper>
  );
};

export default Cancelled;