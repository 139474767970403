import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { DC_SLIDES } from '../../constants/data-collection-slides';
import { calculateFiscalID } from '../../services/fiscal-id.service';
import { RootState } from '../index';
import { DOMAIN_PREFIX, getPrefix } from './action-prefix';
import { omit } from 'lodash';
import { isExternalComparatorChannel } from '../../utils/channel/channel.utils';

const prefix = getPrefix(DOMAIN_PREFIX.DATA_COLLECTION);

export const clientTypeSubmit = createAction(prefix.concat('clientTypeSubmit'));
export const progressSummarySubmit = createAction(prefix.concat('progressSummarySubmit'));
export const personalInfo1Submit = createAction(prefix.concat('personalInfo1Submit'));
export const personalInfo2Submit = createAsyncThunk(
  prefix.concat('personalInfo2Submit'),
  async (stepData: any, thunkAPI) => {
    const slides = (thunkAPI.getState() as RootState).dataCollection.slides;

    let payload = {
      slides: {
        [DC_SLIDES.PERSONAL_INFO_2.ID]: stepData
      }
    };

    try {
      const cf = calculateFiscalID({
        ...slides[DC_SLIDES.PERSONAL_INFO_1.ID],
        ...stepData
      });
      payload.slides[DC_SLIDES.FISCAL_ID.ID] = { code: cf };
    } catch (err: any) {
      console.error('Fiscal ID: Manual input required. Unable to calculate automatically:', err.message);

      // user input its fiscal code manually
      payload.slides[DC_SLIDES.FISCAL_ID.ID] = { code: '' };
    }

    return payload;
  }
);
export const fiscalIDSubmit = createAction(prefix.concat('fiscalIDSubmit'));
export const contactInfoSubmit = createAsyncThunk(prefix.concat('contactInfoSubmit'), async (stepData: any, thunkAPI) => {

    const slides = (thunkAPI.getState() as RootState).dataCollection.slides;
    const channel = (thunkAPI.getState() as RootState).channel;

    let payload: any;

    if (isExternalComparatorChannel(channel!)) {
      payload = {
        slides: {
          [DC_SLIDES.CONTACT_INFO.ID]: omit(stepData, ['datePrivacy']),
          [DC_SLIDES.PERSONAL_INFO_1.ID]: {
            ...slides.personal_info_1,
            datePrivacy: stepData.datePrivacy
          }
        }
      };
    } else {
      payload = {
        slides: {
          [DC_SLIDES.CONTACT_INFO.ID]: stepData
        }
      };
    }

    if (stepData.email.length === 0 && stepData.phone.length === 0
    ) {
      // set step
      payload.nextStep = DC_SLIDES.ADDRESS;
    }

    return payload;
  })
;
export const privacyConsentsSubmit = createAction(prefix.concat('privacyConsentsSubmit'), stepData => {
  return { payload: stepData.consents };
});
export const otpSubmit = createAction(prefix.concat('otpSubmit'));
export const addressSubmit = createAction(prefix.concat('addressSubmit'));
export const maritalStatusSubmit = createAction(prefix.concat('maritalStatusSubmit'));
export const numHouseholdMembersSubmit = createAction(
  prefix.concat('numHouseholdMembersSubmit'),
  stepData => {
    let payload: any = {
      slides: {
        [DC_SLIDES.NUM_HOUSEHOLD_MEMBERS.ID]: stepData
      }
    };

    if (stepData.numHouseholdMembers === 'ONE') {
      // reset unnecessary data
      payload.slides[DC_SLIDES.PEOPLE_WITH_INCOME.ID] = {};

      // set step
      payload.nextStep = DC_SLIDES.EDUCATION_LEVEL;
    } else {
      // set step
      payload.nextStep = DC_SLIDES.PEOPLE_WITH_INCOME;
    }

    return { payload };
  }
);
export const peopleWithIncomeSubmit = createAction(prefix.concat('peopleWithIncomeSubmit'));
export const educationLevelSubmit = createAction(prefix.concat('educationLevelSubmit'));
export const employmentStatusSubmit = createAction(prefix.concat('employmentStatusSubmit'), stepData => {
  let payload: any = {
    slides: {
      [DC_SLIDES.EMPLOYMENT_STATUS.ID]: stepData,
      [DC_SLIDES.VAT_NUMBER.ID]: {} // reset VAT_NUMBER to keep consistency
    }
  };

  switch (stepData.employmentStatus) {
    case 'SELF_EMPLOYED':
      // reset unnecessary data
      payload.slides[DC_SLIDES.HIRED_ON_YEAR.ID] = {};
      payload.slides[DC_SLIDES.HIRED_ON_MONTH.ID] = {};
      payload.slides[DC_SLIDES.COMPANY_INDUSTRY.ID] = {};
      payload.slides[DC_SLIDES.COMPANY_SIZE.ID] = {};

      payload.nextStep = DC_SLIDES.VAT_NUMBER;
      break;
    case 'PENSIONER':
    case 'STUDENT':
    case 'UNEMPLOYED':
      // reset unnecessary data
      payload.slides[DC_SLIDES.HIRED_ON_YEAR.ID] = {};
      payload.slides[DC_SLIDES.HIRED_ON_MONTH.ID] = {};
      payload.slides[DC_SLIDES.COMPANY_INDUSTRY.ID] = {};
      payload.slides[DC_SLIDES.COMPANY_SIZE.ID] = {};
      payload.slides[DC_SLIDES.JOB_ROLE.ID] = {};

      payload.nextStep = DC_SLIDES.PROGRESS_SUMMARY_3;
      break;

    case 'PERMANENT_EMPLOYEE_FT':
    case 'PERMANENT_EMPLOYEE_PT':
    case 'FIXED_TIME_EMPLOYEE_FT':
    case 'FIXED_TIME_EMPLOYEE_PT':
    default:
      payload.nextStep = DC_SLIDES.HIRED_ON_YEAR;
  }

  return { payload };
});

export const vatNumberSubmit = createAction(prefix.concat('vatNumberSubmit'), stepData => {
  let payload: any = {
    slides: {
      [DC_SLIDES.VAT_NUMBER.ID]: stepData
    },
    nextStep: DC_SLIDES.JOB_ROLE
  };

  return { payload };
});

export const hiredOnYearSubmit = createAction(prefix.concat('hiredOnYear'), stepData => {
  let payload: any = {
    slides: {
      [DC_SLIDES.HIRED_ON_YEAR.ID]: stepData
    }
  };

  const prevYear = (new Date().getFullYear() - 1).toString();
  if (+stepData.hiredOnYear < +prevYear) {
    // set 'hiredOnMonth' to default value
    payload.slides[DC_SLIDES.HIRED_ON_MONTH.ID] = {};

    // skip HiredOnMonth slide
    payload.nextStep = DC_SLIDES.COMPANY_INDUSTRY;
  }
  // else show HiredOnMonth slide

  return { payload };
});
export const hiredOnMonthSubmit = createAction(prefix.concat('hiredOnMonthSubmit'));
export const companyIndustrySubmit = createAction(prefix.concat('companyIndustrySubmit'));
export const companySizeSubmit = createAction(prefix.concat('companySizeSubmit'));
export const jobRoleSubmit = createAction(prefix.concat('jobRoleSubmit'));
export const livingSituationSubmit = createAction(prefix.concat('livingSituationSubmit'));
export const previousLoansSubmit = createAction(prefix.concat('previousLoansSubmit'), stepData => {
  let payload: any = {
    slides: {
      [DC_SLIDES.PREVIOUS_LOANS.ID]: stepData
    }
  };

  switch (stepData.previousLoans) {
    case 'NEVER':
      // reset unnecessary data
      payload.slides[DC_SLIDES.LATE_PAYMENTS.ID] = {};

      payload.nextStep = DC_SLIDES.MONTHLY_INCOME;
      break;
    case 'IN_THE_PAST':
      // reset unnecessary data

      payload.nextStep = DC_SLIDES.LATE_PAYMENTS;
      break;

    case 'ON_GOING':
    default:
      break;
  }

  return { payload };
});
export const latePaymentsSubmit = createAction(prefix.concat('latePaymentsSubmit'));
export const monthlyIncomeSubmit = createAction(prefix.concat('monthlyIncomeSubmit'));
export const incomeSourcesSubmit = createAction(prefix.concat('incomeSourcesSubmit'));
export const couponCodeSubmit = createAction(prefix.concat('couponCodeSubmit'));

/**
 * Prestiti Online
 */

export const privacyConsentsPrestitiOnlineAction = createAction(prefix.concat('privacyConsentsPrestitiOnlineAction'), stepData => {
  let payload = {
    slides: {
      [DC_SLIDES.PRIVACY_CONSENTS.ID]: stepData
    }
  }
  return { payload };
});
