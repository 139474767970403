import CodiceFiscale from 'codice-fiscale-js';
import countryList from '../assets/datasets/country-list.json';

type CalculateFiscalIdArgs = {
  birthCountry: string;
  birthCity: any;
  firstName: any;
  lastName: any;
  gender: any;
  birthDay: any;
  birthMonth: any;
  birthYear: any;
  birthProvince: string;
};

export const calculateFiscalID = (params: CalculateFiscalIdArgs): string => {
  let birthplace;
  if (params.birthCountry === 'ITA') {
    birthplace = params.birthCity; // if birthCountry is Italy, set as bithplace the italian birthCity
  } else {
    // else set as birthplace the country of birth
    birthplace = countryList.find(cl => cl.alpha3 === params.birthCountry)?.name || '';
  }

  // Not mapped in https://lucavandro.github.io/CodiceFiscaleJS/
  const unmappedBirthPlaces = [
    'Antartide',
    'Aruba',
    'Azerbaigian',
    'Georgia',
    'Georgia del Sud e Isole Sandwich Australi',
    'Guernsey',
    'Isola Bouvet',
    'Isola di Man',
    'Isola di Natale',
    'Isola Norfolk',
    'Isole Åland',
    'Isole BES',
    'Isole Heard e McDonald',
    'Isole minori esterne degli Stati Uniti',
    'Jersey',
    'Kazakistan',
    'Kirghizistan',
    'Malaysia',
    'Saint-Barthélemy',
    'Saint-Martin',
    'Seychelles',
    'Sint Maarten',
    'Svalbard e Jan Mayen',
    'eSwatini',
    'Tagikistan',
    'Terre australi e antartiche francesi',
    'Turkmenistan',
    'Uzbekistan'
  ];

  const mappedBirthPlace: any = {
    Anguilla: 'Anguilla (Isola)',
    Russia: 'Russia=Federazione Russa',
    Cina: 'Cina Nazionale',
    Figi: 'Figi=Viti',
    Guam: 'Guam (Isola)',
    'Guinea-Bissau': 'Guinea Bissau',
    Guyana: 'Guyana Olandese',
    'Guyana francese': 'Guyana Francese',
    'Isole Cayman': 'Cayman (Isole)',
    'Isole Cook': 'Cook (Isole)',
    'Fær Øer': 'Faer Oer (Isole)',
    'Isole Falkland': 'Falkland (Isole)',
    'Isole Marianne Settentrionali': 'Marianne (Isole)',
    'Isole Marshall': 'Marshall (Isole)',
    'Isole Pitcairn': 'Pitcairn (e dipendenze)',
    'Isole Salomone': 'Salomone (Isole)',
    'Isole Vergini britanniche': 'Vergini Britanniche (Isole)',
    'Isole Vergini americane': 'Vergini Americane (Isole)',
    Mauritius: 'Maurizio (Isole)',
    Mayotte: 'Mayotte (Isola',
    Micronesia: 'Micronesia Stati Federati',
    Nauru: 'Nauru (Isole)',
    Niue: 'Niue=Savage (Isole)',
    'Nuova Caledonia': 'NUOVA CALEDONIA (ISOLE E DIPENDENZE)',
    Palestina: 'TERRITORI PALESTINESI',
    'Polinesia francese': 'POLINESIA FRANCESE (ISOLE)',
    'Porto Rico': 'PUERTO RICO',
    'RD del Congo': 'CONGO REPUBBLICA DEMOCRATICA',
    'Rep. Ceca': 'CECA REPUBBLICA',
    'Rep. Centrafricana': 'CENTRAFRICANA REPUBBLICA',
    'Rep. del Congo': 'CONGO REPUBBLICA POPOLARE',
    'Rep. Dominicana': 'DOMINICANA REPUBBLICA',
    'La Riunione': 'LA REUNION (ISOLA)',
    'Sahara Occidentale': 'SAHARA SPAGNOLO',
    "Sant'Elena, Ascensione e Tristan da Cunha": "SANT'ELENA (ISOLA)",
    'Saint-Pierre e Miquelon': 'SAINT PIERRE ET MIQUELON (ISOLE)',
    'Samoa Americane': 'SAMOA AMERICANE (ISOLE)',
    'São Tomé e Príncipe': "SAO TOME' E PRINCIPE (ISOLE)",
    'Stati Uniti': "STATI UNITI D'AMERICA",
    Sudafrica: 'Sudafricana Repubblica',
    'Terre australi e antartiche francesi': 'DIPENDENZE FRANCESI',
    "Territorio britannico dell'Oceano Indiano": 'DIPENDENZE BRITANNICHE',
    'Timor Est': 'TIMOR (ISOLA)',
    Tokelau: "TOKELAU=ISOLE DELL'UNIONE",
    'Turks e Caicos': 'TURKS E CAICOS (ISOLE)',
    'Wallis e Futuna': 'WALLIS E FUTUNA (ISOLE)'
  };

  if (mappedBirthPlace[birthplace]) {
    birthplace = mappedBirthPlace[birthplace];
  }

  if (!unmappedBirthPlaces.includes(birthplace)) {
    const fiscalId = new CodiceFiscale({
      name: params.firstName,
      surname: params.lastName,
      gender: params.gender,
      day: params.birthDay,
      month: params.birthMonth,
      year: params.birthYear,
      birthplace,
      birthplaceProvincia: params.birthCountry === 'ITA' ? params.birthProvince : 'EE' // EE is for people born outside Italy
    });
    return fiscalId.cf;
  } else return '';
};
