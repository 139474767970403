import React from 'react';
import Button from '../../UI/Button/Button';
import FixedButton from '../../UI/FixedButton/FixedButton';
import styles from './Slide.module.scss';

const Slide: any = {};

Slide.Container = ({ noPadding, children }: any) => (
  <div className={[styles.slideContainer, noPadding ? styles.noPadding : ''].join(' ')}> {children} </div>
);

Slide.Content = ({ noMargin, noHorizontalMargin, children }: any) => (
  <div className={[styles.slideContent, noHorizontalMargin ?  styles.noHorizontalMargin : noMargin ? styles.noMargin : styles.extraMargin].join(' ')}>
    {children}
  </div>
);

Slide.Question = ({ type = 'title', number, alternativeColor, children }: any) => (
  <div className={[styles.slideQuestion, alternativeColor ? styles.blue : ''].join(' ')}>
    <span className={styles.questionNumber}> {number} </span>
    <i className="icon-conte_icon_dc_arrow_red_step" />
    <span className={styles.questionText}>
      {type === 'title' && <span className={styles.questionTitle}> {children} </span>}
      {type === 'sub-title' && <span className={styles.questionSubTitle}> {children} </span>}
    </span>
  </div>
);

Slide.QuestionNote = ({ children }: any) => <div className={styles.questionNote}> {children} </div>;

Slide.Actions = ({ children }: any) => <div className={styles.slideActions}> {children} </div>;

Slide.FixedButton = ({ text, disabled, onClick }: any) => (
  <FixedButton onClick={onClick} disabled={disabled} data-test="fixed-button">
    {text}
  </FixedButton>
);

// Slide.FixedButtonDouble = ({
//   textLeft,
//   textRight,
//   disabledLeft,
//   disabledRight,
//   onClickLeft,
//   onClickRight
// }: any) => (
//   <>
//     <FixedButton onClick={onClickLeft} disabled={disabledLeft} data-test="fixed-button-double-left">
//       {textLeft}
//     </FixedButton>
//     <FixedButton onClick={onClickRight} disabled={disabledRight} data-test="fixed-button-double-right">
//       {textRight}
//     </FixedButton>
//   </>
// );

Slide.BackButtonDesktop = ({ text, onClick }: any) => (
  <Button
    customClasses={[styles.slideBackButtonDesktop]}
    color="secondary"
    icon="icon-conte_icon_header_back"
    onClick={onClick}>
    {text}
  </Button>
);

Slide.BackButtonMobile = ({ text, onClick }: any) => (
  <Button customClasses={[styles.slideBackButtonMobile]} color="secondary" onClick={onClick}>
    {text}
  </Button>
);

Slide.FixedFooter = ({ children, className }: any) => (
  <div className={[styles.slideFooterFixed, className].join(' ')}>{children}</div>
);

export default Slide;
