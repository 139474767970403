import React from 'react';

// React Router
import { useNavigate } from 'react-router';

// Redux
import { useSelector } from 'react-redux';
import { selectPersonalInfo } from '../../store/selectors';

// Custom Hooks
import { useBackListener } from '../data-collection-pol/custom-hooks/useBackListenerDataCollectionPol';

// Theme
import { defaultTheme } from '../../components/ThemeWrapper';

// Component
import Slide from '../../components/Slide/Slide';
import PageWrapper from '../../components/PageWrapper';

// Custom Hooks
import useDataLayer from '../../features/data-layer/hooks/use-data-layer';

// UI Components
import Image from '../../UI/Image/Image';
import { Container, FlexContainer, Separator } from '../../UI/containers/Containers';
import { BoldParagraph, Paragraph } from '../../UI/paragraphs/Paragraphs';
import { Accordion } from '../../UI/Accordion/Accordion';

// Constants
import { CONTE_HOME, SIC_FAQ } from '../../constants/links';

// Enums
import { PageSections } from '../../enums/data-layer/page-sections';

// Resources
import {
  ReactComponent as conte_icon_illustration_declined_by_rules_bureau_ko
} from '../../assets/resources/illustrations/personal-area/conte_icon_illustration_declined_by_rules_bureau_ko.svg';

// @ts-ignore
import { AccordionContent } from '../Negotiation/pages/Failed/Failed';


const OfferBureauKo: React.FC = () => {

  const navigate = useNavigate();

  /**
   * useSelectors
   */
  const { firstName } = useSelector(selectPersonalInfo);

  /**
   * Custom Hooks
   */
  // Prevent navigation back
  useBackListener(() => {
    navigate(1);
  });
  const { handlePushFunnelStep } = useDataLayer(PageSections.NEGOTIATION);

  /**
   * handlers
   */
  const handleRedirectHome = () => {
    handlePushFunnelStep(undefined);
    window.location.href = process.env.REACT_APP_LANDING_PAGE_URL ?? CONTE_HOME;
  };

  return (
    <PageWrapper hasNavbarGradient>
      <Slide.Container>
        <Slide.Content noMargin>
          <Container marginBottom={40}>
            <Image component={conte_icon_illustration_declined_by_rules_bureau_ko} />
          </Container>
          <Container fontSize={16} lineHeight={30} marginBottom={50}>
            <BoldParagraph fontSize={18}>{firstName},</BoldParagraph>
            <Paragraph color={defaultTheme.colors.text}>
              sulla base delle informazioni che ci hai fornito, non siamo in grado di fornirti una proposta di prestito
              personale.
            </Paragraph>
          </Container>
          <FlexContainer gap={20} flexDirection={'column'} marginBottom={50}>
            <BoldParagraph fontSize={18} color={defaultTheme.colors.dark_blue}>Perché non ci sono
              offerte?</BoldParagraph>
            <Separator backgroundColor={defaultTheme.colors.c_red} />
          </FlexContainer>
          <FlexContainer marginVertical={20} gap={20} flexDirection={'column'} align={'left'} childrenFullWidth>
            <Accordion id='failed1' noPadding
                       title='Perchè la mia richiesta di prestito personale non è stata accolta?'>
              <Container padding={25} align={'left'} color={defaultTheme.colors.text}>
                Questa situazione potrebbe occorrere al presentarsi di alcuni motivi come a titolo
                esemplificativo ma
                non
                esaustivo:
                <br />
                <br />
                <ul>
                  <li> La tua situazione complessiva non è in linea con la nostra policy di credito;
                    <br />
                    <br />
                  </li>
                  <li>La tua situazione patrimoniale o il tuo reddito risultano essere insufficienti a garantire il
                    risarcimento del prestito;
                    <br />
                    <br />
                  </li>
                  <li>Risultano esserci già altri finanziamenti in corso che possono causare una situazione di
                    sovraindebitamento, oppure sono in corso altre richieste di prestito a più istituti di credito o
                    finanziarie;
                    <br />
                    <br />
                  </li>
                  <li>Dalle informazioni fornite dai SIC - Sistemi di Informazioni Creditizie (vedi&nbsp;
                    <a className='link' href={SIC_FAQ} target='_blank'>
                      Che cosa sono i credit bureau?
                    </a>) la tua posizione evidenzia una o più problematiche.
                    <br />
                  </li>
                </ul>
              </Container>
            </Accordion>
            <Accordion id='failed3' noPadding title='Come posso avere maggiori informazioni?'>
              <Container padding={25} align={'left'} color={defaultTheme.colors.text}>
                Se vuoi chiedere maggiori informazioni contatta il nostro servizio clienti accedendo dalla tua Area
                Personale o scrivi un'email ad&nbsp;
                <a className='link' href='mailto:assistenza@conteprestiti.it' target='_blank'
                   rel='noreferrer'>assistenza@conteprestiti.it</a>.
              </Container>
            </Accordion>
          </FlexContainer>
        </Slide.Content>
        <Slide.Actions>
          <Slide.FixedButton text='Torna alla Homepage' onClick={handleRedirectHome} />
        </Slide.Actions>
      </Slide.Container>
    </PageWrapper>
  );
};

export default OfferBureauKo;
