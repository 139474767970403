export enum DirectChannels {
  CONTE = 'CONTE'
}

export enum ExternalComparatorsChannels {
  // TODO: Add new external comparators channels if required
  POL = 'POL',
  POL_WEB_A = 'POL-WEB-A',
  POL_WEB_B = 'POL-WEB-B',
  POL_WEB_A_2 = 'POL_WEB_A',
  POL_WEB_B_2 = 'POL_WEB_B',
  POL_CRM = 'POL-CRM',
  SEG_WEB_A = 'SEG-WEB-A',
  SEG_WEB_B = 'SEG-WEB-B',
  SEG_CRM = 'SEG-CRM',
  S24_WEB_A = 'S24-WEB-A',
  S24_WEB_ = 'S24-WEB-B',
  S24_CRM = 'S24-CRM',
  COFIDIS = 'COFIDIS',
  MUTUIAMO = 'MUTUIAMO'
}

export enum CbsChannels {
  CRM = 'CRM',
  PORTAL = 'PORTAL',
  PCW = 'PCW'
}
