import React from 'react';
import styled from 'styled-components/macro';
import { FlexContainer } from '../containers/Containers';
import tw from 'tailwind-styled-components';

interface CardContainerProps {
  noPadding?: boolean;
  padding?: number;
  hasEffect?: boolean;
}

const CardContainer = styled.div<CardContainerProps>`
  background: ${props => props.theme.colors.white};
  box-shadow: 0 0 40px ${props => props.theme.colors.dark_blue_shadow};
  padding: ${(props: any) => props.noPadding ? 0 : props.padding ?? 20}px;
  border-radius: 7px;

  animation: ${(props: any) => props.hasEffect ? 'fadeIn 0.5s' : ''};
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const CloseButton = styled.i`
  cursor: pointer;
  color: #acc1d8;
  font-size: 20px;

  &:hover {
    filter: brightness(75%);
  }
`;

const CardTitle = styled.h4`
  font-size: 20px;
`;

const CardCover = styled.div<{ coverImage: string }>`
  background-image: url(${props => props.coverImage});

  width: 100%;
  height: 110px;

  background-size: 100% auto;
  background-position: top;
  background-repeat: no-repeat;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
`;

export type CardProps = {
  id: string;
  title?: string;
  customTitle?: any;
  noPadding?: boolean;
  padding?: number;
  coverImage?: string;
  isDismissable?: boolean;
  children: React.ReactNode;
  className?: string;
  onCloseClick?: () => void;
  onClick?: () => void;
  hasEffect?: boolean;
  customClasses?: Array<string>
};

export function Card({
                       id,
                       title,
                       customTitle,
                       noPadding,
                       padding,
                       coverImage,
                       isDismissable,
                       children,
                       onCloseClick,
                       onClick,
                       hasEffect,
                       customClasses,
                       ...rest
                     }: CardProps) {
  return (
    <CardContainer id={id} title={title} noPadding={noPadding || !!coverImage} padding={padding}
                   hasEffect={hasEffect} onClick={onClick} className={customClasses?.join(" ") || ""} {...rest}>
      {isDismissable &&
        <FlexContainer justifyContent={'end'}>
          <CloseButton className={'icon-conte_icon_close_pupup'} onClick={onCloseClick} />
        </FlexContainer>
      }
      {coverImage && <CardCover coverImage={coverImage} />}
      {title &&
        <CardTitle>{title}</CardTitle>
      }
      {customTitle && customTitle}
      {children}
    </CardContainer>
  );
}

export const SimpleCard = styled.div`
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`;

interface ConTeCardProps {
  noPadding?: boolean;
}

export const ConTeCard = tw(SimpleCard)<ConTeCardProps>`
    ${(props) => (props.noPadding ? 'tw-p-0' : 'tw-p-5')}
    tw-rounded-xl tw-bg-white 
`;