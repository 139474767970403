import React, { FC, useMemo, useState } from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { AppThemeCtx, AppThemeEnhancements, ThemeContext } from '../context/theme-context';

export type DeviceSize = 'mobile' | 'tablet' | 'desktop';

export const defaultTheme = {
  breakpoints: {
    tablet: 768,
    desktop: 1024
  },
  device: {
    mobile: 'max-width: 767.98px',
    tablet: 'min-width: 768px',
    desktop: 'min-width: 1024px'
  },
  colors: {
    blue: '#4DABFF', // Hover and filled inputs
    dark_blue: '#0F4683',
    dark_blue_shadow: '#0F468326',
    white: '#FFFFFF',
    black: '#000000',
    black_shadow: '#F8F8F8',

    // Containers
    container_light: '#F8F8F9',

    text: '#484848',

    c_card_bg: '#F9FCFF',

    // TODO review
    c_light_grey: '#DEDEDE',
    c_dim_grey: '#B6B6B6',
    c_green: '#4AB047',
    c_light_green: '#E5F7E4',
    c_confirm_green: '#CEF0B4',
    c_dark_green: '#1B7A18',
    c_progress_bg: '#F0F0F0',
    c_red: '#C8102E',
    c_red_error: '#EE0029',
    c_disabled_blu: '#ACC1D8',
    c_divider: '#E6ECF2',
    c_overlay: '#09458580',
    c_button_blue: 'linear-gradient(270deg, #104d87 0%, #1e77b4 100%)',
    c_button_green: 'linear-gradient(90deg, #4fb054 2.75%, #80d585 98.01%)',
    yellow: "#FFCC17",
    // secondary: {
    //   yellow: "#FFCC17",
    //   yellow50: "#FFFCF3",
    //   yellow100: "#FFFAE8",
    //   yellow200: "#FFF5D1",
    //   yellow300: "#FFF0BA",
    //   yellow400: "#FFEBA2",
    //   yellow500: "#FFE58A",
    //   yellow600: "#FFD074",
    //   yellow700: "#FFCC17",
    //   yellow800: "#F3C00C",
    //   yellow900: "#E4B303",
    // },
    // service: {
    //   red: "#CC0000",
    //   lightRed: "#FAE6E6",
    //   orange: "#F19830",
    //   lightOrange: "#FEF5EB",
    //   green: "#3AAB3A",
    //   lightGreen: "#ECF7EC",
    //   gray: "#555555",
    //   lightGray: "#EAEAEA",
    //   alertLight: "#FFEEDB",
    //   alert: "#FF7A00",
    // },
    // disabled: {
    // },
  },
  fonts: {
    family: {
      primary: '\'Lato\', sans-serif'
    },
    size: {
      extraSmall: '10px',
      small: '12px',
      regular: '14px',
      large: '16px'
    },
    style: {
      regular: 'normal'
    },
    lineHeight: {
      regular: '20px'
    },
    letterSpacing: {
      regular: '0px'
    },
    weight: {
      regular: '400',
      bold: '700',
      bolder: '800',
      extraBold: '900'
    }
  }
};

export type AppTheme = typeof defaultTheme;

const AppThemeProvider: FC = ({ children }) => {
  const [enhancements, setTheme] = useState<AppThemeEnhancements>(defaultTheme);
  const ctx = useMemo<AppThemeCtx>(() => ({ setTheme }), [setTheme]);
  const theme = useMemo<AppTheme>(() => ({ ...enhancements, ...defaultTheme }), [enhancements]);

  return (
    <ThemeContext.Provider value={ctx}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export const GlobalStyle = createGlobalStyle`

  html,
  body {
    margin: 0;
    min-width: 320px;
    font-family: ${({ theme }) => theme.fonts.family.primary};
    font-size: ${({ theme }) => theme.fonts.size.regular};
    font-style: ${({ theme }) => theme.fonts.style.regular};
    font-weight: ${({ theme }) => theme.fonts.weight.regular};
    line-height: ${({ theme }) => theme.fonts.lineHeight.regular};
    letter-spacing: ${({ theme }) => theme.fonts.letterSpacing.regular};
    color: ${({ theme }) => theme.colors.dark_blue};
  }

  b {
    font-weight: ${({ theme }) => theme.fonts.weight.bold};
  }

  strong {
    font-weight: ${({ theme }) => theme.fonts.weight.extraBold};
  }
`;

export { AppThemeProvider as ThemeProvider };
