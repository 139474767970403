export enum DocumentRolesLabels {
  ABF_IN_PAROLE_SEMPLICI = 'ABF In Parole Semplici',
  AB_LAST = 'Ultimo Estratto Conto',
  ANNUAL_STATEMENT = 'Annual Statement',
  CENTRALE_RISCHI = 'Centrale Rischi',
  CI_BACK = 'Carta d\'Identità Retro',
  CIE_BACK = 'Carta d \'Identità Elettronica Retro',
  CIE_FRONT = 'Carta d\'Identità Elettronica Fronte',
  CI_FRONT = 'Carta d\'Identità Fronte',
  CONTEGGI_ESTINZIONE = 'Conteggi di estinzione dei prestiti in corso con gli altri istituti',
  CONTRACT_SIGNED = 'Contratto Firmato',
  CONTRACT_TO_SIGN = 'Contratto Da Firmare',
  CONTRATTO_LAVORO = 'Contratto di lavoro',
  CREDITO_CONSUMATORI = 'Credito Consumatori',
  CUD = 'CUD',
  DL_BACK = 'Patente Retro',
  DL_FRONT = 'Patente Fronte',
  DOCUMENTO_CRIF = 'Documentazione Crif',
  DOCUMENTO_EXPERIAN = 'Documento Experian',
  DOS_PER = 'Dossier Persona',
  LAUNDRY_QUESTIONNAIRE = 'Questionario Antiriciclaggio',
  LETTERA_TRASPARENZA = "Lettera di trasparenza",
  MODELLO_UNICO = 'Modello Unico con Ricevuta di Presentazione Telematica',
  PAGAMENTO_RATA = 'Giustificativo pagamento rata',
  PASS_BACK = 'Passaporto Retro',
  PASS_FRONT = 'Passaporto Fronte',
  PAYCHECK_1 = 'Ultima Busta Paga',
  PAYCHECK_2 = 'Penultima Busta Paga',
  PAYCHECK_OTHER = 'Altra Busta Paga',
  PERM_SOGG_BACK = 'Permesso Soggiorno Retro',
  PERM_SOGG_FRONT = 'Permesso Soggiorno Fronte',
  PERM_SOGG_REC = 'Permesso Soggiorno Ricevuta',
  QUE_ADV = 'Questionario Adeguata Verifica',
  RENDICONTO = "Rendiconto annuale",
  REPORT_SCIPAFI = 'Report Scipafi',
  RICEVUTA_TELEMATICA_REDDITI = 'Ricevuta di presentazione telematica dei redditi',
  SCONOSCIUTO = 'Altro',
  SECCI = 'Secci',
  SELFIE = 'Selfie',
  TRIMESTRALE_ANTIUSURA = 'Trimestrale Antiusura',
  TS_BACK = 'Tessera Sanitaria Retro',
  TS_FRONT = 'Tessera Sanitaria Fronte',
  UTILITY_1 = 'Bolletta di utenza domestica non più vecchia di 90 gg',
  UTILITY_2 = 'Certificato di residenza non più vecchio di 90 gg',
  UTILITY_3 = 'Tessera elettorale',
  UTILITY_4 = 'Libretto auto',
  VIDEO_SELFIE = 'Video Selfie'
}

export enum DocumentRoles {
  ABF_IN_PAROLE_SEMPLICI = 'ABF_IN_PAROLE_SEMPLICI',
  AB_LAST = 'AB_LAST',
  ANNUAL_STATEMENT = 'ANNUAL_STATEMENT',
  CENTRALE_RISCHI = 'CENTRALE_RISCHI',
  CI_BACK = 'CI_BACK',
  CIE_BACK = 'CIE_BACK',
  CIE_FRONT = 'CIE_FRONT',
  CI_FRONT = 'CI_FRONT',
  CONTEGGI_ESTINZIONE = 'CONTEGGI_ESTINZIONE',
  CONTRACT_SIGNED = 'CONTRACT_SIGNED',
  CONTRACT_TO_SIGN = 'CONTRACT_TO_SIGN',
  CONTRATTO_LAVORO = 'CONTRATTO_LAVORO',
  CREDITO_CONSUMATORI = 'CREDITO_CONSUMATORI',
  CUD = 'CUD',
  DL_BACK = 'DL_BACK',
  DL_FRONT = 'DL_FRONT',
  DOCUMENTO_CRIF = 'DOCUMENTO_CRIF',
  DOCUMENTO_EXPERIAN = 'DOCUMENTO_EXPERIAN',
  DOS_PER = 'DOS_PER',
  LAUNDRY_QUESTIONNAIRE = 'LAUNDRY_QUESTIONNAIRE',
  LETTERA_TRASPARENZA = 'LETTERA_TRASPARENZA',
  MODELLO_UNICO = 'MODELLO_UNICO',
  PAGAMENTO_RATA = 'PAGAMENTO_RATA',
  PASS_BACK = 'PASS_BACK',
  PASS_FRONT = 'PASS_FRONT',
  PAYCHECK_1 = 'PAYCHECK_1',
  PAYCHECK_2 = 'PAYCHECK_2',
  PAYCHECK_OTHER = 'PAYCHECK_OTHER',
  PERM_SOGG_BACK = 'PERM_SOGG_BACK',
  PERM_SOGG_FRONT = 'PERM_SOGG_FRONT',
  PERM_SOGG_REC = 'PERM_SOGG_REC',
  QUE_ADV = 'QUE_ADV',
  RENDICONTO = 'RENDICONTO',
  REPORT_SCIPAFI = 'REPORT_SCIPAFI',
  RICEVUTA_TELEMATICA_REDDITI = 'RICEVUTA_TELEMATICA_REDDITI',
  SCONOSCIUTO = 'SCONOSCIUTO',
  SECCI = 'SECCI',
  SELFIE = 'SELFIE',
  TRIMESTRALE_ANTIUSURA = 'TRIMESTRALE_ANTIUSURA',
  TS_BACK = 'TS_BACK',
  TS_FRONT = 'TS_FRONT',
  UTILITY_1 = 'UTILITY_1',
  UTILITY_2 = 'UTILITY_2',
  UTILITY_3 = 'UTILITY_3',
  UTILITY_4 = 'UTILITY_4',
  VIDEO_SELFIE = 'VIDEO_SELFIE'
}
