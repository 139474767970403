export enum NEGOTIATION_SLIDES {
  COMMERCIAL_OFFER_LOADING = 'commercial-offer-loading',
  COMMERCIAL_OFFER = 'commercial-offer',
  SELECT_OFFER = 'select-offer',
  OFFER_SUMMARY = 'offer-summary',
  IBAN_INPUT = 'iban-input',
  FAILED = 'failed'
  // INSURANCE: 'insurance',
  // CUSTOM_OFFER: 'custom-offer',
}
