import { PayloadAction } from '@reduxjs/toolkit';
import { MainSliceState } from '../main-slice';

export const setVatNumberReducer = (state: MainSliceState, action: PayloadAction<any>) => {
  state.dataCollection.slides.vat_number = action.payload.vat_number;
};

export const setCompanyIndustryReducer = (state: MainSliceState, action: PayloadAction<any>) => {
  state.dataCollection.slides.company_industry = action.payload.company_industry;
};

export const setCompanySizeReducer = (state: MainSliceState, action: PayloadAction<any>) => {
  state.dataCollection.slides.company_size = action.payload.company_size;
};
