export enum ADV_SLIDES {
  HOME_ADV = 'adv-home',
  DICHIARAZIONE_PEP = 'dichiarazione-pep',
  FONTE_PATRIMONIO = 'fonte-patrimonio',
  FONTE_REDDITO = 'fonte-reddito',
  RELAZIONE_FONDI_PUBBLICI = 'relazione-fondi-pubblici',
  TITOLARE_EFFETTIVO = 'titolare-effettivo',
  FASCIA_REDDITO = 'fascia-reddito',
  RUOLO_EFFETTIVO = 'ruolo-effettivo',
  ADV_COMPLETE = 'adv-complete'
}
