import React, { FC, Fragment, useEffect } from 'react';

// Redux
import { useSelector } from 'react-redux';
import { selectInit } from '../../store/selectors';

// React Router
import { useLocation, useNavigate } from 'react-router';

// Custom Hooks
import { useLastPath } from '../../hooks/useLastPath';

// Utils
import { removeEmptyValuesDeep } from '../../utils/remove-empty-values';

// Enums
import { AppRoutes } from '../../enums/app-routes';

export const AuthHelper: FC = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isSessionInitialized = useSelector(selectInit);
  const lastPath = useLastPath();

  useEffect(() => {
    if (
      isSessionInitialized ||
      [
        AppRoutes.LANDING,
        AppRoutes.SITE_UNDER_MAINTENANCE,
        AppRoutes.CONTINUE,
        AppRoutes.OTP,
        AppRoutes.OPEN_BANKING_CALLBACK,
        AppRoutes.OPTIMIZED_DATA_COLLECTION,
        AppRoutes.LEADS,
        AppRoutes.PROGRESS,
      ].includes(lastPath as AppRoutes)
    ) {
      return;
    }

    const locationClone: Partial<typeof location> = { ...location };
    delete locationClone.key;
    delete locationClone.state;
    return navigate({
      pathname: `/${AppRoutes.CONTINUE}`,
      search: new URLSearchParams({
        redirect: JSON.stringify(removeEmptyValuesDeep(locationClone))
      }).toString()
    });
  }, [location, isSessionInitialized, navigate]);

  return <Fragment>{children}</Fragment>;
};
