// https://docs.tink.com/resources/api-setup/connect-tink-link#customize-tink-link

// generate open banking link (Tink Link)
export const generateTinkLink = (state: any, externalReference?: string) => {
  const stateString = JSON.stringify(state);
  const stateEncoded = encodeURIComponent(stateString);

  const params = [
    `client_id=${process.env.REACT_APP_TINK_LINK_USERS_CLIENT_ID}`,
    `redirect_uri=${process.env.REACT_APP_TINK_LINK_CALLBACK_URL}`,
    `external_reference=${externalReference}`,
    `market=${process.env.REACT_APP_TINK_LINK_USERS_MARKET}`,
    'locale=it_IT',
    `state=${stateEncoded}`,
    `test=${process.env.REACT_APP_TINK_LINK_TEST_ENV}`
  ];

  return `https://link.tink.com/1.0/income-check/create-report?${params.join('&')}`;
};
