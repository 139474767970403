export enum StepCheckpoints {
  INITIATION = 'INITIATION',
  INITIATION_PCW = 'INITIATION_PCW',
  INITIATION_PARTNERSHIPS = 'INITIATION_PARTNERSHIPS',
  INITIATION_LEADS = 'INITIATION_LEADS',
  INITIATION_OP = 'INITIATION_OP',
  RESUME_PERSONAL = 'RESUME_PERSONAL',
  RESUME_JOB = 'RESUME_JOB',
  RESUME_FINANCE = 'RESUME_FINANCE',
  STARTING_OFFER_TO_BE_DISPLAYED = 'STARTING_OFFER_TO_BE_DISPLAYED',
  STARTING_OFFER_DISPLAYED = 'STARTING_OFFER_DISPLAYED',
  OFFER_BUREAU_KO = 'OFFER_BUREAU_KO',
  DECLINED_BY_RULES = 'DECLINED_BY_RULES',
  OFFER_TO_BE_SELECTED = 'OFFER_TO_BE_SELECTED',
  SELECTED_OFFER = 'SELECTED_OFFER',
  IDENTITY_TO_BE_VERIFIED = 'IDENTITY_TO_BE_VERIFIED',
  IDENTITY_VERIFIED = 'IDENTITY_VERIFIED',
  PAYCHECKS_UPLOADED = 'PAYCHECKS_UPLOADED',
  DOCS_UPLOADED = 'DOCS_UPLOADED',
  DOCS_REQUIRED_BY_UW = 'DOCS_REQUIRED_BY_UW',
  QUESTIONNAIRE_COMPLETED = 'QUESTIONNAIRE_COMPLETED',
  IBAN_CHANGE_REQUESTED = 'IBAN_CHANGE_REQUESTED',

  // Optimized Data Collection
  DC_CONSENSUS = 'DC_CONSENSUS',
  DC_PERSONAL = 'DC_PERSONAL',
  DC_JOB_FINANCE = 'DC_JOB_FINANCE',
  DC_RESIDENTIAL = 'DC_RESIDENTIAL',
  DC_COMPLETED = 'DC_COMPLETED',
  NEGOTIATION_COMPLETED = 'NEGOTIATION_COMPLETED',
  KYC_COMPLETED = 'KYC_COMPLETED'
}
