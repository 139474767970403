import { createAction } from '@reduxjs/toolkit';
import { MainSliceState } from '../main-slice';
import { DOMAIN_PREFIX, getPrefix } from './action-prefix';

  const prefix = getPrefix(DOMAIN_PREFIX.ERROR_DIALOG);

export const openErrorModalAction = createAction<Partial<MainSliceState['errorModal']>>(
  prefix.concat('open-dialog')
);
export const closeErrorModalAction = createAction<void>(prefix.concat('close-dialog'));
