import React, { FC, ReactNode } from 'react';
import Sheet from 'react-modal-sheet';

// React Router
import { useLocation } from 'react-router';

// Theme
import { defaultTheme } from '../../components/ThemeWrapper';

// UI Components
import { FlexContainer, Separator } from '../containers/Containers';

// Enums
import { AppRoutes } from '../../enums/app-routes';

// Styles
import styled from 'styled-components';
import styles from './BottomSheet.module.scss';

export interface BottomSheetProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit?: () => void;
  children?: ReactNode;
  hasCloseButton?: boolean;
}

const BottomSheet: FC<BottomSheetProps> = ({ isOpen, onClose, children, hasCloseButton }) => {
  const { pathname } = useLocation();

  return (
    <Sheet
      isOpen={isOpen}
      onClose={onClose}
      snapPoints={[0.8]}
      detent="content-height"
      className={[styles.sheet, !pathname.includes(AppRoutes.CONTINUE) && styles.sheetPosition].join(' ')}>
      <Sheet.Backdrop onTap={onClose} />
      <Sheet.Container
        style={{ borderTopRightRadius: '14px', borderTopLeftRadius: '14px', boxShadow: 'none' }}>
        {hasCloseButton && (
          <BottomSheetCloseButton className="icon-conte_icon_close_pupup" onClick={onClose} />
        )}
        <Sheet.Header className="pt-3" />
        <Sheet.Content disableDrag style={{ height: 'auto' }}>
          {children}
          <FlexContainer marginBottom={20}>
            <Separator backgroundColor={defaultTheme.colors.c_dim_grey} />
          </FlexContainer>
        </Sheet.Content>
      </Sheet.Container>
    </Sheet>
  );
};

export default BottomSheet;

const BottomSheetCloseButton = styled.button`
  all: unset;
  position: absolute;
  top: 25px;
  right: 25px;
  color: #0f4683;
  font-size: 1.4rem;
  z-index: 999;
  opacity: 0.5;
  transition: 0.5s;

  &:hover {
    opacity: 0.8;
  }
`;
