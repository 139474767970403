import React, { ReactNode, useCallback, useMemo } from 'react';

// Redux
import { useDispatch } from 'react-redux';
import { openSidebarAction } from '../store/actions/set-sidebar.action';
import { openCustomerServiceAction } from '../store/actions/customer-service.action';

// Context
import { NavbarContext } from '../context/nav-context';

// Components
// @ts-ignore
import { SidebarTile } from './Sidebar/Sidebar';
// @ts-ignore
import Page from './Page/Page';
// @ts-ignore
import Navbar from './Navbar/Navbar';


interface Props {
  children: ReactNode;
  hasNavbarGradient: boolean;
}

const PageWrapper: React.FC<Props> = ({ children, hasNavbarGradient }) => {

  // const navigate = useNavigate();
  const dispatch = useDispatch();

  /**
   * useContext
   */
  const navbarCtx = React.useContext(NavbarContext);

  /**
   * handlers and useCallbacks
   */
  // const handleBack = useCallback(() => navigate(-1), [navigate]);
  const handleOpenSidebar = useCallback(() => dispatch(openSidebarAction()), [dispatch]);
  const handleOpenCustomerService = useCallback(() => dispatch(openCustomerServiceAction()), [dispatch]);

  /**
   * useMemos
   */
  /*  const backButton = useMemo(() => {
      return { l1: { icon: 'icon-conte_icon_header_back', action: handleBack } };
    }, [handleBack]);*/

  // const backButtonDisabled = React.useMemo(() => ({ l1: { icon: '', action: null } }), []);

  const menuButton = useMemo(() => {
    return { l1: { icon: 'icon-conte_icon_header_right_burger_icon', action: handleOpenSidebar } };
  }, [handleOpenSidebar]);

  /*
    const menuButtonDisabled = useMemo(() => ({ r2: { icon: '', action: null } }), []);
  */

  const helpButton = useMemo(() => {
    return { r1: { icon: 'icon-conte_icon_help_cs', action: handleOpenCustomerService } };
  }, [handleOpenSidebar]);


  /**
   * useEffect
   */
  React.useEffect(() => {

    navbarCtx.showGradientBg(hasNavbarGradient);
    navbarCtx.updateButton({ ...menuButton, ...helpButton });

  }, [menuButton, helpButton]);

  return (
    <React.Fragment>
      <SidebarTile />
      <Page.Container withSidebar hasGradient={navbarCtx.hasGradientBg}>
        <Navbar />
        <Page.Content className='my-md-4'>
          {children}
        </Page.Content>
      </Page.Container>
    </React.Fragment>
  );
};

export default PageWrapper;