import { createContext } from "react";
import { AppTheme } from "../components/ThemeWrapper";

export type AppThemeEnhancements = Partial<AppTheme>;

export type SetThemeFn = (enhancements: AppThemeEnhancements) => void;

export type AppThemeCtx = {
  setTheme: SetThemeFn;
};

const ThemeContext = createContext<AppThemeCtx>({
  setTheme: () => undefined,
});

export { ThemeContext };
