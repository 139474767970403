import { DC_SLIDES } from '../../constants/data-collection-slides';
import { JobData } from '../../types/procedure';
import { RootState } from '../index';

export function selectEmploymentStatus(state: RootState): JobData['employmentStatus'] {
  return state.dataCollection.slides[DC_SLIDES.EMPLOYMENT_STATUS.ID].employmentStatus;
}

export function selectEmployment(state: RootState): JobData['employmentStatus'] {
  return state.dataCollection.slides[DC_SLIDES.EMPLOYMENT_STATUS.ID];
}
