import React from 'react';
import styled from 'styled-components';

const IconContainer = styled.i<{ size?: string }>`
  line-height: unset !important;

  &:before {
    font-size: ${({ size }) => (size ? size : '18px')};
    margin: 0;
  }
`;

export type IconProps = {
  iconName: string;
  size?: string;
  className?: string;
};

export function Icon({ iconName, className, ...rest }: IconProps): React.ReactElement {
  const _className = [iconName, className].join(' ');
  return <IconContainer className={_className} {...rest} />;
}

interface Props {
  color?: any;
  fontSize?: number;
}

export const ConTeIcon = styled.i<Props>`
  color: ${(props: Props) => props.color || ''};
  font-size: ${(props: Props) => props.fontSize || ''}px;
`;
