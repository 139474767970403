export const isValidDate = (year: number, month: number, day: number): boolean => {
  const d = new Date(year, month, day);
  // eslint-disable-next-line eqeqeq
  return d.getFullYear() == year && d.getMonth() == month && d.getDate() == day;
};

export const getTokenExpiration = (date: Date): number => date.getTime() + 60 * 60 * 1000;

export const isExpired = (millis: number): boolean => millis < new Date().getTime();

export const isAuthExpired = (): boolean => isExpired(+(sessionStorage.getItem('auth-expiration') || 0));

type ExplodedDate = { year: string; month: string; day: string };
/**
 * explode a date
 * @param date 'YYYY-MM-DD'
 * @returns {}
 */
export function explodeDate(date?: string): Partial<ExplodedDate> {
  const defaultReturn = { year: undefined, month: undefined, day: undefined };
  if (!date) {
    return defaultReturn;
  }
  try {
    const splittedDate = date.split('-');
    return { year: splittedDate[0], month: splittedDate[1], day: splittedDate[2] };
  } catch (err) {
    console.error('Date not valid ', err);
    return defaultReturn;
  }
}
