import React from 'react';

// UI Components
import { Container, FlexContainer } from '../../UI/containers/Containers';
import { Card } from '../../UI/Card/Card';
import { Paragraph } from '../../UI/paragraphs/Paragraphs';
import Button from '../../UI/Button/Button';


interface InterruptWindowProps {
  homeUrl: string;
  handleSwitchActivePage: Function;
}

const InterruptWindow: React.FC<InterruptWindowProps> = ({ homeUrl, handleSwitchActivePage }) => {

  return (
    <FlexContainer fullHeight={true} padding={20}>
      <Card id={'interrupt-card'}>
        <div>
          <Container marginBottom={10}>
            <Paragraph>
              Il sito di&nbsp;
              <span>
                  <a className='link' href={homeUrl} target='_blank' rel='noreferrer'>ConTe.it Prestiti</a>
                </span>
              &nbsp;risulta già aperto in un'altra finestra del tuo browser di navigazione 😊
            </Paragraph>
          </Container>
          <Paragraph>Se continui in questa finestra, la tua richiesta di prestito
            riprenderà dall'ultimo salvataggio.<br />Altrimenti basta chiudere questa finestra di navigazione e tornare
            alla finestra precedente.</Paragraph>
          <br />
          <br />
          <FlexContainer gap={10}>
            <Button onClick={handleSwitchActivePage}>Continua in questa finestra</Button>
          </FlexContainer>
        </div>
      </Card>
    </FlexContainer>
  );
};

export default InterruptWindow;