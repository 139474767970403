// Redux
import { notificationPopupAction } from '../../store/actions/notification-popup.action';

// Enums
import { NotificationType } from '../../enums/notification-type';


const openAndResetPopup = (dispatch: any, message: string, type: NotificationType, title?: string) => {

  // Show popup with animation
  dispatch(notificationPopupAction({
    isOpen: true,
    title: title,
    message: message,
    type: type
  }));

  // Hide popup after 3 sec with animation (args must be the same to show animation)
  setTimeout(() => {
    dispatch(notificationPopupAction({
      isOpen: false,
      title: title,
      message: message,
      type: type
    }));
  }, 3000);

  // Reset popup content after 3.5 sec
  setTimeout(() => dispatch(notificationPopupAction({
    isOpen: false
  })), 3500);
};

const notificationPopupUtil = { openAndResetPopup };

export default notificationPopupUtil;