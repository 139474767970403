import React from 'react';
import { useSelector } from 'react-redux';
import loadingGif from '../../assets/resources/logo/conte_loading_custom.gif';
import styles from './LoadingOverlay.module.scss';

export default function LoadingOverlay({ defaultEnabled }) {
  const { isLoading, text } = useSelector(state => state.loading);

  return (
    <React.Fragment>
      {(defaultEnabled || isLoading) && (
        <div className={styles.loadingOverlay}>
          <div className={styles.overlayContent}>
            <img src={loadingGif} alt="loading" className={styles.loadingImg} />
            <div className={styles.loadingText}>{text}</div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
