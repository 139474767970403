import { useLocation } from 'react-router';
import { useMemo } from 'react';

export const useLastPath = () => {
  const { pathname } = useLocation();

  const lastPath = useMemo(() => pathname?.split('/')?.pop(), [pathname]);

  return lastPath;
};
