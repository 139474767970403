import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { OrchestratorAPI } from '../../api';
import * as CRMService from '../../services/crm.service';
import { KycCheckWriteDTO } from '../../types/kyc';
import { VoucherDataReadDTO } from '../../types/vouchers';
import { generateAccount, generateLoanData, generateOfferRequest } from '../../utils/orchestrator-transform';
import { RootState } from '../index';
import { ThunkExtra } from '../types';

interface GenerateVoucherArgs {
  authType: string;
  dataLayerCallback?: any;
}

export const generateVoucherAction = createAsyncThunk<void, GenerateVoucherArgs, ThunkExtra>(
  'generateVoucherAction',
  async ({ authType, dataLayerCallback }, { getState, rejectWithValue }) => {
    const procedureId = (getState() as RootState).procedureId;

    if (!procedureId) {
      // TODO: handle error
      return;
    }

    try {
      const voucherResponse = await OrchestratorAPI.createVoucher({ procedureId });
      await CRMService.updateKycCount(authType);
      if (dataLayerCallback) {
        dataLayerCallback();
      }
      window.location.assign(voucherResponse.registrationUrl);
    } catch (err) {
      const error = err as AxiosError;
      if (dataLayerCallback) {
        dataLayerCallback(undefined, error?.message);
      }
      return rejectWithValue(
        'Si è verificato un problema durante il reindirizzamento a Namirial, ti invitiamo a riprovare più tardi'
      );
    }
  }
);

type GetVoucherReturned = { voucherCode?: GetVoucherArgs; voucherData?: VoucherDataReadDTO };
type GetVoucherArgs = string;

export const getVoucherAction = createAsyncThunk<GetVoucherReturned, GetVoucherArgs>(
  'getVoucher',
  async (voucherCode, thunkAPI) => {
    const { loanIntent, applicationId, procedureId, iban, kyc_data } = thunkAPI.getState() as RootState;
    const slides = (thunkAPI.getState() as RootState).dataCollection.slides;
    const openBankingConsent = (thunkAPI.getState() as RootState).openBanking.openBankingEnabled;

    let deviceFullPayload = kyc_data.devicePayload;
    if (!deviceFullPayload) {
      const deviceTransactionId = localStorage.getItem('deviceTransactionId');
      deviceFullPayload = deviceTransactionId ? JSON.parse(deviceTransactionId) : '';
    }

    if (!procedureId) {
      // TODO review all rejectWithValue
      return thunkAPI.rejectWithValue('Procedure ID not found');
    }

    try {
      const voucherData: VoucherDataReadDTO = await OrchestratorAPI.getVoucher(procedureId, voucherCode);

      // Interruption on SPID/CIE process
      // First level SPID is not allowed => typeIdentification: BASIC not allowed
      if (voucherData.consumed && 'FULL' === voucherData.typeIdentification) {

        const account = generateAccount(slides);
        const loanData = generateLoanData(loanIntent, slides);
        const offerRequest = generateOfferRequest(applicationId, account, deviceFullPayload, loanData);

        const payload: KycCheckWriteDTO = {
          ...offerRequest,
          voucherId: voucherCode,
          // @ts-ignore
          iban,
          consents: {
            openBankingConsent,
            bureausConsent: true // "bureausConsent" always true after starting offer, user already accepted SICs
          }
        };

        await OrchestratorAPI.kycChecks(procedureId, payload);
        await CRMService.updateKycSuccess({
          kyc_mode: 'spidCie',
          kyc_document_type: null
        });

      } else {
        await CRMService.updateSpidFailed();
      }

      return { voucherCode, voucherData };
    } catch (err) {
      return { };
      /*console.log('err', err);
      const error = err as AxiosError;
      return thunkAPI.rejectWithValue(error?.message);*/
    }
  }
);
