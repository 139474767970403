import { useContext, useEffect } from 'react';
import { NavigationType, UNSAFE_NavigationContext } from 'react-router-dom';
import { History, Update } from 'history';

export const useBackListener = (callback: (...args: any) => void) => {
  const navigator = useContext(UNSAFE_NavigationContext).navigator as History;

  useEffect(() => {
    const listener = ({ location, action }: Update) => {
      // console.log('listener', { location, action });
      if (action === NavigationType.Pop) {
        callback({ location, action });
      }
    };

    return navigator.listen(listener);
  }, [navigator, callback]);
};

// https://stackoverflow.com/questions/71572678/react-router-v-6-useprompt-typescript
// export function useBlocker(blocker: any, when = true) {
//   const navigator = useContext(NavigationContext).navigator as History;
//
//   useEffect(() => {
//     if (!when) return;
//     const unblock = navigator.block((tx: any) => {
//       const autoUnblockingTx = {
//         ...tx,
//         retry() {
//           // Automatically unblock the transition so it can play all the way
//           // through before retrying it. TODO: Figure out how to re-enable
//           // this block if the transition is cancelled for some reason.
//           unblock();
//           tx.retry();
//         }
//       };
//
//       blocker(autoUnblockingTx);
//     });
//
//     return unblock;
//   }, [navigator, blocker, when]);
// }
//
// export function usePrompt( message: any, when = true ) {
//   const blocker = useCallback(
//     ( tx ) => {
//       // eslint-disable-next-line no-alert
//       if ( window.confirm( message ) ) tx.retry();
//     },
//     [ message ]
//   );
//
//   useBlocker( blocker, when );
// }