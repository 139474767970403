import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkExtra } from '../types';
import { OrchestratorAPI } from '../../api';
import { AxiosError } from 'axios';
import { CpiBeneficiary } from '../../types/exelab-crm/cpi';

interface SaveCpiBeneficiaryActionsArgs {
  beneficiary: CpiBeneficiary;
}

export const saveCPIBeneficiaryAction = createAsyncThunk<
  void,
  SaveCpiBeneficiaryActionsArgs,
  ThunkExtra
>('saveCPIBeneficiaryAction', async ({ beneficiary }, { getState, rejectWithValue }) => {
  const { procedureId } = getState();

  if(!procedureId) {
    return rejectWithValue('Ops! Qualcosa è andato storto.');
  }

  try {
    await OrchestratorAPI.saveCpiBeneficiary(procedureId, beneficiary)
  } catch (err) {
    const error = err as AxiosError;

    return rejectWithValue(error?.message);
  }
})