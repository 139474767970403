import axios, { AxiosError, AxiosResponse } from 'axios';
import { store } from '../store';
import { selectDataCollectionContactInfo } from '../store/selectors';
import dataLayerService from '../services/data-layer/data-layer.service';

export const CRMInstance = axios.create({ baseURL: process.env.REACT_APP_CRM_ENDPOINT });
CRMInstance.interceptors.request.use(async config => {
  config.headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  return config;
});
CRMInstance.interceptors.response.use(
  (response: AxiosResponse) => response.data,
  (error: AxiosError) => apiErrorHandler(error)
);

export const OrchestratorInstance = axios.create({
  baseURL: process.env.REACT_APP_ORCHESTRATOR_ENDPOINT,
  withCredentials: true
});
OrchestratorInstance.interceptors.request.use(async config => {
  config.headers = {
    accept: 'application/json',
    'Content-Type': 'application/json',
    ...config.headers
  };
  return config;
});
OrchestratorInstance.interceptors.response.use(
  (response: AxiosResponse) => response.data,
  (error: AxiosError) => apiErrorHandler(error)
);

function apiErrorHandler(error: AxiosError) {
  console.error('API Error', error.response);
  dataLayerService.pushErrorMonitor(error);
  if (error.response && [401, 403].includes(error.response?.status)) {
    const state = store.getState();
    const { email } = selectDataCollectionContactInfo(state);

    if (!email) {
      window.location.href = document.referrer || process.env.REACT_APP_LANDING_PAGE_URL || '';
      return;
    }

    window.location.href = '/continue?' + new URLSearchParams({ email }).toString();
  }

  throw error;
}

export const hubspotInstance = axios.create({ baseURL: process.env.REACT_APP_HUBSPOT_ENDPOINT });
hubspotInstance.interceptors.request.use(async config => {
  config.headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };
  return config;
});
hubspotInstance.interceptors.response.use(
  (response: AxiosResponse) => response.data,
  (error: AxiosError) => apiErrorHandler(error)
);