import { useEffect, useMemo } from 'react';

// React Router
import { useLocation, useParams } from 'react-router';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { MainSliceState } from '../store/main-slice';
import { selectContactId } from '../store/selectors/select-contactId';
import { selectDataCollectionStep } from '../store/selectors';
import { selectDatalayer } from '../store/selectors/select-data-layer';
import { savePreviousLocationAction } from '../store/actions/save-previous-location.action';
import { selectStartingOfferSelected } from '../store/selectors/select-starting-offer';
import { selectLoanIntent } from '../store/selectors/select-loan-intent';
import { selectOfferPreSelected } from '../store/selectors/select-offers';

// Enums
import { PageSections } from '../enums/data-layer/page-sections';
import { Events } from '../enums/data-layer/events';
import { ADV_SLIDES } from '../constants/adv-slides';
import { NEGOTIATION_SLIDES } from '../constants/negotiation-slides';
import { AppRoutes } from '../enums/app-routes';

// Types
import { DataLayerViewInput } from '../types/data-layer/data-layer';

// Services
import dataLayerService from '../services/data-layer/data-layer.service';

// Utils
import dataLayerUtil from '../utils/data-layer/data-layer.util';

export const useLocationChange = (hasHash: boolean, pageSection: PageSections) => {

  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();

  /**
   * useSelectors
   */
  const contactId = useSelector(selectContactId);
  const { PROGRESS } = useSelector(selectDataCollectionStep) || {};
  const { previousPageSection, previousLocation } = useSelector(selectDatalayer);
  const startingOffer = useSelector(selectStartingOfferSelected);
  const preSelectedOffer = useSelector(selectOfferPreSelected);
  const procedureId = useSelector((state: MainSliceState) => state.procedureId);
  const installmentAmount = useSelector(
    (state: MainSliceState) => state.offers?.selectedOffer?.installmentsAmount
  );
  const loanIntent = useSelector(selectLoanIntent);

  /**
   * useMemos
   */
  const viewInput: DataLayerViewInput = useMemo(() => {
    return {
      hasHash: hasHash,
      pageSection: pageSection,
      location: location,
      previousPageSection: previousPageSection,
      previousLocation: previousLocation,
      params: params,
      stepNumberDataCollection: PROGRESS
    };
  }, [location, previousLocation, params, PROGRESS]);

  useEffect(() => {
    if (
      hasHash ? location?.hash !== previousLocation?.hash : location?.pathname !== previousLocation?.pathname
    ) {
      dataLayerService.pushInit(contactId);
      dataLayerService.pushFunnelView(viewInput);

      dispatch(savePreviousLocationAction(pageSection, location));

      let event;
      const pagePath = dataLayerUtil.getPagePath(location);

      switch (pagePath) {
        case 'negotiation/commercial-offer':
          event = Events.E12_COMMERCIAL_OFFER;
          break;
        case 'negotiation/select-offer':
          event = Events.E13_SELECT_OFFER;
          break;
        case 'negotiation/offer-summary':
          event = Events.E14_OFFER_SUMMARY;
          break;
        case 'kyc':
          event = Events.E15_KYC;
          break;
      }

      if (loanIntent) {

        if (event) {
          dataLayerService.pushLoanIntent(viewInput, event, loanIntent);
        }

        if (startingOffer) {

          if (`${AppRoutes.NEGOTIATION}/${NEGOTIATION_SLIDES.COMMERCIAL_OFFER}` === pagePath) {
            dataLayerService.pushCalcolaPrestitoView(viewInput, loanIntent, startingOffer);
          }
        }

        if (preSelectedOffer) {
          if (
            `${AppRoutes.ADV}/${ADV_SLIDES.ADV_COMPLETE}` === pagePath &&
            installmentAmount &&
            procedureId
          ) {
            dataLayerService.pushPurchase(
              viewInput,
              loanIntent,
              preSelectedOffer,
              procedureId,
              installmentAmount
            );
          }
        }
      }
    }

  }, [location]);

};