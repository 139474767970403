import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { StepCheckpoints } from '../../constants/step-checkpoints';
import * as CRMService from '../../services/crm.service';
import { ThunkExtra } from '../types';
import { disableLoadingAction, enableLoadingAction } from './set-loading.action';

export const updateCrmStepCheckpointAction = createAsyncThunk<StepCheckpoints, StepCheckpoints, ThunkExtra>(
  'updateCrmStepCheckpointAction',
  async (stepCheckpoint, { rejectWithValue, dispatch }) => {
    try {
      dispatch(enableLoadingAction(''));
      stepCheckpoint = await CRMService.updateCheckpoint(StepCheckpoints.STARTING_OFFER_DISPLAYED);
      dispatch(disableLoadingAction());

      return stepCheckpoint;
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error?.message);
    }
  }
);

interface CrmAccountArgs {
  data: any;
  stepCheckpoint?: StepCheckpoints;
  dataLayerCallback?: any;
}

interface CrmAccountPayload {
  stepCheckpoint?: StepCheckpoints;
}

export const updateCrmAccountAction = createAsyncThunk<CrmAccountPayload, CrmAccountArgs, ThunkExtra>(
  'update-crm-account-action',
  async (crmAccountArgs, { rejectWithValue }) => {
    const { data, stepCheckpoint, dataLayerCallback } = crmAccountArgs || {};
    try {
      await CRMService.updateAccount({ ...data, step_checkpoint: stepCheckpoint });

      if (dataLayerCallback) {
        dataLayerCallback({ data, stepCheckpoint });
      }

      return { stepCheckpoint };
    } catch (err) {
      const error = err as AxiosError;
      if (dataLayerCallback) {
        dataLayerCallback({ data, stepCheckpoint }, error?.message);
      }
      return rejectWithValue(error?.message);
    }
  }
);
