import { PayloadAction } from '@reduxjs/toolkit';
import { MainSliceState } from '../main-slice';

export const setMaritalStatusReducer = (state: MainSliceState, action: PayloadAction<any>) => {
  state.dataCollection.slides.marital_status = action.payload.marital_status;
};

export const setFamilyReducer = (state: MainSliceState, action: PayloadAction<any>) => {
  state.dataCollection.slides.num_household_members = { ...action.payload.num_household_members };
  state.dataCollection.slides.people_with_income = { ...action.payload.people_with_income };
};
