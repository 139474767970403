import { initialState, MainSliceState } from '../main-slice';

export const enableLoadingReducer = (state: MainSliceState) => {
  state.loading.isLoading = true;
};

export const disableLoadingReducer = (state: MainSliceState) => {
  state.loading.isLoading = false;
  state.loading.text = initialState.loading.text;
};
