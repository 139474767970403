import React, { FC, Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';

// React Router
import { useLocation } from 'react-router';

// Redux
import { useSelector } from 'react-redux';
import { selectRoot } from '../../store/selectors/select-root';

// Context
import { NavbarContext } from '../../context/nav-context';

// UI Components
import Image from '../../UI/Image/Image';

// Enums
import { AppRoutes, LeadsRoutes } from '../../enums/app-routes';
import { ExternalComparatorsChannels } from '../../enums/channels';

// Utils
import { isExternalComparatorChannel } from '../../utils/channel/channel.utils';

// Styles
import styles from './Navbar.module.scss';

// Resources
import { ReactComponent as logoImg } from '../../assets/resources/logo/conte_icon_logo_color.svg';
import { ReactComponent as dividerImg } from '../../assets/resources/icons/conte_icon_logo_chain.svg';
import { ReactComponent as partnerLogoImg } from '../../assets/resources/logo/prestiti_online_logo.svg';
import { ReactComponent as CofidisLogo } from '../../assets/resources/logo/cofidis_logo.svg';
import { ReactComponent as MutuiamoLogo } from '../../assets/resources/logo/mutuiamo-logo.svg';

const Navbar: FC = ({ children }) => {
  const location = useLocation();
  const ctx = useContext(NavbarContext);
  const { latestChannel, channel: originalChannel } = useSelector(selectRoot);
  const nBtns = ctx.navbarButtons;
  const hasGradientBg = ctx.hasGradientBg;

  const [bgOpacity, setBgOpacity] = useState(0);

  const onScroll = useCallback(() => {
    if (hasGradientBg) {
      setBgOpacity(window.scrollY / 50);
    }
  }, [hasGradientBg]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [onScroll]);

  const channel = useMemo(() => {
    return latestChannel ?? originalChannel
  }, [latestChannel, originalChannel])

  return (
    <Fragment>
      {ctx.isShow && (
        <div
          style={{
            background: hasGradientBg
              ? `linear-gradient(180deg, rgba(251, 252, 254, ${bgOpacity}), rgba(216, 234, 252, ${bgOpacity}))`
              : `white`,
            marginBottom: channel && isExternalComparatorChannel(channel) ? '25px' : ''
          }}
          className={styles.nav}>
          <div className={styles.redLine} />

          {ctx.isShowLogo && (
            <div className={styles.navbar}>
              {/* Left side */}
              <div className={styles.leftSide}>
                {nBtns.l1 && <NavbarItem icon={nBtns.l1.icon} action={nBtns.l1.action} />}
                {nBtns.l2 && <NavbarItem icon={nBtns.l2.icon} action={nBtns.l2.action} />}
              </div>

              {/* Center */}
              <div className='tw-flex tw-items-center tw-flex-wrap tw-justify-center gap-1 tw-flex-row tw-w-full'>
                <Image className={styles.logo} component={logoImg} />
                {channel && isExternalComparatorChannel(channel) && (
                  <Fragment>
                    <Image className={styles.logoDivider} component={dividerImg} />
                    <Image component={partnerLogoImg} />
                  </Fragment>
                )}
                {channel &&
                  [
                    `/${AppRoutes.LEADS}/${LeadsRoutes.LOAN_INTENT}`,
                    `/${AppRoutes.LEADS}/${LeadsRoutes.PRIVACY}`
                  ].includes(location.pathname) && (
                    <div className='tw-flex tw-items-center tw-justify-start'>
                      <Image className={styles.logoDivider} component={dividerImg} />
                      {channel === ExternalComparatorsChannels.COFIDIS && <Image component={CofidisLogo} />}
                      {channel === ExternalComparatorsChannels.MUTUIAMO && <MutuiamoLogo />}
                    </div>
                  )}
              </div>

              {/* Right side */}
              <div className={styles.rightSide}>
                {nBtns.r1 && <NavbarItem icon={nBtns.r1.icon} action={nBtns.r1.action} />}
                {nBtns.r2 && <NavbarItem icon={nBtns.r2.icon} action={nBtns.r2.action} />}
              </div>
            </div>
          )}

          {ctx.isShowChildren && <div className={styles.navChildren}>{children}</div>}
        </div>
      )}
    </Fragment>
  );
}

export default Navbar;

const NavbarItem = ({ icon, action }: any) => (
  <div className={styles.buttonIcon}>
    <i className={icon} onClick={action} aria-hidden="true" />
  </div>
);

export const NavbarTitle: FC = ({ children }) => <div className={styles.titleOnlyDesktop}>{children}</div>;
