import { RootState } from '../index';

export function selectPersonalAreaRoute(state: RootState) {
  return state.personalArea.route;
}

export function selectPersonalAreaDocumentsRequired(state: RootState) {
  return state.personalArea.documentsRequired;
}

export function selectPersonalAreaLoanData(state: RootState) {
  return state.personalArea.loanData;
}
