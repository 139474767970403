import React from 'react';

const contextTemplate: any = {
  channel: String,
  isShow: true,
  isShowLogo: true,
  isShowPartnerLogo: false,
  hasGradientBg: false,
  navbarButtons: {
    l1: { icon: '', action: null },
    l2: { icon: '', action: null },
    r1: { icon: '', action: null },
    r2: { icon: '', action: null }
  },
  isShowChildren: false,
  showNavbar: () => {},
  showLogo: () => {},
  updateButton: () => {},
  showGradientBg: () => {},
  showChildren: () => {},
  setChannel: () => {}
};

export const NavbarContext = React.createContext(contextTemplate);

export const NavbarProvider = ({ children }: any) => {
  const [isShow, showNavbar] = React.useState(contextTemplate.isShow);
  const [isShowLogo, showLogo] = React.useState(contextTemplate.isShowLogo);
  const [hasGradientBg, showGradientBg] = React.useState(contextTemplate.hasGradientBg);
  const [navbarButtons, setNavbarButtons] = React.useState(contextTemplate.navbarButtons);
  const [isShowChildren, showChildren] = React.useState(contextTemplate.isShowChildren);
  const [channel, setChannel] = React.useState(contextTemplate.channel);

  const updateButton = (buttons: any) => setNavbarButtons({ ...buttons });

  return (
    <NavbarContext.Provider
      value={{
        channel,
        setChannel,
        isShow,
        showNavbar,
        isShowLogo,
        showLogo,
        hasGradientBg,
        showGradientBg,
        navbarButtons,
        updateButton,
        isShowChildren,
        showChildren
      }}>
      {children}
    </NavbarContext.Provider>
  );
};
