import TagManager from 'react-gtm-module';

// Axios
import { AxiosError } from 'axios';

// Types
import {
  DataLayerCalcolaPrestitoView,
  DataLayerFunnelStep,
  DataLayerFunnelView,
  DataLayerInit,
  DataLayerLoanIntent,
  DataLayerPurchase,
  DataLayerViewInput,
  ErrorMonitor
} from '../../types/data-layer/data-layer';
import { CrmLoanIntent, CrmOffer } from '../../types/exelab-crm/account';
import { Offer } from '../../types/procedure';

// Enums
import { Events } from '../../enums/data-layer/events';

// Utils
import dataLayerUtil from '../../utils/data-layer/data-layer.util';

/**
 * Helpers
 */
const pushLayer = (dataLayer: any) => {

  TagManager.dataLayer({
    dataLayer: dataLayer
  });

};

const getDatalayerFunnelView = (viewInput: DataLayerViewInput): DataLayerFunnelView => {

  const pageName = dataLayerUtil.getPageName(viewInput.hasHash, viewInput.location, viewInput.params);
  const pagePath = dataLayerUtil.getPagePath(viewInput.location);

  return {
    event: Events.E02_FUNNEL_VIEW,
    page_path: pagePath,
    page_section: viewInput.pageSection,
    page_name: pageName,
    page_url: window.location.href,
    page_host: window.location.host,
    previous_page_path: dataLayerUtil.getPreviousPagePath(viewInput.previousLocation),
    previous_page_section: viewInput.previousPageSection ?? 'entry',
    step_number: dataLayerUtil.getStepNumber(viewInput.pageSection, viewInput.stepNumberDataCollection, pagePath),
    step_name: dataLayerUtil.getStepName(viewInput.pageSection, viewInput.hasHash, viewInput.location, viewInput.params)
  };
};

// Generic: Init
const pushInit = (contactId?: string) => {

  const dataLayerInit: DataLayerInit = {
    event: Events.INIT,
    user_id: contactId ?? '',
    is_logged: !!contactId
  };

  pushLayer(dataLayerInit);

};

// Error: Error monitor
const pushErrorMonitor = (error: AxiosError) => {

  const dataLaterErrorMonitor: ErrorMonitor = {
    event: Events.ERROR_MONITOR,
    error_type: `${error.response?.statusText || ''} ${error.code}`,
    error_code: error.response?.status?.toString() || '',
    error_line: '',
    error_url: window.location.href || '',
    error_module: ''
  };

  pushLayer(dataLaterErrorMonitor);
};

// Funnel Step
export const pushFunnelStep = (event: DataLayerFunnelStep) => {
  const dataLayerFunnelStep = {
    event: Events.FUNNEL_STEP,
    ...event
  };

  pushLayer(dataLayerFunnelStep);
};

// E02: Funnel View
const pushFunnelView = (viewInput: DataLayerViewInput) => {

  const dataLayerFunnelView = {
    ...getDatalayerFunnelView(viewInput),
    event: Events.E02_FUNNEL_VIEW
  };

  pushLayer(dataLayerFunnelView);

};

// E05: Calcola Prestito View
const pushCalcolaPrestitoView = (viewInput: DataLayerViewInput, loanIntent: CrmLoanIntent, startingOffer: Offer) => {

  const dataLayerCalcolaPrestitoView: DataLayerCalcolaPrestitoView = {
    ...getDatalayerFunnelView(viewInput),
    event: Events.E05_CALCOLA_PRESTITO_VIEW,
    progetto: loanIntent.purpose!,
    importo: loanIntent.amount,
    durata_mesi: loanIntent.term,
    taeg: startingOffer.taeg,
    tan: startingOffer.tan,
    rata_mensile: startingOffer.installmentsAmount
  };
  pushLayer(dataLayerCalcolaPrestitoView);

};

// E12: Commercial Offer
// E13: Select Offer
// E14: Offer Summary
// E15: KYC
const pushLoanIntent = (viewInput: DataLayerViewInput, event: Events, loanIntent: CrmLoanIntent) => {

  const dataLayerLoanIntent: DataLayerLoanIntent = {
    ...getDatalayerFunnelView(viewInput),
    event: event,
    progetto: loanIntent.purpose!,
    importo: loanIntent.amount,
    durata_mesi: loanIntent.term
  };
  pushLayer(dataLayerLoanIntent);
};

// E016: Purchase
const pushPurchase = (
  viewInput: DataLayerViewInput,
  loanIntent: CrmLoanIntent,
  offer: Offer | Partial<CrmOffer>,
  procedureId: string,
  installmentAmount: number
) => {
  const dataLayerPurchase: DataLayerPurchase = {
    ...getDatalayerFunnelView(viewInput),
    event: Events.E16_PURCHASE,
    progetto: loanIntent.purpose!,
    motivation: loanIntent.purpose!,
    importo: loanIntent.amount,
    required_amount: loanIntent.amount,
    durata_mesi: loanIntent.term,
    duration: loanIntent.term,
    taeg: offer?.taeg!,
    tan: offer?.tan!,
    rata_mensile: offer?.installmentsAmount!, //installment_value
    currency: 'EUR',
    value: installmentAmount,
    transactionId: procedureId,
    paymentType: ''
  };
  pushLayer(dataLayerPurchase);
};

const dataLayerService = {
  pushInit,
  pushErrorMonitor,
  pushFunnelView,
  pushCalcolaPrestitoView,
  pushLoanIntent,
  pushPurchase
};

export default dataLayerService;

