import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { CRMAPI } from '../../api';
import { selectFiscalId, selectPersonalInfo } from '../selectors';
import { ThunkExtra } from '../types';
import { ContextDTO } from '../../types/otp';
import { HubspotFormRequest } from '../../types/hubspot';
import { BookingCallFormData } from '../../types/form-data';
import { selectContactInfo } from '../selectors/select-contact-info';

interface SetAnalyticsHubspotArgs {
  email: string;
  tracking?: any;
}

export const setAnalyticsHubSpotAction = createAsyncThunk<void, SetAnalyticsHubspotArgs, ThunkExtra>(
  'requestHubSpotAction',
  async (setAnalyticsHubspotArgs, { rejectWithValue }) => {
    const { email, tracking } = setAnalyticsHubspotArgs || {};

    // Get hutk from cookie hubspothutk
    const hutk: string = document.cookie.replace(/(?:(?:^|.*;\s*)hubspotutk\s*\=\s*([^;]*).*$)|^.*$/, '$1');
    const context: ContextDTO = {
      pageUri: window.location.href,
      pageName: 'Data Collection'
    };

    if (hutk) {
      context.hutk = hutk;
    }

    const createHubSpotAccountDTO = {
      fields: [
        {
          name: 'email',
          value: email
        }
      ],
      context: context
    };

    const utm = tracking?.utm;

    if(tracking?.channelForm) {
      createHubSpotAccountDTO.fields.push(
        {
          name: 'channel_form',
          value: tracking?.channelForm
        });
    }

    createHubSpotAccountDTO.fields.push(
      {
        name: 'latest_source',
        value: tracking?.source || '--'
      },
      {
        name: 'latest_source_drill_down',
        value: tracking?.urlSource || '--'
      },
      {
        name: 'latest_utm_campaign',
        value: utm?.latestUtmCampaign || '--'
      },
      {
        name: 'latest_utm_source',
        value: utm?.latestUtmSource || '--'
      },
      {
        name: 'latest_utm_medium',
        value: utm?.latestUtmMedium || '--'
      },
      {
        name: 'latest_utm_content',
        value: utm?.latestUtmContent || '--'
      },
      {
        name: 'latest_utm_term',
        value: utm?.latestUtmTerm || '--'
      }
    );

    try {
      await CRMAPI.createHubSpotAccount(createHubSpotAccountDTO);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error?.message);
    }
  }
);

export const requestHubSpotAction = createAsyncThunk<void, string, ThunkExtra>(
  'requestHubSpotAction',
  async (email, { getState, rejectWithValue }) => {
    const state = getState();
    const { firstName, lastName } = selectPersonalInfo(state);
    const { code } = selectFiscalId(state);
    const channel = state?.channel;
    const tracking = state?.tracking;

    // Get hutk from cookie hubspothutk
    const hutk: string = document.cookie.replace(/(?:(?:^|.*;\s*)hubspotutk\s*\=\s*([^;]*).*$)|^.*$/, '$1');
    const context: ContextDTO = {
      pageUri: window.location.href,
      pageName: 'Data Collection'
    };

    if (hutk) {
      context.hutk = hutk;
    }

    const createHubSpotAccountDTO = {
      fields: [
        {
          name: 'email',
          value: email
        },
        {
          name: 'channel_form',
          value: tracking?.channelForm ?? channel
        }
      ],
      context: context
    };

    if (firstName && lastName) {
      createHubSpotAccountDTO.fields.push(
        {
          name: 'firstname_form',
          value: firstName
        },
        {
          name: 'lastname_form',
          value: lastName
        }
      );
    }

    if (code) {
      createHubSpotAccountDTO.fields.push({
        name: 'fiscal_code_form',
        value: code
      });
    }

    const utm = tracking?.utm;

      createHubSpotAccountDTO.fields.push(
        {
          name: 'latest_source',
          value: tracking?.source || '--'
        },
        {
          name: 'latest_source_drill_down',
          value: tracking?.urlSource || '--'
        },
        {
          name: 'latest_utm_campaign',
          value: utm?.latestUtmCampaign || '--'
        },
        {
          name: 'latest_utm_source',
          value: utm?.latestUtmSource || '--'
        },
        {
          name: 'latest_utm_medium',
          value: utm?.latestUtmMedium || '--'
        },
        {
          name: 'latest_utm_content',
          value: utm?.latestUtmContent || '--'
        },
        {
          name: 'latest_utm_term',
          value: utm?.latestUtmTerm || '--'
        }
      );

    try {

      await CRMAPI.createHubSpotAccount(createHubSpotAccountDTO);

    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error?.message);
    }
  }
);

export const submitBookingCallAction = createAsyncThunk<void, BookingCallFormData, ThunkExtra>(
  'submitBookingCallAction',
  async (formData: BookingCallFormData, { getState, rejectWithValue }) => {
    const state = getState();
    const { firstName, lastName } = selectPersonalInfo(state);
    const { email } = selectContactInfo(state);

    // Get hutk from cookie hubspothutk
    const hutk: string = document.cookie.replace(/(?:(?:^|.*;\s*)hubspotutk\s*\=\s*([^;]*).*$)|^.*$/, '$1');
    const context: ContextDTO = {
      pageUri: window.location.href,
      pageName: 'booking-call'
    };

    if (hutk) {
      context.hutk = hutk;
    }

    const phoneComplete = `+${formData.phonePrefix.value} ${formData.phone}`;

    const hubspotFormRequest: HubspotFormRequest = {
      fields: [
        {
          name: 'email',
          value: email
        },
        {
          name: 'firstname',
          value: firstName
        },
        {
          name: 'lastname',
          value: lastName
        },
        {
          name: 'motivazione_assistenza',
          value: formData.motivation
        },
        {
          name: 'mobilephone',
          value: phoneComplete
        }
      ],
      context: context
    };

    try {
      await CRMAPI.submitBookingCall(hubspotFormRequest);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error?.message);
    }
  }
);


