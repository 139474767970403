// Redux
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkExtra } from '../types';
import { enableLoadingAction } from './set-loading.action';

// Axios
import { AxiosError } from 'axios';

// Api
import { OrchestratorAPI } from '../../api';

// Types
import { CRMAccountDTO } from '../../types/exelab-crm/account';
import { OfferWriteDTO } from '../../types/offer';

// Constants
import { StepCheckpoints } from '../../constants/step-checkpoints';

// Enums
import { GeneralStatus, Status } from '../../enums/offer/status';
import { YesNo } from '../../enums/yes-no';

// Services
import * as CRMService from '../../services/crm.service';

// Utils
import { accertifyDataCollector } from '../../utils/accertify-data-collector';
import {
  generateAccount,
  generateLoanData,
  generateOfferRequest,
  parseAccount
} from '../../utils/orchestrator-transform';

export const calculateLeadsOffer = createAsyncThunk<any, void, ThunkExtra>(
  'calculateLeadsOffer',
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      let purpose = state?.loanIntent?.purpose;
      const consents = Object.entries(state.dataCollection?.slides?.privacy_consents).reduce(
        (result: any, [key, value]) => {
          result[key] = value !== '' ? value === 'T' : '';

          return result;
        },
        {} as CRMAccountDTO['consents']
      );
      const { birthCountry, birthCity } = state.dataCollection.slides.personal_info_2;
      let stepCheckpoint,
        applicationId,
        procedureId,
        deviceFullPayload,
        offerLoanResponse,
        preSelectionRequest;

      /**
       * Enable loading
       */
      dispatch(enableLoadingAction(''));

      /**
       * Get Hubspot account
       */
      let crmAccount: CRMAccountDTO = await OrchestratorAPI.getAccountCRM();

      if (StepCheckpoints.INITIATION_LEADS === crmAccount.step_checkpoint) {
        const loanIntent: any = {
          purpose,
          term: crmAccount?.loan_intent?.term,
          amount: crmAccount?.loan_intent?.amount
        };

        /**
         * Save loan intent, consents, and birthCountry/birthCity if foreign customer
         */
        await CRMService.updateAccount({
          loan_intent: loanIntent,
          consents,
          applicants:
            !birthCountry && !birthCity
              ? undefined
              : [
                  {
                    birthCountry,
                    birthCity
                  }
                ]
        });

        // Foreign customer birthplace
        if (birthCity && birthCountry) {
          crmAccount.applicants[0].birthCountry = birthCountry;
          crmAccount.applicants[0].birthCity = birthCity;
        }

        /**
         * Accertify: calculate device payload
         */
        deviceFullPayload = await accertifyDataCollector();
        localStorage.setItem('deviceTransactionId', JSON.stringify(deviceFullPayload));

        const slides = parseAccount(crmAccount).dataCollection?.slides;
        const account = generateAccount(slides);

        const loanData = generateLoanData(loanIntent, slides);
        const offerRequest = generateOfferRequest(undefined, account, deviceFullPayload, loanData);
        const payload: OfferWriteDTO = {
          ...offerRequest,
          loanData: {
            ...offerRequest.loanData,
            consents: {
              bureausConsent: false // "bureausConsent" always false in first commercial offer request
            }
          },
          channel: state.channel
        };

        // Generate offers with bureau
        const offerResponse = await OrchestratorAPI.generateOffers({
          ...payload,
          applicationId,
          loanData: { ...payload.loanData, consents: { bureausConsent: true } } // "bureausConsent" always true after starting offer, user already accepted SICs
        });

        /**
         * Map response into variables
         */
        const offerApplicationId = offerResponse.applicationId;
        procedureId = offerResponse.procedureId;
        const offerStatus = offerResponse.status;
        offerLoanResponse = offerResponse.loanResponse![0];
        const offerGeneralStatus = offerLoanResponse.generalStatus;
        const selectedOffer = offerLoanResponse.offers ? offerLoanResponse.offers[0] : undefined;
        const offerBureauOnlyKO = offerLoanResponse.bureauOnlyKO;

        /**
         * If offer generation is not complete throw error
         */
        if (Status.COMPLETE !== offerStatus) {
          throw new Error(`Unhandled case ${offerStatus}`);
        }

        /**
         * If offer KO, update CRM with KO checkpoint
         */
        if (GeneralStatus.KO === offerGeneralStatus) {
          if (offerBureauOnlyKO) {
            await CRMService.updateAccount({
              applicationId: offerApplicationId,
              procedureId,
              kyc: { kyc_data: { devicePayload: deviceFullPayload } },
              step_checkpoint: StepCheckpoints.OFFER_BUREAU_KO
            });
            return {
              stepCheckpoint: StepCheckpoints.OFFER_BUREAU_KO,
              devicePayload: deviceFullPayload,
              applicationId: offerApplicationId,
              offerLoanResponse
            };
          } else {
            await CRMService.updateAccount({
              step_checkpoint: StepCheckpoints.DECLINED_BY_RULES
            });
            return {
              stepCheckpoint: StepCheckpoints.DECLINED_BY_RULES,
              devicePayload: deviceFullPayload,
              applicationId: offerApplicationId,
              offerLoanResponse
            };
          }
        } else if (selectedOffer) {
          /**
           * Save bureau offer in Hubspot and update step checkpoint
           */
          // Request
          preSelectionRequest = {
            selected_by: 'customer',
            procedureId,
            offer: {
              offerId: selectedOffer.offerId,
              reference: selectedOffer.reference,
              amount: selectedOffer.amount,
              installmentsCnt: selectedOffer.installmentsCnt,
              installmentsAmount: selectedOffer.installmentsAmount,
              tan: selectedOffer.tan,
              taeg: selectedOffer.taeg,
              teg: selectedOffer.teg,
              recommended: selectedOffer.recommended,
              totalCreditAmount: selectedOffer.totalCreditAmount,
              insurance: YesNo.NO
            }
          };

          await OrchestratorAPI.createProcedureCRM(preSelectionRequest);
          stepCheckpoint = StepCheckpoints.SELECTED_OFFER;
        }

        // Save loan intent, consents, applicationId, procedureId in Hubspot if starting offer and offers are completed
        await CRMService.updateAccount({
          applicationId: offerApplicationId,
          procedureId,
          kyc: { kyc_data: { devicePayload: deviceFullPayload } },
          step_checkpoint: stepCheckpoint
        });

        return {
          slides,
          account: crmAccount,
          applicationId: offerApplicationId,
          devicePayload: deviceFullPayload,
          procedureId,
          offerLoanResponse,
          selected: preSelectionRequest?.offer,
          stepCheckpoint
        };
      }
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error?.message);
    }
  }
);
