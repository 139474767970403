import { CrmPostResponseDTO } from '../../types/exelab-crm/account';
import {
  CreateHubSpotAccountDTO,
  CreateHubSpotAccountResponseDTO,
  CreateNonceReadDTO,
  CreateOtpWriteDTO,
  VerifyNonceParamsDTO,
  VerifyOtpReadDTO,
  VerifyOtpWriteDTO
} from '../../types/otp';
import { CRMInstance, hubspotInstance } from '../api-client';
import { HubspotFormRequest, HubspotFormResponse } from '../../types/hubspot';

const credentialHeaders = { withCredentials: true };

/**
 * POST /api/create-otp
 * @description Create the OTP token
 */
export async function createOTP(payload: CreateOtpWriteDTO) {
  return CRMInstance.post<CreateOtpWriteDTO, CrmPostResponseDTO>(`/api/create-otp`, payload);
}

/**
 * POST /api/verify-otp
 * @description Verify the OTP token
 */
export async function verifyOTP(payload: VerifyOtpWriteDTO): Promise<VerifyOtpReadDTO> {
  return CRMInstance.post<VerifyOtpWriteDTO, VerifyOtpReadDTO>(`/api/verify-otp`, payload, credentialHeaders);
}

/**
 * POST /getNonce
 * @description Generate a Nonce code
 */
export async function getNonce(): Promise<CreateNonceReadDTO> {
  return CRMInstance.get<void, CreateNonceReadDTO>(`/getNonce`, credentialHeaders);
}

/**
 * POST /auth/nonce/validate
 * @description Validate a Nonce
 * This api will set an auth cookie and redirect to the given url if available
 */
export async function verifyNonce({ contactId, nonce, redirectUrl }: VerifyNonceParamsDTO): Promise<string> {
  const params = { contactId, nonce, redirectUrl };
  const uri = CRMInstance.getUri({ ...credentialHeaders, params, url: '/auth/nonce/validate' });
  return uri;
}

/**
 * POST //submissions/v3/integration/submit/:portalId/:formGuid
 * @description Create HubSpot account
 * This api will create an Account in Hubspot CRM with customer main info
 */
export async function createHubSpotAccount(payload: CreateHubSpotAccountDTO) {
  const portalId = process.env.REACT_APP_PORTALID;
  const formGuid = process.env.REACT_APP_FORMGUID;
  return hubspotInstance.post<CreateHubSpotAccountDTO, CreateHubSpotAccountResponseDTO>(
    `/submissions/v3/integration/submit/${portalId}/${formGuid}`,
    payload
  );
}

/**
 * POST //submissions/v3/integration/submit/:portalId/:formGuid
 * @description Create HubSpot account
 * This api will create an Account in Hubspot CRM with customer main info
 */
export async function submitBookingCall(payload: HubspotFormRequest) {
  const portalId = process.env.REACT_APP_PORTALID_BOOKING_CALL;
  const formGuid = process.env.REACT_APP_FORMGUID_BOOKING_CALL;
  return hubspotInstance.post<HubspotFormRequest, HubspotFormResponse>(
    `/submissions/v3/integration/submit/${portalId}/${formGuid}`,
    payload
  );
}
