import styles from './Page.module.scss';

const Page = {};

Page.Container = ({ className, hasGradient = false, withSidebar = false, children }) => (
  <div
    className={[
      styles.pageContainer,
      hasGradient ? styles.gradientBg : '',
      withSidebar ? styles.withSidebar : '',
      className
    ].join(' ')}>
    {children}
  </div>
);

Page.Content = ({ className, children }) => (
  <div className={[styles.pageContent, className].join(' ')}>{children}</div>
);

export default Page;
