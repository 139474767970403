import React from 'react';

// React Router
import { useNavigate } from 'react-router';

// Redux
import { useSelector } from 'react-redux';
import { selectPersonalInfo } from '../../store/selectors';

// Custom Hooks
import { useBackListener } from '../data-collection-pol/custom-hooks/useBackListenerDataCollectionPol';

// Theme
import { defaultTheme } from '../../components/ThemeWrapper';

// Component
import Slide from '../../components/Slide/Slide';
import PageWrapper from '../../components/PageWrapper';

// UI Components
import Image from '../../UI/Image/Image';
import { Container } from '../../UI/containers/Containers';
import { Paragraph, BoldParagraph } from '../../UI/paragraphs/Paragraphs';

// Resources
import {
  ReactComponent as conte_icon_illustration_offers_generated_kyc_expired
} from '../../assets/resources/illustrations/expired-ko/conte_icon_illustration_offers_generated_kyc_expired.svg';

const OffersExpired = () => {

  const navigate = useNavigate();

  /**
   * useSelectors
   */
  const { firstName } = useSelector(selectPersonalInfo);

  /**
   * Custom Hooks
   */
  // Prevent navigation back
  useBackListener(() => {
    navigate(1);
  });

  return (
    <PageWrapper hasNavbarGradient>
      <Slide.Container>
        <Slide.Content noMargin>
          <Container marginBottom={40}>
            <Image component={conte_icon_illustration_offers_generated_kyc_expired} />
          </Container>
          <Container fontSize={18} lineHeight={32} color={defaultTheme.colors.text}>
            <BoldParagraph color={defaultTheme.colors.dark_blue}>{firstName},</BoldParagraph>
            <Paragraph>
              la validità delle tue offerte di prestito
              (10 giorni) è scaduta e la tua richiesta di finanziamento è stata annullata.
              <br /><br />
              Ti ringraziamo per averci scelto e speriamo in futuro di poter soddisfare le tue esigenze.
            </Paragraph>
          </Container>
        </Slide.Content>
      </Slide.Container>
    </PageWrapper>
  );
};

export default OffersExpired;






