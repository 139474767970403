import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { CRMAPI } from '../../api';
import { CreateNonceReadDTO, VerifyNonceParamsDTO } from '../../types/otp';
import { getTokenExpiration } from '../../utils/dateUtil';
import { ThunkExtra } from '../types';

export const generateNonce = createAsyncThunk<CreateNonceReadDTO, void, ThunkExtra>(
  'generateNonce',
  async (_, { rejectWithValue }) => {
    try {
      return CRMAPI.getNonce();
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error?.message);
    }
  }
);

export const verifyNonce = createAsyncThunk<void, VerifyNonceParamsDTO, ThunkExtra>(
  'verifyNonce',
  async ({ contactId, nonce, redirectUrl }, { rejectWithValue }) => {
    try {
      const cookieUri = await CRMAPI.verifyNonce({ contactId, nonce, redirectUrl });
      sessionStorage.setItem('auth-expiration', `${getTokenExpiration(new Date())}`);
      setTimeout(() => (window.location.href = cookieUri), 400);
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error?.message);
    }
  }
);
