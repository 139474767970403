import { AxiosError } from 'axios';

import { createAsyncThunk } from '@reduxjs/toolkit';
import { enableLoadingAction } from './set-loading.action';

import { OrchestratorAPI } from '../../api';

// Services
import * as CRMService from '../../services/crm.service';

// Utils
import { generateAccount, generateLoanData, generateOfferRequest } from '../../utils/orchestrator-transform';

// Types
import { OfferReadDTO, OfferWriteDTO } from '../../types/offer';
import { LoanResponse } from '../../types/procedure';
import { ThunkExtra } from '../types';
import {
  CreateUpdateHubspotDealRequest,
  CreateUpdateHubspotDealResponse,
  CrmLoanIntent,
  CrmOffer
} from '../../types/exelab-crm/account';

// Constants
import { StepCheckpoints } from '../../constants/step-checkpoints';
import { GeneralStatus, Status } from '../../enums/offer/status';
import { YesNo } from '../../enums/yes-no';
import { accertifyDataCollector } from '../../utils/accertify-data-collector';
import { ObChecksWriteDTO } from '../../types/open-banking';
import { ProvenirClient } from '../../enums/offer/provenir-client';
import { EducationalLevels } from '../../enums/applicant/educational-levels';
import PreviousLoans from '../../enums/financial-data/previous-loans';
import { LatePayments } from '../../enums/financial';

interface GenerateOfferRequest {
  provenirClient?: ProvenirClient;
}

export type GenerateOfferReturned = {
  applicationId: string;
  procedureId: string;
  loanResponse: LoanResponse;
  selected?: Partial<CrmOffer>;
  stepCheckpoint: StepCheckpoints;
};

// Just for Prestiti Online flow
// N.B. SELECTED_OFFER stepCheckpoint
export const generateOffersAction = createAsyncThunk<GenerateOfferReturned, GenerateOfferRequest, ThunkExtra>(
  'generateOffersAction',
  async (actionRequest, { getState, rejectWithValue, dispatch }) => {
    try {
      const {
        channel,
        loanIntent,
        applicationId,
        procedureId,
        kyc_data,
        dataCollection: { slides },
        openBanking: { openBankingCode: openBankingToken, openBankingEnabled: openBankingConsent }
      } = getState();

      /**
       * Get deviceFullPayload
       */

      let deviceFullPayload = kyc_data.devicePayload ? JSON.parse(kyc_data.devicePayload) : "";
      if (!deviceFullPayload) {
        const deviceTransactionId = localStorage.getItem('deviceTransactionId');
        deviceFullPayload = deviceTransactionId
          ? (JSON.parse(deviceTransactionId))
          : await accertifyDataCollector();
        localStorage.setItem('deviceTransactionId', JSON.stringify(deviceFullPayload));
      }

      /**
       * Generate offers with bureau
       */

      // Request
      const account = generateAccount(slides);
      const loanData = generateLoanData(loanIntent, slides);
      const offerRequest = generateOfferRequest(applicationId, account, deviceFullPayload, loanData);
      const offerWriteDTO: OfferWriteDTO = {
        ...offerRequest,
        procedureId,
        loanData: {
          ...offerRequest.loanData,
          consents: {
            openBankingConsent,
            incomeCheckId: openBankingToken,
            bureausConsent: true // "bureausConsent" always true after starting offer, user already accepted SICs
          }
        },
        provenirClient: actionRequest?.provenirClient,
        channel: channel,
      };

      // Response
      let offerReadDTO: OfferReadDTO | undefined;

      try {
        dispatch(enableLoadingAction('Stiamo caricando le tue offerte, non chiudere questa pagina 😎'));
        offerReadDTO = await OrchestratorAPI.generateOffers(offerWriteDTO);
      } catch (err) {
        dispatch(enableLoadingAction('Caricamento ancora in corso, non chiudere la pagina!'));
        offerReadDTO = await OrchestratorAPI.generateOffers(offerWriteDTO);
      }

      /**
       * Map response into variables
       */

      const offerApplicationId = offerReadDTO.applicationId;
      const offerProcedureId = offerReadDTO.procedureId;
      const offerStatus = offerReadDTO.status;
      const offerLoanResponse = offerReadDTO.loanResponse[0];
      const offerGeneralStatus = offerLoanResponse.generalStatus;
      const offerBureauOnlyKO = offerLoanResponse.bureauOnlyKO;


      let stepCheckpoint: StepCheckpoints | undefined;
      /**
       * If offer generation is complete update CRM
       */

      await CRMService.updateAccount({
        applicationId: offerApplicationId,
        procedureId: offerProcedureId,
        loan_intent: loanIntent
      });
      await CRMService.updateConsents({ secciAcknowledgementDate: new Date() });

      let crmProcedureReadDTO: CreateUpdateHubspotDealRequest | undefined;

      /**
       * If offer KO, update CRM with KO checkpoint
       */

      if (GeneralStatus.KO === offerGeneralStatus) {
        if (offerBureauOnlyKO) {
          stepCheckpoint = await CRMService.updateOfferKOCheckpoint();
        } else {
          stepCheckpoint = await CRMService.updateOfferDeclinedByRulesCheckpoint();
        }

        // Update applicationId
      } else {
        /**
         * Map offers response into variable
         */
        const selectedOffer = offerLoanResponse.offers![0];
        /**
         * Save bureau offer in CRM and update step checkpoint
         */

        // Request
        crmProcedureReadDTO = {
          selected_by: 'customer',
          procedureId: offerProcedureId,
          offer: {
            offerId: selectedOffer.offerId,
            reference: selectedOffer.reference,
            amount: selectedOffer.amount,
            installmentsCnt: selectedOffer.installmentsCnt,
            installmentsAmount: selectedOffer.installmentsAmount,
            tan: selectedOffer.tan,
            taeg: selectedOffer.taeg,
            teg: selectedOffer.teg,
            recommended: selectedOffer.recommended,
            totalCreditAmount: selectedOffer.totalCreditAmount,
            insurance: YesNo.NO,
            originalInstallmentsAmount: selectedOffer.originalInstallmentsAmount,
            savedInterests: selectedOffer.savedInterests
          }
        };

        await OrchestratorAPI.createProcedureCRM(crmProcedureReadDTO);
        stepCheckpoint = await CRMService.updateCheckpoint(StepCheckpoints.SELECTED_OFFER);
      }

      return {
        applicationId: offerApplicationId,
        procedureId: offerProcedureId,
        loanResponse: offerLoanResponse,
        selected: crmProcedureReadDTO?.offer,
        stepCheckpoint
      };
    } catch (err) {
      const error = err as AxiosError;
      return rejectWithValue(error?.message);
    }
  }
);

interface CalculateOffersArgs {
  provenirClient?: ProvenirClient;
  newLoanIntent?: Partial<CrmLoanIntent>;
  hasInsurance?: boolean;
  dataLayerCallback?: (input?: any, errorMessage?: string) => void;
}

interface CalculateOffersPayload {
  applicationId?: string;
  devicePayload: any;
  procedureId: string;
  generalStatus: GeneralStatus;
  loanResponse: Array<LoanResponse>;
  selected?: Partial<CrmOffer>;
  stepCheckpoint?: StepCheckpoints;
  hasInsurance?: boolean;
  hasCpiProduct?: boolean;
  secciId?: string;
}

export const calculateOffersAction = createAsyncThunk<
  CalculateOffersPayload,
  CalculateOffersArgs,
  ThunkExtra
>('calculate-offers-action', async (calculateOffersArgs, { getState, rejectWithValue, dispatch }) => {
  const { dataLayerCallback } = calculateOffersArgs || {};
  const { hasInsurance, provenirClient } = calculateOffersArgs || {};

  try {
    /**
     * Redux state
     */
    const {
      applicationId,
      procedureId,
      loanIntent,
      kyc_data,
      dataCollection: { slides },
      openBanking,
      channel,
      offers: { offerList }
    } = getState();

    const offerLoanIntent = { ...loanIntent, ...calculateOffersArgs?.newLoanIntent };

    const fiscalCode = slides?.fiscal_id?.code;
    const { openBankingCode, openBankingEnabled } = openBanking;

    /**
     * Ob checks
     */
    const openBankingConsent = (!!openBankingCode || openBankingEnabled) as boolean;

    let openBankingApplicationId, openBankingToken;

    if (!applicationId && openBankingConsent) {
      const obChecksWriteDTO: ObChecksWriteDTO = {
        applicationId, // undefined on first offer calculation
        fiscalCode,
        incomeCheckId: openBankingCode ?? ''
      };
      const openBankingData = await OrchestratorAPI.obChecks(obChecksWriteDTO);
      openBankingApplicationId = openBankingData.applicationId;
    } else {
      const openBankingToken = localStorage.getItem('openBankingCode') || openBankingCode || undefined;
      const openBankingConsent = (!!openBankingToken || openBankingEnabled) as boolean;
    }

    /**
     * Generate/Get deviceFullPayload
     */

    let deviceFullPayload = kyc_data.devicePayload ? JSON.parse(kyc_data.devicePayload) : "";
    if (!deviceFullPayload) {
      const deviceTransactionId = localStorage.getItem('deviceTransactionId');
      deviceFullPayload = deviceTransactionId
        ? (JSON.parse(deviceTransactionId))
        : await accertifyDataCollector();
      localStorage.setItem('deviceTransactionId', JSON.stringify(deviceFullPayload));
    }

    /**
     * Generate offers with bureau
     */
    // Request
    const { applicants }: any = generateAccount(slides);
    const loanData = generateLoanData(offerLoanIntent, slides);
    const offerRequest = {
      applicants,
      deviceTransactionId: deviceFullPayload,
      loanData
    };

    const offerWriteDTO: OfferWriteDTO = {
      ...offerRequest,
      procedureId,
      applicationId,
      loanData: {
        ...offerRequest.loanData,
        jobData: { ...offerRequest.loanData?.jobData, jobRole: 779 }, // TODO: remove default
        financialData: {
          ...offerRequest.loanData?.financialData,
          previousLoans: PreviousLoans.NEVER,
          latePayments: LatePayments.NEVER
        }, // TODO: remove default
        educationLevel: EducationalLevels.HIGH_SCHOOL_DIPLOMA, // TODO: remove default,

        consents: {
          openBankingConsent,
          incomeCheckId: openBankingToken,
          bureausConsent: true
        }
      },
      multipleOffers: true,
      provenirClient: provenirClient,
      channel: channel
    };

    // Response
    let offerReadDTO: OfferReadDTO | undefined;

    try {
      dispatch(enableLoadingAction('Stiamo caricando le tue offerte, non chiudere questa pagina 😎'));
      offerReadDTO = await OrchestratorAPI.generateOffers(offerWriteDTO);
    } catch (err) {
      dispatch(enableLoadingAction('Caricamento ancora in corso, non chiudere la pagina!'));
      offerReadDTO = await OrchestratorAPI.generateOffers(offerWriteDTO);
    }
    /**
     * Map response into variables
     */

    const offerApplicationId = offerReadDTO.applicationId;
    const offerProcedureId = offerReadDTO.procedureId;
    const offerLoanResponse = offerReadDTO.loanResponse[0];
    const offerGeneralStatus = offerLoanResponse.generalStatus;
    let selectedOffer;
    let hasCpiProduct;

    if (offerLoanResponse?.offers) {
      const discountedOffers = offerLoanResponse?.offers.filter(offer => offer.attributes);
      const filteredOffers = discountedOffers?.length > 0 ? discountedOffers : offerLoanResponse?.offers;

      const offerWithCpi = filteredOffers.find(offer => offer.cpi);
      hasCpiProduct = !!offerWithCpi;
      selectedOffer = hasInsurance && offerWithCpi? offerWithCpi : filteredOffers[0];
      //set cpi product based on offers list
    } else if(!offerLoanResponse?.offers && offerList?.length) {
      hasCpiProduct = !!offerList.find(offer => offer.cpi);
    }
    const offerBureauOnlyKO = offerLoanResponse.bureauOnlyKO;

    let stepCheckpoint: StepCheckpoints | undefined;
    /**
     * If offer generation is complete and first time (no procedureId saved) update CRM
     */
    let crmProcedureReadDTO: CreateUpdateHubspotDealRequest | undefined;
    let secciId: string | undefined;

    if (!procedureId) {
      await CRMService.updateAccount({
        applicationId: offerApplicationId,
        procedureId: offerProcedureId,
        loan_intent: loanIntent
      });


      /**
       * If offer KO, update CRM with KO checkpoint
       */
      if (GeneralStatus.KO === offerGeneralStatus) {
        if (offerBureauOnlyKO) {
          stepCheckpoint = await CRMService.updateOfferKOCheckpoint();
        } else {
          stepCheckpoint = await CRMService.updateOfferDeclinedByRulesCheckpoint();
        }
      } else if (selectedOffer) {
        /**
         * Save bureau offer in CRM and update step checkpoint
         */

        // Request
        crmProcedureReadDTO = {
          selected_by: 'customer',
          procedureId: offerProcedureId,
          offer: {
            offerId: selectedOffer.offerId,
            reference: selectedOffer.reference,
            amount: selectedOffer.amount,
            installmentsCnt: selectedOffer.installmentsCnt,
            installmentsAmount: selectedOffer.installmentsAmount,
            tan: selectedOffer.tan,
            taeg: selectedOffer.taeg,
            teg: selectedOffer.teg,
            recommended: selectedOffer.recommended,
            totalCreditAmount: selectedOffer.totalCreditAmount,
            insurance: hasInsurance ? YesNo.YES : YesNo.NO,
            cpi: hasInsurance
              ? {
                  ...selectedOffer?.cpi
                }
              : undefined,
            attributes: selectedOffer.attributes?.length ? selectedOffer.attributes : undefined
          },
        };

        const createUpdateHubspotDealResponse: CreateUpdateHubspotDealResponse = await OrchestratorAPI.createProcedureCRM(crmProcedureReadDTO);
        secciId = createUpdateHubspotDealResponse.secciId;

        if (!applicationId) {
          await CRMService.updateAccount({
            step_checkpoint: StepCheckpoints.OFFER_TO_BE_SELECTED,
            applicationId: offerApplicationId,
            applicants: [
              {
                educationLevel: EducationalLevels.HIGH_SCHOOL_DIPLOMA,
                jobData: {
                  jobRole: 779
                },
                financialData: {
                  latePayments: LatePayments.NEVER,
                  previousLoans: PreviousLoans.NEVER
                }
              }
            ],
            kyc: {
              kyc_data: { devicePayload: JSON.stringify(deviceFullPayload) }
            }
          } as any);
        }
      }
    }

    if (dataLayerCallback) {
      dataLayerCallback(undefined);
    }

    return {
      applicationId: offerApplicationId,
      devicePayload: JSON.stringify(deviceFullPayload),
      procedureId: offerProcedureId,
      generalStatus: offerReadDTO.loanResponse[0].generalStatus,
      loanResponse: offerReadDTO.loanResponse,
      selected: crmProcedureReadDTO?.offer,
      stepCheckpoint: stepCheckpoint ?? StepCheckpoints.OFFER_TO_BE_SELECTED,
      hasInsurance,
      hasCpiProduct,
      secciId
    };
  } catch (err) {
    const error = err as AxiosError;
    if(dataLayerCallback) {
      dataLayerCallback(undefined, error.message);
    }
    return rejectWithValue(error?.message);
  }
});

interface UpdateHubspotDealArgs {
  selectedOffer: Partial<CrmOffer>;
  dataLayerCallback?: any;
}

interface UpdateHubspotDealPayload {
  loanIntent: CrmLoanIntent;
  selectedOffer: Partial<CrmOffer>;
  secciId?: string;
}

export const saveHubspotDealAction = createAsyncThunk<
  UpdateHubspotDealPayload,
  UpdateHubspotDealArgs,
  ThunkExtra
>('save-hubspot-deal-action', async (updateHubspotDealArgs, { getState, rejectWithValue }) => {
  const { selectedOffer, dataLayerCallback } = updateHubspotDealArgs || {};

  const reduxState = getState();
  const { procedureId } = reduxState;
  const loanIntent = reduxState.loanIntent;

  const newLoanIntent = {
    ...loanIntent,
    amount: selectedOffer?.amount!,
    term: selectedOffer?.installmentsCnt!
  };

  try {
    await CRMService.updateAccount({
      loan_intent: newLoanIntent
    });

    const crmProcedureReadDTO: CreateUpdateHubspotDealRequest = {
      selected_by: 'customer',
      procedureId: procedureId!,
      offer: selectedOffer
    };

    const createUpdateHubspotDealResponse: CreateUpdateHubspotDealResponse = await OrchestratorAPI.createProcedureCRM(crmProcedureReadDTO);

    if (dataLayerCallback) {
      dataLayerCallback();
    }

    return {
      loanIntent: newLoanIntent,
      selectedOffer: crmProcedureReadDTO?.offer,
      secciId: createUpdateHubspotDealResponse.secciId
    };
  } catch (err) {
    const error = err as AxiosError;
    if (dataLayerCallback) {
      dataLayerCallback(undefined, error?.message);
    }
    return rejectWithValue(error?.message);
  }
});
