// Enums
import {
  AppRoutes,
  DataCollectionRoutes,
  IncomeDocumentationRoutes,
  KycRoutes,
  VideoSelfieRoutes
} from '../enums/app-routes';

// Constants
import { NEGOTIATION_SLIDES } from './negotiation-slides';
import { ADV_SLIDES } from './adv-slides';

export const StepNumbers = {
  [`${AppRoutes.OPTIMIZED_DATA_COLLECTION}/${DataCollectionRoutes.CONTACT_INFO}`]: 1,
  [`${AppRoutes.OPTIMIZED_DATA_COLLECTION}/${DataCollectionRoutes.PRIVACY_CONSENTS}`]: 2,
  [`${AppRoutes.OPTIMIZED_DATA_COLLECTION}/${DataCollectionRoutes.PERSONAL_INFO}`]: 3,
  [`${AppRoutes.OPTIMIZED_DATA_COLLECTION}/${DataCollectionRoutes.BIRTH}`]: 4,
  [`${AppRoutes.OPTIMIZED_DATA_COLLECTION}/${DataCollectionRoutes.FISCAL_CODE}`]: 5,
  [`${AppRoutes.OPTIMIZED_DATA_COLLECTION}/${DataCollectionRoutes.EMPLOYMENT}`]: 6,
  [`${AppRoutes.OPTIMIZED_DATA_COLLECTION}/${DataCollectionRoutes.VAT_NUMBER}`]: 7,
  [`${AppRoutes.OPTIMIZED_DATA_COLLECTION}/${DataCollectionRoutes.COMPANY_INDUSTRY}`]: 8,
  [`${AppRoutes.OPTIMIZED_DATA_COLLECTION}/${DataCollectionRoutes.COMPANY_SIZE}`]: 9,
  [`${AppRoutes.OPTIMIZED_DATA_COLLECTION}/${DataCollectionRoutes.GROSS_INCOME}`]: 10,
  [`${AppRoutes.OPTIMIZED_DATA_COLLECTION}/${DataCollectionRoutes.MONTHLY_INCOME}`]: 10,
  [`${AppRoutes.OPTIMIZED_DATA_COLLECTION}/${DataCollectionRoutes.OTHER_INCOME_SOURCES}`]: 11,
  [`${AppRoutes.OPTIMIZED_DATA_COLLECTION}/${DataCollectionRoutes.ADDRESS}`]: 12,
  [`${AppRoutes.OPTIMIZED_DATA_COLLECTION}/${DataCollectionRoutes.MARITAL_STATUS}`]: 13,
  [`${AppRoutes.OPTIMIZED_DATA_COLLECTION}/${DataCollectionRoutes.FAMILY}`]: 14,
  [`${AppRoutes.OPTIMIZED_DATA_COLLECTION}/${DataCollectionRoutes.LIVING_SITUATION}`]: 15,
  [`${AppRoutes.OPTIMIZED_DATA_COLLECTION}/${DataCollectionRoutes.COUPON_CODE}`]: 16,

  [`${AppRoutes.NEGOTIATION}/${NEGOTIATION_SLIDES.COMMERCIAL_OFFER_LOADING}`]: 32,
  [`${AppRoutes.NEGOTIATION}/${NEGOTIATION_SLIDES.COMMERCIAL_OFFER}`]: 33,
  [`${AppRoutes.NEGOTIATION}/${NEGOTIATION_SLIDES.COMMERCIAL_OFFER}/edit`]: 34,
  [`${AppRoutes.NEGOTIATION}/${NEGOTIATION_SLIDES.OFFER_SUMMARY}`]: 35,
  [`${AppRoutes.NEGOTIATION}/${NEGOTIATION_SLIDES.IBAN_INPUT}`]: 36,
  [AppRoutes.KYC]: 38,
  [`${AppRoutes.KYC}/${KycRoutes.EIDAW_REDIRECT}`]: 39,
  [`${AppRoutes.KYC}/${KycRoutes.VIDEO_SELFIE}`]: 40,
  [`${AppRoutes.KYC}/${KycRoutes.VIDEO_SELFIE}/${VideoSelfieRoutes.PRIVACY}`]: 41,
  [`${AppRoutes.KYC}/${KycRoutes.VIDEO_SELFIE}/${VideoSelfieRoutes.DESKTOP}`]: 42,
  [`${AppRoutes.KYC}/${KycRoutes.VIDEO_SELFIE}/${VideoSelfieRoutes.DOCUMENT_CHOISE}`]: 43,
  [`${AppRoutes.KYC}/${KycRoutes.VIDEO_SELFIE}/${VideoSelfieRoutes.FRONT_INSTRUCTION}`]: 44,
  [`${AppRoutes.KYC}/${KycRoutes.VIDEO_SELFIE}/${VideoSelfieRoutes.FRONT_CAPTURE}`]: 45,
  [`${AppRoutes.KYC}/${KycRoutes.VIDEO_SELFIE}/${VideoSelfieRoutes.BACK_INSTRUCTION}`]: 46,
  [`${AppRoutes.KYC}/${KycRoutes.VIDEO_SELFIE}/${VideoSelfieRoutes.BACK_CAPTURE}`]: 47,
  [`${AppRoutes.KYC}/${KycRoutes.VIDEO_SELFIE}/${VideoSelfieRoutes.SELFIE_INSTRUCTION}`]: 48,
  [`${AppRoutes.KYC}/${KycRoutes.VIDEO_SELFIE}/${VideoSelfieRoutes.SELFIE_CAPTURE}`]: 49,
  [`${AppRoutes.KYC}/${KycRoutes.VIDEO_SELFIE}/${VideoSelfieRoutes.TS_FRONT_INSTRUCTION}`]: 50,
  [`${AppRoutes.KYC}/${KycRoutes.VIDEO_SELFIE}/${VideoSelfieRoutes.TS_FRONT_CAPTURE}`]: 51,
  [`${AppRoutes.KYC}/${KycRoutes.VIDEO_SELFIE}/${VideoSelfieRoutes.TS_BACK_INSTRUCTION}`]: 52,
  [`${AppRoutes.KYC}/${KycRoutes.VIDEO_SELFIE}/${VideoSelfieRoutes.TS_BACK_CAPTURE}`]: 53,
  [`${AppRoutes.KYC}/${KycRoutes.VIDEO_SELFIE}/${VideoSelfieRoutes.PERMIT_FRONT_INSTRUCTION}`]: 54,
  [`${AppRoutes.KYC}/${KycRoutes.VIDEO_SELFIE}/${VideoSelfieRoutes.PERMIT_FRONT_CAPTURE}`]: 55,
  [`${AppRoutes.KYC}/${KycRoutes.VIDEO_SELFIE}/${VideoSelfieRoutes.PERMIT_BACK_INSTRUCTION}`]: 56,
  [`${AppRoutes.KYC}/${KycRoutes.VIDEO_SELFIE}/${VideoSelfieRoutes.PERMIT_BACK_CAPTURE}`]: 57,
  [`${AppRoutes.KYC}/${KycRoutes.VIDEO_SELFIE}/${VideoSelfieRoutes.VERIFICATION_ONGOING}`]: 58,
  [`${AppRoutes.KYC}/${KycRoutes.VIDEO_SELFIE}/${VideoSelfieRoutes.VERIFICATION_COMPLETE}`]: 59,
  [`${AppRoutes.KYC}/${KycRoutes.VIDEO_SELFIE}/${VideoSelfieRoutes.VERIFICATION_FAILED}`]: 60,
  [`${AppRoutes.KYC}/${KycRoutes.INCOME_DOCUMENTATION}`]: 61,
  [`${AppRoutes.KYC}/${KycRoutes.INCOME_DOCUMENTATION}/${IncomeDocumentationRoutes.PAYROLL}`]: 62,
  [`${AppRoutes.KYC}/${KycRoutes.INCOME_DOCUMENTATION}/${IncomeDocumentationRoutes.ACCOUNT_STATEMENT}`]: 63,
  [`${AppRoutes.ADV}/${ADV_SLIDES.HOME_ADV}`]: 64,
  [`${AppRoutes.ADV}/${ADV_SLIDES.FONTE_REDDITO}`]: 65,
  [`${AppRoutes.ADV}/${ADV_SLIDES.FONTE_PATRIMONIO}`]: 66,
  [`${AppRoutes.ADV}/${ADV_SLIDES.DICHIARAZIONE_PEP}`]: 67,
  [`${AppRoutes.ADV}/${ADV_SLIDES.RELAZIONE_FONDI_PUBBLICI}`]: 68,
  [`${AppRoutes.ADV}/${ADV_SLIDES.TITOLARE_EFFETTIVO}`]: 69,
  [`${AppRoutes.ADV}/${ADV_SLIDES.FASCIA_REDDITO}`]: 70,
  [`${AppRoutes.ADV}/${ADV_SLIDES.RUOLO_EFFETTIVO}`]: 71,
  [`${AppRoutes.ADV}/${ADV_SLIDES.ADV_COMPLETE}`]: 72,
  [`${AppRoutes.PERSONAL_AREA}`]: 73,
  [`${AppRoutes.PERSONAL_AREA}/procedure-evaluation`]: 74,
  [`${AppRoutes.PERSONAL_AREA}/loan-list`]: 75,
  [`${AppRoutes.PERSONAL_AREA}/communication`]: 76,
  [`${AppRoutes.PERSONAL_AREA}/repayment-plan`]: 77,
  [`${AppRoutes.PERSONAL_AREA}/contract`]: 78,
  [`${AppRoutes.PERSONAL_AREA}/other-actions`]: 79,
  [`${AppRoutes.PERSONAL_AREA}/other-actions/early-repayment`]: 80,
  [`${AppRoutes.PERSONAL_AREA}/other-actions/withdrawal`]: 81,
  [`${AppRoutes.PERSONAL_AREA}/signature-required`]: 82,
  [`${AppRoutes.PERSONAL_AREA}/declined-by-uw`]: 83,
  [`${AppRoutes.PERSONAL_AREA}/declined-by-aml`]: 84
};